import React, { useState } from 'react';

export default function Keywords() {
    const [dynamicFieldsKeyword, setDynamicFieldsKeyword] = useState([])
    let handleKeywordChange = (i, e) => {
        const { value } = e.target;
        const list = [...dynamicFieldsKeyword];
        list[i]['defaultValue'] = value;
        setDynamicFieldsKeyword(list);
    }

    let removeKeywordFields = (i) => {
        let newdynamicFieldsKeyword = [...dynamicFieldsKeyword];
        newdynamicFieldsKeyword.splice(i, 1);
        setDynamicFieldsKeyword(newdynamicFieldsKeyword)
    }
    const addField = (id) => {
        var inputField = {};
        if (id === 'keyword') {
            inputField = { name: 'keywords[]', type: 'text', placeholder: 'Enter Keyword', required: true, defaultValue: '', id: '', className: 'form-control mb-2' };
            setDynamicFieldsKeyword([...dynamicFieldsKeyword, inputField])
        }
    }
    return (
        <>
            <div className="col-sm-4 col-lg-3 fw-bold pt-1">Keyword:</div>
            <div className="col-sm-8 col-lg-9">
                <div id="keyword_inputwrapping"></div>
                <button type="button" className="btn btn-sm d-inline-flex align-items-center btn-outline-dark" id="keyword" onClick={() => addField('keyword')}>
                    <i className="bi bi-plus-circle-fill bi-1p2 me-2"></i> Add keyword
                </button>
                {(dynamicFieldsKeyword) && dynamicFieldsKeyword.map((item, i) => (
                    <div key={`keyword_${i}`}>
                        <div className="input-group mb-2 mt-2" >
                            <input type={item.type} className="form-control" required placeholder={item.placeholder} value={(item.defaultValue) ? item.defaultValue : ''} name={item.name} id={`keyword_${i}`} onChange={e => handleKeywordChange(i, e)} />
                            <span className="input-group-text remove cursor-pointer" onClick={() => removeKeywordFields(i)}><svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
