import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import $ from 'jquery'
import { CardSection } from '../../components/Elements';
Exporting(Highcharts);

function ReviewSentiment(props) {

    const [positive, setPositive] = useState(0);
    const [negative, setNegative] = useState(0);
    const [neutral, setNeutral] = useState(0);
    var star_rating = props.data && props.data.average ? props.data.average.toFixed(2) : null
    useEffect(() => {
        let data = props.data;
        var per_network = data && data.per_network;
        let negative = 0;
        let neutral = 0;
        let positive = 0;
        var total = data && data.total;
        $.each(per_network, function (key, value) {
            negative += value.negative;
            neutral += value.neutral;
            positive += value.positive;
        });

        let tmp_positive = Math.round(positive * 100 / total);
        let tmp_neutral = Math.round(neutral * 100 / total);
        let tmp_negative = Math.round(negative * 100 / total);
        setNegative((100 > (tmp_positive + tmp_neutral + tmp_negative)) ? (100 - (tmp_positive + tmp_neutral)) : tmp_negative);
        setNeutral(tmp_neutral);
        setPositive(tmp_positive);
        // Create the chart
        Highcharts.chart('circle-chart', {
            chart: {
                type: 'pie'
            },
            colors: [
                '#4662a0',
                '#aadb87',
                '#da495b',
                '#a87bc6',
                '#fde5a5',
                '#66ceed',
                '#d565ad',
                '#7ea45d',
                '#eace6b',
                '#66edc6',
                '#fdb7a5'
            ],


            title: {
                text: ''
            },
            tooltip: {
                enabled: false
            },
            //              subtitle: {
            //                text: '-'
            //              },
            //              innerSize: 20,
            //              plotOptions: {
            //                series: {
            //                  dataLabels: {
            //                    enabled: true,
            //                   // format: '{point.name}: {point.y:.1f}%'
            //                  }
            //                }
            //              },

            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    dataLabels: {
                        connectorWidth: 0
                    }
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }

            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },

            credits: {
                enabled: false
            },

            //              tooltip: {
            //                headerFormat: '',
            //                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            //              },

            "series": [
                {
                    "colorByPoint": true,
                    "data": [
                        {
                            "name": "",
                            "y": positive,
                            "drilldown": "Positive"
                        },
                        {
                            "name": "",
                            "y": neutral,
                            "drilldown": "Natural"
                        },
                        {
                            "name": "",
                            "y": negative,
                            "drilldown": "Negative"
                        }
                    ]
                }
            ]
        });

    }, [props]);
    
    return (
        <CardSection title="Review Sentiment">
            <div className="text-end pr-2 pt-2" style={{ position: 'relative' }}>
            <div className="text-end">{star_rating}</div>
            <div className="star-rating text-end me-0"><span id="" style={{width: star_rating ? Number(star_rating * 20) +'%' : Number(0 * 20) +'%' }}></span></div>
                <div className="row">
                <div className="segmentsd-tail col-2">
                    <p className="seg-pos">{positive ? positive : 0}  %<br/> <span>Positive</span></p>
                    <p className="seg-neu">{neutral ? neutral : 0} %<br/> <span>Neutral</span></p>
                    <p className="seg-neg">{negative ? negative : 0} %<br/> <span>Negative</span></p>
                </div>

                <div id="circle-chart" className="col-10" style={{height:'260px'}}></div>
                </div>
            </div>
        </CardSection>
    )
}

export default ReviewSentiment

