import React from 'react'

const PaymentMethod = () => {
    const payment_method = ['DISCOVER','VISA','INVOICE','MASTERCARD','ANDROIDPAY','TRAVELERSCHECK','CHECK','APPLE PAY','CASH','DINERSCLUB','FINANCING','AMERICANEXPRESS','PAYPAL','BITCOIN','SAMSUNGPAY']
  return (
    <>
        {payment_method.map((ele,num) => {
            return (
                <div className="col mb-2" key={num}>
                    <label className="form-check mb-0 fs-16px d-inline-block">
                        <input className="form-check-input" type="checkbox" value={ele} name="payment_methods[]" />
                        <div className="form-check-label pt-2px text-common">{ele}</div>
                    </label>
                </div>
            )
        })}
    </>
  )
}

export default PaymentMethod
