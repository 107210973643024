import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import $ from 'jquery'
import _ from 'lodash'
import { CardSection } from '../../components/Elements';
Exporting(Highcharts);

function FanEngagementDemographic(props) {
    useEffect(() => {
        let data = props.data;
        let fan_sengment_men_engaged_data = data && data.men && data.men.engaged ? data.men.engaged : 0;
        let fan_sengment_men_fan_data = data && data.men && data.men.fans ? data.men.fans : 0;

        let fan_sengment_women_engaged_data = data && data.women && data.women.engaged ? data.women.engaged : 0;
        let fan_sengment_women_fan_data = data && data.women && data.women.fans ? data.women.fans : 0;


        const total_engaged = fan_sengment_women_engaged_data.total && fan_sengment_men_engaged_data.total ? fan_sengment_women_engaged_data.total + fan_sengment_men_engaged_data.total : 0;
        const total_fans = fan_sengment_women_fan_data.total && fan_sengment_men_fan_data.total ? fan_sengment_women_fan_data.total + fan_sengment_men_fan_data.total : 0;

        const women_fans = (fan_sengment_women_fan_data.total !== 0) ? Math.round(fan_sengment_women_fan_data.total * 100 / total_fans) : 0;
        const men_fans = (fan_sengment_men_fan_data.total !== 0) ? Math.round(fan_sengment_men_fan_data.total * 100 / total_fans) : 0;

        const women_engaged = (fan_sengment_women_engaged_data.total !== 0) ? Math.round(fan_sengment_women_engaged_data.total * 100 / total_engaged) : 0;
        const men_engaged = (fan_sengment_men_engaged_data.total !== 0) ? Math.round(fan_sengment_men_engaged_data.total * 100 / total_engaged) : 0;
        
        let womenFan = []; 
        $.each(fan_sengment_women_fan_data, function(key, value){
            if (key !== 'total') {
                womenFan[key] = (value !== 0) ? Math.round(value * 100/total_fans) : 0;
            }
        });
        let womenEngaged = [];
        $.each(fan_sengment_women_engaged_data, function(key, value){
            if (key !== 'total') {
                womenEngaged[key] = (value !== 0) ? Math.round(value * 100/total_engaged) : 0;
            }
        });
        
        let menFan = [];
        $.each(fan_sengment_men_fan_data, function(key, value){
            if (key !== 'total') {
                menFan[key] = (value !== 0) ? Math.round(value * 100/total_fans) : 0;
            }
        });

        let menEngaged = [];
        $.each(fan_sengment_men_engaged_data, function(key, value){
            if (key !== 'total') {
                menEngaged[key] = (value !== 0) ? Math.round(value * 100/total_engaged) : 0;
            }
        });

        Highcharts.chart('container-positive', {
            chart: {
                type: 'column',
                spacingLeft: 120,
            },
            colors: [
                '#4662a0',
                '#aadb87',
                '#da495b',
                '#a87bc6',
                '#fde5a5',
                '#66ceed',
                '#d565ad',
                '#7ea45d',
                '#eace6b',
                '#66edc6',
                '#fdb7a5'
            ],
            title: {
                text: ''
            },
            xAxis: {
                categories: _.drop(Object.keys(fan_sengment_women_fan_data),1) ,
                gridLineWidth: 0,
            },
            yAxis: {
                gridLineWidth: 0,
                title: {
                    margin: 0,
                    text: 'Women'
                },
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'middle',
                x: -120,
                y: 0,
                floating: true,
                borderWidth: 0.2,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#fff'
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}%'
                    }
                    
                }
            },
            series: [{
                name: women_fans ? women_fans + '% Fans' : 0 + '% Fans',
                data: Object.values(womenFan)
            }, {
                name: women_engaged ?  women_engaged + '% Engaged': 0 + '% Engaged',
                data: Object.values(womenEngaged)
            }]
        });

        Highcharts.chart('container-negative', {
            chart: {
                type: 'column',
                spacingLeft: 120,
            },
            colors: [
                '#4662a0',
                '#aadb87',
                '#da495b',
                '#a87bc6',
                '#fde5a5',
                '#66ceed',
                '#d565ad',
                '#7ea45d',
                '#eace6b',
                '#66edc6',
                '#fdb7a5'
            ],
            title: {
                text: '',
            },

            yAxis: {
                gridLineWidth: 0,
                title: {
                    margin: 0,
                    text: 'Men'
                },
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'middle',
                x: -120,
                y: 0,
                floating: true,
                borderWidth: 0.2,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#fff'
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}%'
                    }
                }
            },
            xAxis: {
                labels: {
                    enabled: false
                }
            },
            series: [{
                name: men_fans ? men_fans + '% Fans' : 0 + '% Fans',
                data: Object.values(menFan)
            }, {
                name: men_engaged ? men_engaged + '% Engaged' : 0 + '% Engaged',
                data: Object.values(menEngaged)
            }]
        });
    }, [props]);

    return (
        <CardSection title="Fan & Engagement Demographic">
            <div id="container-positive" style={{ minWidth: ' 100%', height: ' 125px', margin: '0 auto' }}></div>
            <div id="container-negative" style={{ minWidth: '100%', height: '125px', margin: ' 0 auto', borderTop: ' 13px solid #ddd' }}></div>
        </CardSection>
    )
}

export default FanEngagementDemographic
