import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { CardSection } from '../Elements';

function LocationMap(props) {

    const [selected, setSelected] = useState()

    useEffect(() => {
    }, [props, props.data]);

    const mapStyles = {
        height: "400px",
        width: "100%"
    }

    const defaultCenter = props && props.data && props.data.length > 0 ? props.data[0].location : {lat: 32.8850634, lng: -96.9688435};
    const zoomPx = props && props.data && props.data.length > 1 ? 3 : 7; 

    return (
        <CardSection title="Location Map">
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={zoomPx}
                    center={defaultCenter}
                >   {
                        props && props.data && props.data.map((item, i) => {
                            return (
                                <Marker 
                                    key={i} 
                                    position={item.location}
                                    onMouseOver={() => {
                                        setSelected(item);
                                    }}
                                />
                            )
                        })
                    }
                    {selected ? (
                        <InfoWindow
                            position={{
                                lat: selected.location.lat,
                                lng: selected.location.lng
                            }}
                            onCloseClick={() => {
                                setSelected(null);
                            }}
                        >
                        <div className="text-center">
                            <h6 className='mt-3 fw-bold text-info'>{selected.name}</h6>
                            <p>{selected.address}</p>
                        </div>
                        </InfoWindow>
                    ) : null}
                    
                </GoogleMap>
        </CardSection>
        
    )
}

export default LocationMap
