const Design2 = (props) => {
    return (
        <div className={`card-box-container ${props.viewType === 'preview' ? 'bg-white' : ''}`} id="Design2">
            <div className="card-box" id="printing-card-box">
                <div className={`card1 ${props.flipCard === 'back' ? 'flip' : ''} ${props.viewType === 'preview' ? 'mt-0' : ''}`}>
                    <div className="front">
                        {props?.formField && props.formField.card_type === 'both' &&
                            props.viewType !== 'preview' ? <span className="flipButton btn btn-secondary d-inline-block btn-theming" onClick={props.changeFlipCard}>Flip Card</span> : <h5 className="fs-4 fw-bold mb-3">Front View</h5>
                        }
                        <section className={props.viewType === 'preview' ? 'border' : ''}>
                            <div className={`flip-box-inner ${props.viewType !== 'preview' ? 'border-dotted' : ''}`}>
                                <div className="flip-box-front d-flex ">
                                    <div className="card-second text-center ps-2 logo-box-area preview-logo-bgcolor" style={{ background: props.formField.logo_bg_color, fontFamily: props.formField.font, color: props.formField.text_color }}>
                                        <div className="d-flex flex-column">
                                    
                                            <img src={props.newImg ? props.newImg : 'images/CED.png'} className="card-logo  w-100" alt='card' />
                                          
                                            <p className="fw-bold card-fs mb-0  tag-name pt-2"></p>
                                            <p className="fw-bold card-fs mb-0 tag-name pt-2 url" >{props.formField.pc_url}</p>
                                        </div>
                                    </div>
                                    <div className="card-body preview-card-background w-50per p-0" style={{ background: props.formField.bg_color, fontFamily: props.formField.font, color: props.formField.text_color }}>
                                        <div className="d-flex flex-column align-self-center h-100 pt-3 pb-3 text-end">
                                            <div className="card-information text-end pe-2">
                                                <p className="mb-0 card-fs fw-bold name">{props.formField.pc_name}</p>
                                                <b className="card-fs  name">{props.formField.name}</b>
                                                <p className="mb-0 card-fs  job_title">{props.formField.job_title}</p>
                                                {(props.formField.phone || props.formField.def_phone) && <p className="card-fs m-0 p-0  mb-0 num">Direct: {props.formField.phone ? props.formField.phone : props.formField.def_phone}</p>}
                                                {props.formField.cell_phone && <p className="card-fs m-0 p-0  mb-0 num">Cell: {props.formField.cell_phone && props.formField.cell_phone}</p>}
                                                {props.formField.help_desk_phone && <p className="card-fs m-0 p-0  mb-0 num">Help Desk: {props.formField.help_desk_phone && props.formField.help_desk_phone}</p>}
                                                <p className="mb-0 card-fs  email">{props.formField.contact_email}</p>

                                                <p className="mb-0 card-fs street_address street_address-2" >{props.formField.street}</p>
                                                <p className="mb-0">
                                                    <span className="mb-0 card-fs pe-1 city" >{props.formField.city}</span>
                                                    <span className="mb-0 card-fs pe-1 state" >{props.formField.state}</span>
                                                    <span className="mb-0 card-fs pe-1  zip" >{props.formField.zip}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {props?.formField && props.formField.card_type === 'both' &&
                        <div className={props.viewType !== 'preview' ? 'back' : 'mt-90pc'}>
                            {props.viewType !== 'preview' ? <span className="flipButton btn btn-secondary d-inline-block btn-theming" onClick={props.changeFlipCard}>Flip Card</span> : <h5 className="fs-4 fw-bold mb-3">Back View</h5>}
                            <section>
                                <div className={`logo-box logo-back text-center py-4 ${props.viewType !== 'preview' ? 'border-dotted' : 'border'}`}>
                                    <p className="mb-0 card-fs name">{props.formField.pc_name}</p>
                                    <p className="mb-0 card-fs  name">{props.formField.name}</p>
                                    <p className="mb-0 card-fs  num">{props.formField.phone ? props.formField.phone : props.formField.def_phone}</p>
                                    <p className="mb-0 card-fs  email">{props.formField.contact_email}</p>
                                    <p className="mb-0 card-fs  street_address">{props.formField.street}</p>
                                    <p className="mb-0">
                                        <span className="mb-0 card-fs  pe-1 city" >{props.formField.city}</span>
                                        <span className="mb-0 card-fs  pe-1 state">{props.formField.state}</span>
                                        <span className="mb-0 card-fs  pe-1 zip">{props.formField.zip}</span>
                                    </p>
                                </div>
                            </section>
                        </div>
                    }
                </div >
            </div >
        </div >
    )
}

export default Design2