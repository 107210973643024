import * as Elements from "../../components/Elements";

function SocialMedia() {
	return (
		<>
            <Elements.PageTitleSection title="Social Media Management"/>

			<section className="py-5">
                <div className="container py-md-4">
                    <div className="row g-4 justify-content-between mb-5 align-items-center">
                        <div className="col-md-6 col-lg-5">
                                <img alt="amazio" src="images/services/socila-media-management1.jpg" className="w-100 shadow rounded p-2"/>
                        </div>
                        <div className="col-md-6 col-lg-7 ps-lg-4 align-self-center">
                            <p className="lh-md">
                                Social media has made a big transition over the last few years from a place where people connected personally to friends and families to a place where individuals connect with larger companies and brands. This has opened up opportunities for brands to become more engaged with their client base and to reach new customers organically. Loyal brand followers can quickly become your own brand ambassadors, as they use their own social networks to promote your brand or product.
                            </p>
                            <p>
                                But social media only works as a business tool when it's managed correctly. When you work with Amazio, you get access to managed social media services. We can take care of all of your social media management for you so you can build your social network and profile while still focusing on running your business.
                            </p>
                            <p>
                                No matter what size your business is, here are some ways Amazio can help you with social media management.
                            </p>
                        </div>
                    </div>
                    <div className="row g-4 justify-content-between mb-5 mt-4 align-items-center">
                        <div className="col-md-6 col-lg-7 ps-lg-4 align-self-center mt-3">
                            <h4 className="font-wt900">Social Networks</h4>
                            <p className="lh-md">
                                Knowing which social networks work best for your brand is crucial for making sure you're reaching your audience in the most effective way possible. Amazio understands the needs of businesses in different industries and can help you determine which social networks are the most valuable for your business. Here's a brief rundown of some of the more popular social networks.
                            </p>
                            <p className="fw-bold mb-0">Facebook</p>
                            <p className="lh-md">
                                Facebook is still the most prevalent social network out there. Most businesses can benefit from having a Facebook page that lists their relevant contact information to make it easy for customers and future clients to find them. If your business offers services (such as a salon or auto shop), you can also use Facebook to schedule appointments. Facebook is also a good platform to use to generate organic reviews from users.
                            </p>
                            <p className="fw-bold mb-0">Google+</p>
                            <p className="lh-md">
                                A lot of people forgot about Google+, but it has its place in business. Setting up a Google+ account with your address is important if you want to show up in local searches. Search engines will often pull information from Google+ instead of your webpage, so you could be missing out on valuable traffic by not having a profile set up.
                            </p>
                            <p className="fw-bold mb-0">Instagram</p>
                            <p className="lh-md">
                                Because it's picture-heavy, Instagram is useful for businesses that are product-based or have a lifestyle component.
                            </p>
                            <p className="fw-bold mb-0">Twitter</p>
                            <p className="lh-md">
                                Because Twitter puts you in direct, instant communication with your users, it's a good platform to use if your business is heavily social and relies on direct interaction with customers.
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-5">
                            <img alt="amazio" src="images/services/socila-media-management2.jpg" className="w-100 shadow rounded p-2"/>
                        </div>
                    </div>
                    <div className="row g-4 justify-content-between mb-5 mt-4 align-items-center">
                        <div className="col-md-6 col-lg-5">
                            <img alt="amazio" src="images/services/socila-media-management.jpg" className="w-100 shadow rounded p-2"/>
                        </div>
                        <div className="col-md-6 col-lg-7 ps-lg-4 align-self-center mt-3">
                            <h4 className="font-wt900">Social media posts</h4>
                            <p className="lh-md">
                                One benefit social media has over other types of online platforms is it allows brands and companies to directly interact with their followers and fans through engaging posts. These posts can range from company promotions, "behind the scenes" looks at how your company works, inspirational quotes, fun images, or anything else that's relevant to your company and situates you as a thought leader in your industry.
                            </p>
                            <p className="lh-md">
                                Posting regularly on your chosen social media platforms is important to keep engagement among your followers strong and to make sure you're being seen as an industry leader. A good social media strategy combines posts about your business with relevant posts about the industry. You might publish a blog post one day and then share an insightful article from a competitor the next day.
                            </p>
                            <p className="lh-md">
                                Keeping up with your social media posts is time-consuming and is easy to let fall by the wayside as you get into the daily grind of running your business. Amazio's managed social media services will create and publish engaging posts to all of your social media platforms.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
		</>
	)
}

export default SocialMedia
