import { useEffect, useState } from "react"
import { srcToBase64 } from "../../components/Helper"

function FlyerPdf(props){
    const [src, setSrc] = useState('')
    useEffect(()=> {
        if(props && props.data && props.data.business_logo) {
            srcToBase64(props.data.business_logo, res => {
                setSrc(res)
            })
        }
    }, [props])
    return(
        <>
        {
            <style>{`
                *,*::before,*::after{
                    box-sizing: border-box;
                }
                :root{
                    --red : #7e1214;
                    --green: #2a9253;
                    --blue: #276599;
                    --primary: #231f20;
                }
                body{
                    // font-family: 'HelveticaNeue', sans-serif;
                    // font-weight: 500;
                    // padding: 10px;
                    // margin: 0;
                    // color: var(--primary);
                    // font-size: 14px;
                }
                .text-nowrap{
                    white-space: nowrap;
                }
                .wrapper{
                    width: 100%;
                    max-width: 595pt;
                    height: 841pt;/* 595 | 842 */
                    margin: 0 auto;
                    box-shadow: none;
                    background: #fff;
                    position: relative;
                    overflow: hidden;
                }
                .row-pdf{
                    display: flex;
                    justify-content: space-between;
                }
                .row-pdf-x20{padding: 0 20px}
                .row-pdf-x30{padding: 0 30px}
                .row-pdf-x35{padding: 0 35px}
                .row-pdf-x40{padding: 0 40px}
                .row-pdf-x45{padding: 0 45px}
                .col-pdf-100{
                    width: 100%;
                    padding-left: 35px;
                    padding-right: 35px;
                }
                .col-pdf-100 img{
                    width: 100%;
                    max-width: 240px;
                }
                .col-pdf-50{
                    width: 50%;
                    padding: 1px 15px;
                    margin-top: -20px;
                }
                .col-pdf-33{
                    width: 33%;
                    padding: 1px 5px;
                }
                .pad-t5{padding-top: 5px;}
                .pad-t10{padding-top: 10px;}
                .pad-t15{padding-top: 15px;}
                .pad-t20{padding-top: 20px;}
                .pad-t25{padding-top: 25px;}
                .pad-t30{padding-top: 30px;}
                .pad-t45{padding-top: 45px;}
                .pad-y40{padding-top: 40px; padding-bottom: 40px;}
                .pad-y45{padding-top: 45px; padding-bottom: 45px;}
                .bg-light2{
                    background-color: #f6f6f6;
                }
                .card-box{
                    display: flex;
                    align-items: flex-start;
                }
                .card-icon{
                    width: 60px;
                    min-width: 60px;
                    max-width: 60px;
                    margin-right: 4px;
                }
                .card-icon img{
                    width: 60px;
                }
                .box-heading{
                    font-family: 'Helvetica Neue';
                    font-weight: bold;
                    margin: 0 0 4px 0;
                    font-size: 21px;
                    color: var(--primary);
                    text-transform: uppercase;
                }
                .box-heading2{
                    font-family: 'HelveticaNeue';
                    font-weight: bold;
                    margin: 0;
                    font-size: 15px;
                    color: var(--primary);
                }
                .card-content ul{
                    list-style-type: disc;
                    padding: 0;
                    margin: 8px 0;
                    line-height: 20px;
                    color: var(--primary);
                    padding-left: 15px;
                }
                .card-content ul.pl-0{
                    padding-left: 0px;
                }
                .card-content ul li{
                    font-size: 13px;
                    padding-bottom: 3px;
                    font-weight: 500;
                    color: var(--primary);
                }
                .card-content ul li img{
                    width: 11px;
                    height: 11px;
                    margin-bottom:-3px;
                    margin-right: 6px;
                }
                .col-pdf-33 .card-box .card-content ul{
                    list-style-type: disc;
                }
                .col-pdf-33 .card-box .card-content ul li{
                    font-size: 12.5px;
                }
                .col-pdf-33 .card-box .card-content ul li{
                    margin: 0px;
                    padding: 0px;
                }
                .img-red, .img-green, .img-blue{
                    display: none;
                    margin-bottom: 3px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    pointer-events: none;
                }
                .wrapper-red .img-red,
                .wrapper-green .img-green,
                .wrapper-blue .img-blue{ display: block; }
                .logo-red, .logo-green, .logo-blue{ display: none; margin-bottom: 3px;}
                .wrapper-red .logo-red,
                .wrapper-green .logo-green,
                .wrapper-blue .logo-blue{ display: block; }
                .red-card, .green-card, .blue-card{ display: none;}
                .wrapper-red .red-card,
                .wrapper-green .green-card,
                .wrapper-blue .blue-card{ display: block; }
                .red-time, .green-time, .blue-time{ display: none;}
                .wrapper-red .red-time,
                .wrapper-green .green-time,
                .wrapper-blue .blue-time{ display: block; }
                .red-data, .green-data, .blue-data{ display: none;}
                .wrapper-red .red-data,
                .wrapper-green .green-data,
                .wrapper-blue .blue-data{ display: block; }
                .footer-logo-red, .footer-logo-green, .footer-logo-blue{ display: none;}
                .wrapper-red .footer-logo-red,
                .wrapper-green .footer-logo-green,
                .wrapper-blue .footer-logo-blue{ display: block; }
                .wrapper-green  .list-green{
                    color: var(--green);
                }
                .wrapper-red  .list-red{
                    color: var(--red);
                }
                .wrapper-blue  .list-blue{
                    color: var(--blue);
                }
                .welcome-text{
                    font-weight: 500 !important;
                    font-size: 18px;
                    line-height: 26px;
                    margin: 0;
                    padding-top: 15px;
                }
                .wrapper-red .welcome-text, .wrapper-red .text-pdf-color, .text-pdf-link{
                    color: var(--red);
                }
                .wrapper-green .welcome-text, .wrapper-green .text-pdf-color, .wrapper-green .text-pdf-lin, .wrapper-green .greentext{
                    color: var(--green);
                    font-weight: 600;
                }
                .wrapper-blue .welcome-text, .wrapper-blue .text-pdf-color, .wrapper-blue .text-pdf-link{
                    color: var(--blue);
                }
                .forall-text{
                    font-size: 13px;
                    line-height: 20px;
                    color: #231f20;
                }
                .card-content ul.proven-leftright{
                    padding-left: 0px;
                }
                .proven-leftright li{
                    width: 49%!important;
                    display: inline-block;
                    white-space: nowrap;
                    padding-left: 0px;
                }
                .text-pdf-bold,.text-pdf-color, .text-pdf-link{font-weight: 700; text-decoration: none;}
                .text-pdf-center{text-align: center;}
                .mt-10px{margin-top: 10px} .mt-15px{margin-top: 15px} .mt-20px{margin-top: 20px}
                .footer-pdf p{
                    margin: 0 0 1px 0;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                }
                .footer-pdf{
                    position: relative;
                    width: 100%;
                    background-repeat: no-repeat;
                    z-index: 0;
                    display: flex;
                    justify-content: center;
                    color: var(--primary);
                    font-size: 14px;
                    padding-top: 30px;
                    padding-bottom: 25px;
                }
                .footer-contact strong {
                    color: var(--primary);
                }
                .border-bottom{
                    width: 100%;
                    height: 15px;
                    display: block;
                }
                .wrapper-red .border-bottom{
                    background-color: var(--red);
                }
                .wrapper-green .border-bottom{
                    background-color: var(--green);
                }
                .wrapper-blue .border-bottom{
                    background-color: var(--blue);
                }
                .footer-pdf a{
                    color: var(--primary);
                    text-decoration: none;
                }
                .footer-logo{
                    padding-left: 35px;
                    width: 33%;
                }
                .footer-logo img{
                    width: 100%;
                    max-width: 160px;
                    max-height: 64px;
                }
                .footer-address{
                    padding-left: 20px;
                    width: 33%;
                }
                .footer-contact{
                    padding-right: 35px;
                    width: 33%;
                }
                .pcname{
                    font-size: 15px;
                    font-weight: 600;
                    margin-top: 0;
                    margin-bottom: 2px;
                }
                .wrapper-green .pcname {
                    color: var(--green);
                }
                .wrapper-blue .pcname {
                    color: var(--blue);
                }
                .wrapper-red .pcname {
                    color: var(--red);
                }
                .w-68{
                    width: 68%;
                }
            `}
            </style>
        }
            <div className={`wrapper wrapper-${props.data.viewtype}`} id="container">
                <img src="images/AMSFlyer-red.png" className="img-red w-68" alt=""/>
                <img src="images/AMSFlyer-green.png" className="img-green w-68" alt=""/>
                <img src="images/AMSFlyer-blue.png" className="img-blue w-68" alt=""/>

                <div className="row-pdf">
                    <div className="col-pdf-100 pad-t45">
                        <img src="images/logo-green.png" className="logo-green" alt=""/>
                        <img src="images/logo-blue.png" className="logo-blue" alt=""/>
                        <img src="images/logo-red.png" className="logo-red" alt=""/>
                        <h3 className="welcome-text">
                            A complete barcoded solution for effective<br/>
                            inventory management and resources that help<br/>
                            you track where your inventory is being consumed.
                        </h3>
                        <div className="forall-text pad-t15">
                            Whether at your facility or jobsite, we can provide the tools you need to develop an <br/> inventory management strategy that is tailored to your business.
                        </div>
                    </div>
                </div>

                <div className="row-pdf row-pdf-x20 pad-t45">
                    <div className="col-pdf-50 pad-t10">
                        <div className="card-box">
                            <div className="card-content">
                                <h5 className="box-heading">WHAT SETS US APART?</h5>
                                <div className="forall-text pad-t5">
                                    We assign a dedicated inventory management specialist and have the tools you need to efficiently monitor and manage your inventory.
                                </div>
                                <ul>
                                    <li>Dedicated specialist</li>
                                    <li>Customized inventory strategy</li>
                                    <li>Connect App Mobile Scanning</li>
                                    <li>24/7 web and app enabled tools</li>
                                    <li>Customer reporting</li>
                                    <li>Inventory organization, replenishment, &amp; reduction</li>
                                    <li>Tool management capabilities</li>
                                    <li>Kitting</li>
                                    <li>On-site solutions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-pdf-50 pad-t10">
                        <div className="card-box">
                            <div className="card-content">
                                <h5 className="box-heading">A PROVEN SUCCESS MODEL</h5>
                                <div className="forall-text pad-t5">
                                    We take a collaborative approach to providing<br/> solutions that best fit our customer's needs. One size<br/> does not fit all so let us work with you to create a tailored solution to fit your business.
                                </div>
                                <ul className="proven-leftright greentext">
                                    <li className="list-green list-red list-blue">1. Meet</li>
                                    <li className="list-green list-red list-blue">4. Implement</li>
                                    <li className="list-green list-red list-blue">2. Define Goals</li>
                                    <li className="list-green list-red list-blue">5. Manage</li>
                                    <li className="list-green list-red list-blue">3. Develop Timeline</li>
                                    <li className="list-green list-red list-blue">6. Continuously Improve</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light2 pad-y40 mt-4">
                    <div className="row-pdf">
                        <div className="col-pdf-100">
                            <h5 className="box-heading">Benefits of a managed inventory program</h5>
                        </div>
                    </div>
                    <div className="row-pdf row-pdf-x30 pad-t10">
                        <div className="col-pdf-33">
                            <div className="card-box">
                                <div className="card-icon">
                                    <img src="images/icon-CutCosts-Green.svg" className="green-card" alt=""/>
                                    <img src="images/icon-CutCosts-Blue.svg" className="blue-card" alt=""/>
                                    <img src="images/icon-CutCosts-Red.svg" className="red-card" alt=""/>
                                </div>
                                <div className="card-content">
                                    <h5 className="box-heading2">Cut Costs</h5>
                                    <ul>
                                        <li className="text-nowrap">Avoid expedited freight fees.</li>
                                        <li>Reduce distressed inventory.</li>
                                        <li>Inventory consolidation recommendations.</li>
                                        <li>Reduce your carrying costs.</li>
                                        <li>True job cost reporting.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-pdf-33">
                            <div className="card-box">
                                <div className="card-icon">
                                    <img src="images/icon-SaveTime-Green.svg" className="green-time" alt=""/>
                                    <img src="images/icon-SaveTime-Blue.svg" className="blue-time" alt=""/>
                                    <img src="images/icon-SaveTime-Red.svg" className="red-time" alt=""/>
                                </div>
                                <div className="card-content">
                                    <h5 className="box-heading2">Save Time</h5>
                                    <ul>
                                        <li>Define your inventory goals.</li>
                                        <li>Configure your inventory notifications to meet requirements.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-pdf-33">
                            <div className="card-box">
                                <div className="card-icon">
                                    <img src="images/icon-HaveWhatYouNeed-Green.svg" className="green-data" alt=""/>
                                    <img src="images/icon-HaveWhatYouNeed-Blue.svg" className="blue-data" alt=""/>
                                    <img src="images/icon-HaveWhatYouNeed-Red.svg" className="red-data" alt=""/>
                                </div>
                                <div className="card-content">
                                    <h5 className="box-heading2">Have What You Need</h5>
                                    <ul>
                                        <li>Avoid stock outages.</li>
                                        <li>Stock materials at a point of use.</li>
                                        <li>Identify critical spares.</li>
                                        <li>Set migration strategy for obsolete material.</li>
                                        <li>Supply chain management.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-pdf">
                    <div className="footer-logo">
                        <img src={src ? src : props.data.default_logo}  alt="logo"/>
                    </div>
                    <div className="footer-address">
                        <h6 className="pcname">{props.data.pc_name}</h6>
                        <p>{props.data.address}</p>
                    </div>
                    <div className="footer-contact">
                        <p className="text-nowrap"><strong>Email:</strong> <a href="mailto:mode@xipetech.com">{props.data.contact_email}</a></p>
                        <p><strong>Phone:</strong> <a href="tel:972-345-7654">{props.data.phone}</a></p>
                        { props.data.text_address && <p><strong>Text Us: </strong>{props.data.text_address}</p>  }
                    </div>
                </div>
                <div className="border-bottom">
                </div>
            </div>
        </>
    )
}
export default FlyerPdf
