import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { CardSection } from '../Elements';
Exporting(Highcharts);

function GooglePhoneCallHour(props) {
    useEffect(() => {
        Highcharts.chart('container10', {
            chart: {
                type: 'column',
                spacingBottom: 0,
                spacingTop: 10,
                spacingLeft: 0,
                spacingRight: 0,
            },
         colors: [
           '#4662a0',
           '#aadb87',
           '#da495b',
           '#a87bc6',
           '#fde5a5',
           '#66ceed',
           '#d565ad',
           '#7ea45d',
           '#eace6b',
           '#66edc6',
           '#fdb7a5'
        ],
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: props.data[0] ,
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            }, credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            tooltip: { 
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}' 
            },
            series: [{
                name: 'Google Phone Calls',
                data: props.data[1] ? props.data[1] : 0,
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.y:.1f}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            }]
        });
    }, [props]);
    return (
        <CardSection title="Google Phone Calls by Hour">
            <div id="container10" style={{width: '100%',margin: '0 auto', height: '270px'}}></div>
        </CardSection>
    )
}

export default GooglePhoneCallHour
