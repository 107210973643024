import React from "react";
import { Outlet } from "react-router-dom";
import AsideBar from "../components/AsideBar";
import FooterFront from "./FooterFront";
import HeaderFront from "./HeaderFront";

function LayoutOfFront() {
    return (
        <>
            <HeaderFront />
            <Outlet />
            <FooterFront/>
        </>
    )
}

function LayoutOfAsideAndHeader() {
    return (
        <>
            <AsideBar />
            <Outlet />
        </>
    )
}

export { LayoutOfFront, LayoutOfAsideAndHeader}