import React, { useState } from 'react';

export default function Competitor() {
    const [dynamicFieldsCompetitor, setDynamicFieldsCompetitor] = useState([])
    let handleCompetitorChange = (i, e) => {
        const {  value } = e.target;
        const list = [...dynamicFieldsCompetitor];
        list[i]['defaultValue'] = value;
        setDynamicFieldsCompetitor(list);
    }
    let removeCompetitorFields = (i) => {
        let newdynamicFieldsCompetitor = [...dynamicFieldsCompetitor];
        newdynamicFieldsCompetitor.splice(i, 1);
        setDynamicFieldsCompetitor(newdynamicFieldsCompetitor)
    }
    const addField = (id) => {
        var inputField = {};
        if (id === 'competitor') {
            inputField = { name: '', typeURL:'url',typePlaceholder:'Website URL', type: 'text', placeholder: 'Enter Competitor Name', required: true, defaultValue: '', defaultValueURL:'',id: '', className: 'form-control mb-2' };
            setDynamicFieldsCompetitor([...dynamicFieldsCompetitor, inputField])
        }
    }
    let handleURLChange = (i, e) => {
        const {  value } = e.target;
        const list = [...dynamicFieldsCompetitor];
        list[i]['defaultValueURL'] = value;
        setDynamicFieldsCompetitor(list);
    }
    return (
        <>
            <div className="col-sm-4 col-lg-3 fw-bold pt-1">Competitor:</div>
            <div className="col-sm-8 col-lg-9">
                <div id="keyword_inputwrapping"></div>
                <button type="button" className="btn btn-sm d-inline-flex align-items-center btn-outline-dark" id="competitor" onClick={() => addField('competitor')}>
                    <i className="bi bi-plus-circle-fill bi-1p2 me-2"></i> Add Competitor
                </button>
                {(dynamicFieldsCompetitor) && dynamicFieldsCompetitor.map((item, i) => (
                    <div key={`competitor_${i}`}>
                        <div className="input-group mb-2 mt-3" >
                            <input type={item.type} className="form-control" required placeholder={item.placeholder} value={(item.defaultValue) ? item.defaultValue : ''} name={`competitor[${i}][name]`} id={`competitor_${i}`} onChange={e => handleCompetitorChange(i, e)} />
                            <input type={item.typeURL} className="form-control" required placeholder={item.typePlaceholder} value={(item.defaultValueURL) ? item.defaultValueURL : ''} name={`competitor[${i}][website]`} id={`url_${i}`} onChange={e => handleURLChange(i, e)} />
                            <span className="input-group-text remove cursor-pointer" onClick={() => removeCompetitorFields(i)}><svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
