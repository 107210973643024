import { useEffect, useState } from "react";
import { srcToBase64 } from "../../components/Helper";

function ArclightPdf(props) {
  const [src, setSrc] = useState("");
  useEffect(() => {
    if (props && props.data && props.data.business_logo) {
      srcToBase64(props.data.business_logo, (res) => {
        setSrc(res);
      });
    }
  }, [props]);

  return (
    <>
      <style>
        {`
              .wrapper{
                    width: 100%;
                    max-width: 600pt;
                     margin: 0 auto; 
                    border: none;
                    box-shadow: none;
                    background: #fff;
                    position: relative;
                    overflow: hidden;
                    display: block;
                    padding: 0;
                    box-sizing: border-box;
                    height: 100% !important;
                }
               
                .back-office h6 {
                  font-size: 21px;
                  font-weight: 800;
                  color: #0F8394;
                  margin-bottom: 5px;
                }
                .back-office p {
                  font-size: 13px;
                  font-weight: 400;
                  color: #231F20 !important;
                  width: 95%;
                  line-height: 18px;
                  opacity: 0.9;
                }
                .ams-integration h6 {
                  font-size: 21px;
                  font-weight: 800;
                  color: #0F8394;
                  margin-bottom: 5px;
              }
              .mobile-view {
                margin:auto;
                display:block;
                text-align: center;
              }
              .mobile-view img {
                width: 100%;
                max-width:300px;

            }
              .project h6 {
              font-size: 21px;
              font-weight: 800;
              color: #0F8394;
              margin-bottom: 5px
              }
              .project  p {
                font-size: 15px;
                font-weight: 500;
                color: #231F20;
            }
            .wrapper *{
                box-sizing: border-box;
            }
            .pdf-h1heading-arclight {
                color: #0F8394;
                font-weight: 900;
                font-size: 39px !important;
    line-height: 42px;
    margin-top: 13px;
                padding: 0 0 0 17px;
                margin-bottom: 15px;
            }
            .or {
              font-size: 12px;
              font-weight: 700;
              color: #231F20;
            }
            .listing-bullets-arclight {
              font-size: 15px;
              width: 87%;
              color: #231F20;
              padding-bottom: 5px;
              font-weight: 400;
              line-height: 21px;
              margin-top: 14px;
              letter-spacing: 0.2px;
            }
            .listing-bullets-arclight li {
              padding: 0 0 7px 0;
            }
            .arclight-quotes{
                height: 246px;
                background-color: #f2f2f3;
                border-radius: 15px;
                padding: 14px 10px 0px 14px;
                margin-bottom: 20px;
            }
            .arclight-quotes h6{
                font-size: 20px;
                font-weight: 800;
                color: #0F8394;
                margin-bottom: 4px; 
            }
            .arclight-quotes li {
              font-size: 12.5px;
              line-height: 18px;
              padding-bottom: 10px;
              color: #000000;
              font-weight: 400;
              list-style: none !important;
              position: relative;
              opacity: 0.9;
             }
            .back-office {
              position: relative;
            }
            .back-office::after {
              content: "";
              position: absolute;
              width: 110px;
              height: 2px;
              top: 13px;
              left: 152px;
              background: #ffdd00;
              border-left: 2px solid #f5ad12;
              border-right: 2px solid #f5ad12;
              border-radius: 20%;
          }
            .ams-integration {
              position: relative;
            }
            .ams-integration::after {
              content: "";
              position: absolute;
              width: 71px;
              height: 2px;
              top: 13px;
              left: 200px;
              background: #ffdd00;
              border-left: 2px solid #f5ad12;
              border-right: 2px solid #f5ad12;
              border-radius: 20%;
            }
            .project {
              position: relative;
            }
            .arclight-footer-main {
                background-color: transparent !important;
            }
            .arclight-final-footer .para-f p {
              color: white;
              font-size: 17px;
              }
            section.arclight-footer-sub-main {
            background: #449daa;
            }
            .arclight-footer-width {
            background-color: #f2f2f3;
            height: 100%;
            }
            .banner-final{
                margin-top: -42px;
            }
            .arclight-footer-submain{
                background-color:transparent;
            }
          .arclight-ced-logo img {
            width: 100%;
            max-width: 100px;
            object-fit: cover;
            }
              .para-f p {
                  color: #ffff;
                  }
              .para-f {
                padding: 0 0 0 134px;
              }
              .arclight-scanner-images-mobile img {
                  max-width: 200px;
                }
                .arclight-button-section .buton-image-1 {
                    max-width: 75px;
                    border: 1px solid black;
                    border-radius: 5px;
                }
                .arclight-button-section .buton-image-2 {
                    max-width: 75px;
                    margin-left: 5px;
                    border: 1px solid black;
                     border-radius: 5px;
                }
                .scanner-images-mobile img {
                  width: 100%;
                  max-width: 144px;
                }
              .info {
                color: #231F20;
                font-size: 9.33px;
                font-weight: 700;
                margin-left: 10px;
                margin-top: 11px
              }
              .arclight-para p {
                font-size: 11px;
                color: #231F20;
                font-weight: 400;
                width: 94%;
                margin-bottom: 0px;
                line-height: 19px;
                          }
              .arclight-final-footer .para-f p {
                  color: white;
                  font-size: 17px;
                  }
          .arclight-ced-logo p {
              font-size: 13px;
              font-weight: 500;
              color: #fff;
          }
          .arclight-images img {
            width: 100% !important;
    object-fit: cover;
    max-width: 181px;
          }
          .modal-body{
              padding: 0px !important;
          }
          .arclight-heading-footer {
            font-size: 30px;
            font-weight: 800;
            color: #0F8394;
            line-height: 56px;
          }
        .ams-integration p {
          font-size: 13px;
          font-weight: 400;
          color: #231F20 !important;
          width: 100%;
          line-height: 18px;
          opacity: 0.9;
        }
        .project p {
          font-size: 13px;
          font-weight: 400;
          color: #231F20 !important;
          width: 95%;
          line-height: 18px;
          opacity: 0.9;
        }
        .scanner-images-arclight-mobile img {
            width: 100%;
            max-width: 189px;
            object-fit: cover;
        }
        .mobileDflex{
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .baaner-mobile {
                width: 100%;
                object-fit: cover;
                max-width: 181px;
            }
            .arclight-quotes-invoice{
              height: 295px;
                  background-color: rgba(242, 242, 243, 1);
                  border-radius: 20px;
                  padding: 20px 10px 1px 9px;
                  margin-bottom: 20px;
                 }

                 .arclight-quotes-invoice h6 {
                  font-size: 22px;
                  font-weight: 800;
                  color: #0F8394;
                  margin-bottom: 4px;
                  padding: 0 0px 0px 16px;
              }

              .arclight-quotes-invoice li {
                font-size: 13px;
                line-height: 20px;
                padding-bottom: 12px;
                color: #242424;
                font-weight: 500;
                list-style: none !important;
                position: relative;
            }
            .listing-bullets-arclight li {
              list-style:none !important;
              position: relative;
            }
            .dotSpan{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -2%;
              top: 25%;
            }
            .newSpan{
              top: 45%;
            }
            .dotSpanSecond{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -3%;
              top: 17%;
            }
            .topNxt{
              top: 14% !important;
            }
            .dotSpanThird{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -3%;
              top: 40%;
            }
            .dotSpanFourth{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -3%;
              top: 25%;
            }
            .dotSpanSix{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -3%;
              top: 27%;
            }
            .dotSpanSeven{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -3%;
              top: 27%;
            }
            .dotSpaneight{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -3%;
              top: 33%;
            }
            .dotSpannine{
              height: 3px;
              width: 3px;
              margin-right: 5px;
              display: block;
              background-color: #4a4a4a;
              border-radius: 50% !important;
              position: absolute;
              left: -3%;
              top: 28%;
            }
         .banner-arclight{
          width: 100%;
         }
         .banner-final{
          width: 100%;
         }
         .fontTxt {
          font-size: 8px;
      }
      .quick {
        width: 100%;
        max-width: 179px !important;
    }
    .Qrcode{
      width: 100%;
      max-width: 282px;
    }

            @media (max-width: 1199px) and  (min-width: 992px) {
                  .arclight-quotes{
                    height: 360px !important;
                  }
                  .back-office::after {
                    width: 61px;
                    }
                    .ams-integration::after {
                    width: 88px;
                    height: 2px;
                    top: 17px;
                    left: 127px;
                    
                }
                .listing-bullets-arclight{
                  width: 100% !important;
                }
              .pdf-h1heading-arclight {
                  font-size: 35px !important;
              }
              }

            @media (max-width: 980px) and  (min-width: 380px) {
                  .wrapper{
                      min-height: 100% !important;
                      max-height: 100% !important;
                    }
                    .arclight-quotes li {
                      font-size: 12px;
                      padding-bottom: 10px;
                  }
                  .listing-bullets-arclight {
                      font-size: 15px;
                      width: 100% !important;
                      }
                  .pdf-h1heading-arclight {
                      color: #0F8394;
                      font-weight: bolder;
                      font-size: 30px !important;
                      line-height: 43px;
                      margin-top: 15px;
                      padding: 0 0 0 17px;
                  }
                  .mobile-view img {
                      width: 100%;
                      max-width: 337px;
                  }
                  .mobile-view {
                      margin: auto;
                      display: block;
                      text-align: center;
                  }
                  .arclight-footer-para {
                    padding: 0 0 0 0px;
                }
                  .back-office::after {
                      content: "";
                      position: absolute;
                      width: 39px;
                      height: 2px;
                      top: 21px;
                      background: #ffdd00;
                      left: 5px;
                      border-left: 2px solid #f5ad12;
                      border-right: 2px solid #f5ad12;
                      border-radius: 20%;
                  }
                  .ams-integration::after {
                      content: "";
                      position: absolute;
                      width: 43px;
                      height: 2px;
                      top: 23px;
                      left: 3px;
                      background: #ffdd00;
                      border-left: 2px solid #f5ad12;
                        border-right: 2px solid #f5ad12;
                        border-radius: 20%;
                  }
                .project::after {
                    content: "";
                    position: absolute;
                    width: 39px;
                    height: 2px;
                    top: 21px;
                    background: #ffdd00;
                    left: 5px;
                    border-left: 2px solid #f5ad12;
                    border-right: 2px solid #f5ad12;
                      border-radius: 20%;
                }
                .arclight-ced-logo {
                    margin: auto;
                    display: block;
                    text-align: center;
                }
                .mobile-view{
                  display:block;
                  margin:auto;
                }
                .mobile-view img {
                  width: 100%;
                  max-width: 302px !important;
              }

              .arclight-quotes h6 {
                padding: 0px 0px 0px 7px;
            }
              }
          @media (max-width: 991px) and  (min-width: 576px) {
              .set-height {
                height: 100%;
                min-height:100% !important;
                max-height: none !important;
            }
            .arclight-footer-para {
              padding: 0 0 0 10px;
          }
          .arclight-quotes {
            height: 303px;
        }
        .arclight-quotes-invoice h6{
          padding: 0 0px 0px 4px;
        }
        .arclight-quotes-invoice{
          height: 205px;
        }
        .banner-final{
          margin-top:-50px;
        }
        .arclight-heading-footer{
          font-size: 20px;
          width: 80%;
          margin-bottom: 0px;
          line-height: 22px;
        }
        .arclight-para p{
          font-size: 8px;
          width: 100%;
          margin-bottom: 8px;
          margin-top: 5px;
        }
        .arclight-button-section .buton-image-2{
          max-width: 50px;
        }
        .arclight-button-section .buton-image-1 {
          max-width: 50px;
      }
      .info{
        color: #231F20;
    font-size: 10px;
    font-weight: 700;
    margin-left: 14px;
    margin-top: 8px;
    padding-right: 7px;
      }
      .back-office h6{
        font-size: 20px;
      }
      .back-office p{
        line-height: 16px;
        font-size: 10px;
        width: 100%;
      }
      .ams-integration h6{
        font-size: 20px;
      }
      .ams-integration p {
        font-size: 10px;
        line-height: 16px;
        margin-bottom: 0px;
    }
    .project h6{
      font-size: 20px;
    }
    .project p {
      font-size: 10px;
      width: 100%;
      line-height: 16px;
  }
  .arclight-quotes-invoice{
    padding: 0px 23px 1px 20px;
  }
  .arclight-quotes-invoice li{
    line-height: 20px;
    font-sixe: 24px;
    padding-bottom: 4px;
  }
  .scanner-images-arclight-mobile img{
max-width: 135px;
  }
  .arclight-button-section .buton-image-1{
    max-width: 39px;
    height: 18px;
  }
  
        }
          
          @media (max-width: 575px) and  (min-width: 380px) {
                .arclight-quotes {
                    height: 200px;
                }
                .set-height {
                  height: 100%;
                  min-height:100% !important;
                  max-height: none !important;
              }
              .pdf-h1heading-arclight {
                font-size: 28px !important;
                line-height: 30px;
                font-weight: 900;
            }
            .arclight-quotes li {
              font-size: 13px;
              padding-bottom: 7px;
              line-height: 17px;
              font-weight: 400;
          }
          .arclight-quotes h6{
            font-size: 20px;
            padding: 0px 0px 0px 0px;
            margin-bottom: 10px;
          }
          .arclight-quotes-invoice h6{
            font-size: 20px;
            padding: 0px 0px 0px 0px;
            margin-bottom: 10px;
          }
          .newSpan {
            top: 24% !important;
        }
        .dotSpan{
          top: 19% !important;
        }
        .dotSpanSecond{
          height: 2px;
          width: 2px;
          left: -2%;
          top: 18%;
        }
        .scanner-images-arclight-mobile img{
          max-width: 175px !important;
          margin-top: -20px;
        }
        .arclight-quotes-invoice li{
          font-size: 13px;
          line-height: 17px;
          padding-bottom: 5px;
          font-weight: 400;
          color: #231F20;
        }
        .arclight-quotes-invoice{
          height: 220px;
          padding: 20px 15px 1px 14px;
        }
        .quick{
          max-width: 135px !important;
        }
        .back-office h6{
          font-size: 16px;
        }
        .back-office p{
          font-size: 9px;
          line-height: 14px;
          width: 100%;
        }
        .ams-integration h6{
          font-size: 16px;
        }
        .ams-integration p{
          font-size: 9px;
          width: 100%;
          line-height: 14px;
        }
        .project h6{
          font-size: 16px;
        }
        .project p{
          font-size: 9px;
          line-height: 14px;
          width: 100%;
        }
        .project::after{
        width: 27px;
        top: 17px;
        left: -6px;
        }
        .ams-integration::after{
          width: 27px;
      left: -6px;
        }
        .back-office::after{
          width: 27px;
          height: 2px;
          top: 16px;
          left: -6px;
        }
        .arclight-heading-footer{
          font-size: 20px;
          line-height: 18px;
          margin-bottom: 10px !important;
        }
        .arclight-para p{
          font-size: 12px;
    line-height: 15px;
        }
        .banner-final{
          margin-top: -45px !important;
        }
        .arclight-button-section .buton-image-1{
          max-width: 60px;
        }
        .arclight-button-section .buton-image-2{
          max-width: 68px;
        }
        .info{
          font-size: 10.33px;
          margin-left: 10px;
          margin-top: 6px
        }
        .fontTxt{
          font-size: 11px;
          margin-bottom: 0px !important;
          font-weight: 500;
        }
        .arclight-footer-para {
          padding: 25px 25px 25px 25px;
          text-align: center;
      }
        .arclight-button-section{
          display: block !important;
          margin-top: 10px;
        }
          }
          @media (max-width: 380px) and  (min-width: 280px) {
                .back-office::after {
                  width: 39px;
                  height: 2px;
                  top: 21px;
                  left: 5px;
                    }
                  .ams-integration::after {
                    width: 43px;
                    height: 2px;
                    top: 23px;
                    left: 3px;
                  }
                  .arclight-quotes {
                    height: 250px;
                }
                  .project::after {
                    content: "";
                    position: absolute;
                    width: 43px;
                    height: 2px;
                    top: 23px;
                    left: 3px;
                    background: #ffdd00;
                    border-left: 2px solid #f5ad12;
                   border-right: 2px solid #f5ad12;
                   border-radius: 20%;
                  }
                  .pdf-h1heading-arclight {
                    font-size: 19px !important;
                    line-height: 27px;
                    }
                    .listing-bullets-arclight {
                      font-size: 12px;
                      width: 100%;
                    }
                    .arclight-quotes li {
                      font-size: 12px;
                    }
                    .back-office h6 {
                      font-size: 20px;
                    }
                    .ams-integration h6 {
                      font-size: 20px;
                    }
                    .back-office p {
                      font-size: 12px;
                    }
                    .ams-integration p {
                    font-size: 12px;
                    font-weight: 500;
                    }
                  .project h6 {
                  font-size: 20px;
                  }
                  .project p {
                  font-size: 12px;
                  font-weight: 500;
                  }
                  .arclight-footer-para {
                  padding: 0 0 0 19px;
                  }
                .arclight-heading-footer {
                font-size: 22px;
                }
              .arclight-para p {
              font-size: 12px;
              }
              .arclight-ced-logo img {
              width: 100%;
              max-width: 100%;
              object-fit: cover;
              }
              .arclight-quotes li {
              font-size: 10px;
              padding-bottom: 5px;
              line-height: 17px;
              }
              .arclight-quotes-invoice li{
                font-size: 10px;
                padding-bottom: 5px;
                line-height: 17px;
              }
              .info{
                font-size: 7px;
              }
          }

          
        
      `}
      </style>
      <div className="wrapper template-arclight" id="container">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-xl-3 pt-lg-0 pt-md-0 mt-xl-4 mt-lg-4 mt-md-0 ps-lg-4 ps-xs-0">
              <div className="mobileDflex d-xxl-none d-xl-none d-lg-none mt-3">
                <div className="arclight-images/arclight  ps-3 pt-5">
                  <img
                    src="images/arclight/logo.svg"
                    className="w-75 logo-images/arclight"
                    alt="arclight"
                  />
                </div>
                <div className=" d-xl-none  d-block">
                  <img
                    src="images/arclight/banner-mobile.png"
                    className="w-100 baaner-mobile"
                    alt="arclight"
                  />
                </div>
              </div>
              <div className="arclight-images pt-3 ps-3 d-none d-md-none d-lg-block d-xl-block d-xxl-block ">
                <img
                  src="images/arclight/logo.png"
                  alt="arclight-logo"
                  className="w-100 logo-images/arclight"
                />
              </div>
              <h1 className="pdf-h1heading-arclight mb-0">
                Connect directly to your parts catalog with Arclight
              </h1>
              <ul className="listing-bullets-arclight ps-lg-4">
                <li>
                  <span className="dotSpan"></span>Add parts from your
                  customized product catalog to quotes, jobs and invoices.
                </li>
                <li>
                  <span className="dotSpan"></span> Import quotes from us
                  directly into quotes for your customers.
                </li>
                <li>
                  <span className="dotSpan newSpan"></span>Place orders for will call
                  pickup and delivery.
                </li>
                <li>
                  <span className="dotSpan"></span>Receive notifications when
                  orders are ready for pickup.
                </li>
              </ul>
            </div>

            <div className="col-lg-5  d-none d-md-none d-lg-block d-xl-block d-xxl-block ">
              <img
                src="images/arclight/bannerImg.png"
                alt="arclight-banner"
                className="w-100 banner-arclight "
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 d-xl-block d-xxl-block d-lg-block d-none">
              <div className="row">
                <div className="col-lg-4">
                  <div className="arclight-quotes">
                    <h6>Quotes</h6>
                    <ul className="ps-2">
                      <li>
                        <span className="dotSpanSecond"></span>Add parts from
                        your catalog with your profit margin built in.
                      </li>
                      <li>
                        <span className="dotSpanThird"></span>Add labor with
                        customer rates.
                      </li>
                      <li>
                        <span className="dotSpanSecond topNxt"></span>Customers can approve quotes in the app or online.
                      </li>
                      <li>
                        <span className="dotSpanFourth"></span>Assign quotes to
                        staff and schedule them.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="arclight-quotes">
                    <h6>Jobs</h6>
                    <ul className="ps-2">
                      <li>
                        <span className="dotSpanSix"></span>Parts and labor estimates from
                        quotes are copied to the job.
                      </li>
                      <li>
                        <span className="dotSpanSix"></span> Add or remove parts and labor
                        without impacting the job.
                      </li>
                      <li>
                        <span className="dotSpanSix"></span>Job timer allows for tracking of
                        all time spent on a job.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="arclight-quotes">
                    <h6>Invoices</h6>
                    <ul className="ps-2 mb-2">
                      <li className="">
                        <span className="dotSpanSeven"></span>Jobs accepted by
                        your customer become invoices.
                      </li>
                      <li className="m-0 p-0">
                        <span className="dotSpaneight"></span>Send invoices directly to your
                        customer with Arclight<b>...OR</b>
                      </li>
                    </ul>
                    {/* <p className="pb-1 mb-0 or">OR</p> */}
                    <ul className="mb-0 ps-2">
                      <li>
                        <span className="dotSpannine"></span><b>Send invoices to QuickBooks
                          for processing.</b>
                      </li>
                    </ul>
                    <div className="quick-main ">
                      <img
                        src="images/arclight/Mask-group.png"
                        alt="arclight-quick"
                        className="quick"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mb-3  mobile-view-qute d-lg-none d-xl-none  d-xxl-none">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-12">
                <div className="arclight-quotes mb-3">
                  <h6 className="quote-heading ">Quotes</h6>
                  <ul className="quote-mobile-size ps-2">
                    <li>
                      <span className="dotSpanSecond"></span>
                      Add parts from your customized product catalog to quotes,
                      jobs quotes and invoices.
                    </li>
                    <li>Add labor with customer rates.</li>
                    <li>
                      <span className="dotSpanSecond"></span>
                      Customers approve quotes in without impacting the quote
                      customer with Arclight or the app or online.
                    </li>
                    <li>
                      <span className="dotSpanSecond"></span>
                      Assign quotes to staff and schedule them.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-12">
                <div className="arclight-quotes mb-3 ">
                  <h6 className="quote-heading">Jobs</h6>
                  <ul className="quote-mobile-size ps-2">
                    <li>
                      <span className="dotSpanSecond"></span>
                      Parts and labor estimates from quotes are copied to the
                      job.
                    </li>
                    <li>
                      <span className="dotSpanSecond"></span>
                      Add or remove parts anda labor without impacting the
                      quote.
                    </li>
                    <li>
                      <span className="dotSpanSecond"></span>
                      Job timer allows for tracking of all time spent on a job.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className=" arclight-quotes-invoice d-flex justify-content-between ">
                  <div className=" mt-sm-3 mb-3 ">
                    <h6 className="quote-heading">Invoices</h6>
                    <ul className="quote-mobile-size ps-2 mb-0">
                      <li className="">
                        <span className="dotSpanSecond"></span>
                        Jobs accepted by your customer become invoices.
                      </li>
                      <li className="">
                        <span className="dotSpanSecond"></span>
                        Send invoices directly to your customer with Arclight.
                      </li>
                      <li>
                        <span className="dotSpanSecond"></span> Send invoices
                        Quickbooks for processing.
                      </li>
                    </ul>
                    <img
                      src="images/arclight/amajio _new_-08.png"
                      alt="arclight"
                      className=" w-lg-75 quick"
                    />
                  </div>
                  <div className="scanner-images-arclight-mobile pe-xl-4 pe-sm-0 pe-xs-0 text-sm-end text-xs-end text-end">
                    <img
                      src="images/arclight/scanner-moview.svg"
                      alt="scanner"
                      className="w-75"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12  mb-3 mobile-view-back-office d-xl-none d-xxl-none d-lg-none">
            <div className="row d-flex justify-content-between ">
              <div className="col-sm-6 col-md-12 col-6">
                <div className="back-office py-2 ps-lg-5 ps-4">
                  <h6>Back office</h6>
                  <p className="mb-0">
                    An online platform for your office staff to manage every
                    aspect of your business.
                  </p>
                </div>
                <div className="ams-integration  py-2 ps-lg-5 ps-4">
                  <h6>AMS integration</h6>
                  <p className="mb-0">
                    Allows you to scan out parts from your warehouse and quickly
                    attach them to quotes jobs and invoices.
                  </p>
                </div>
                <div className="project  py-2 ps-lg-5 ps-4">
                  <h6>Projects</h6>
                  <p>
                    Monitor every aspect of your project,including the creation
                    of multiple quotes,jobs and invoices for project work.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-12 col-6 align-self-center">
                <div className="mobile-view">
                  <img
                    src="images/arclight/new-banner-mobile.png"
                    className=""
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-md-5 mb-sm-0">
            <div className="col-sm-12 desktop-view-back-office d-xl-block d-xxl-block d-lg-block d-md-none  d-sm-none d-none ">
              <div className="row row-cols-xxl-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3">
                <div className="col">
                  <div className="back-office my-1 ps-3">
                    <h6>Back office</h6>
                    <p className="mb-0">
                      An online platform for your office staff to manage every
                      aspect of your business.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="ams-integration my-1 ps-2">
                    <h6>AMS integration</h6>
                    <p className="mb-0">
                      Allows you to scan out parts from your warehouse and
                      quickly attach them to quotes jobs and invoices.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="project my-1 ps-3">
                    <h6>Projects</h6>
                    <p className="mb-0">
                      Monitor every aspect of your projects, including the
                      creation of multiple quotes, jobs, and invoices for project
                      work.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="arclight-footer-main pt-0">
          <div className="arclight-footer-width">
            <div className="footerFirst">
              <div className="container mt-4 ps-0">
                <div className="row g-0">
                  <div className="col-md-12 ps-0 mt-3">
                    <div className="row mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-col-0 mt-0">
                      <div className="col-sm-6 col-md-12 col-lg-6 col-12">
                        <div className="arclight-footer-image">
                          <img
                            src="images/arclight/Clip-path-group.png"
                            alt="arclight-banner-final"
                            className="banner-final"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-12 col-lg-6 col-12 align-self-center ps-sm-0 ps-xs-0">
                        <div className="arclight-footer-para">
                          <h6 className="arclight-heading-footer">
                            Wherever you need us
                          </h6>
                          <div className="par-sub d-flex ">
                            <div className="arclight-para">
                              <p>
                                Since you could be working anywhere, we have to
                                work everywhere. Arclight is available across all
                                your devices and all the places you do business.
                              </p>
                              <div className=" arclight-button-section d-flex pt-lg-3">
                                <img
                                  src="images/arclight/app-store.png"
                                  alt="arclight-button"
                                  className="w-100 buton-image-1"
                                />
                                <img
                                  src="images/arclight/google.png"
                                  alt="arclight-button"
                                  className="w-100 buton-image-2"
                                />

                                <span className="info">
                                  info@getarclight.com
                                </span>
                              </div>
                            </div>
                            <div className="para2 mt-3 my-auto d-md-none d-xxl-block d-xl-block d-sm-none d-none ">
                              <div className="arclight-scanner">
                                <img
                                  src="images/arclight/qr-code.png"
                                  alt="scanner"
                                  className="Qrcode"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className=" arclight-footer-sub-main d-lg-block d-md-block d-none">
            <div className="container">
              <div className="row d-flex justify-content-center align-items-center py-3">
                <div className="col-12 col-lg-3 col-md-12 col-sm-12 col-xl-3 text-md-center text-left mb-0 align-self-center">
                  <div className="arclight-ced-logo">
                    <img src={src ? src : props.data.default_logo} alt="logo" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9 text-center text-md-left">
                  <div className="arclight-ced-logo">
                    <p className="mb-0">{props.data.pc_name} {props.data.address} {props.data.contact_email} {props.data.phone}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" arclight-footer-sub-main d-lg-none d-md-none d-block">
            <div className="container">
              <div className="row d-flex justify-content-center align-items-center py-2">
                <div className="col-2 text-md-center text-left mb-0 align-self-center">
                  <div className="arclight-ced-logo">
                    <img src={src ? src : props.data.default_logo} alt="logo" />
                  </div>
                </div>
                <div className="col-10">
                  <span className="fontTxt">{props.data.pc_name}</span>
                  <span className="fontTxt">{props.data.address}</span>
                  <span className="fontTxt">
                    {props.data.contact_email}
                  </span>
                  <span className="fontTxt">{props.data.phone}</span>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </div>
    </>
  );
}
export default ArclightPdf;
