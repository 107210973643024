const Design6 = (props) => {
    return (
        <div className={`card-box-container ${props.viewType === 'preview' ? 'bg-white' : ''}`} id="Design6">
            <div className="card-box" id="printing-card-box">
                <div className={`card1 ${props.flipCard === 'back' ? 'flip' : ''} ${props.viewType === 'preview' ? 'mt-0' : ''}`}>
                    <div className="front">
                        {props?.formField && props.formField.card_type === 'both' &&
                            props.viewType !== 'preview' ? <span className="flipButton btn btn-secondary d-inline-block btn-theming" onClick={props.changeFlipCard}>Flip Card</span> : <h5 className="fs-4 fw-bold mb-3">Front View</h5>
                        }
                        <section className={props.viewType === 'preview' ? 'border' : ''}>
                             <div className="flip-box-inner card-six">
                                <div className="d-flex rounded-0 border-0 logo-bgcolor p-3 " style={{ background: props.formField.logo_bg_color, fontFamily: props.formField.font, color: props.formField.text_color }}>
                                    <div className={props.frontView && "card-design-six"}>
                                        <div className="data-move card-backbg1" >
                                            <img src={props.newImg ? props.newImg : 'images/logo-aa.png'} className="card-logo" alt="card" />
                                        </div>
                                        <p className="para2 mb-0 fw-bold">
                                            <span className="street_address p-1">{props.formField.street && `${props.formField.street}, `}</span>
                                            <span className="city p-1">{props.formField.city && `${props.formField.city}, `}</span>
                                            <span className="state p-1">{props.formField.state && `${props.formField.state}, `}</span>
                                            <span className="zip p-1">{props.formField.zip && `${props.formField.zip}`}</span></p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-self-end card-background  position-relative" style={{ background: props.formField.bg_color, fontFamily: props.formField.font, color: props.formField.text_color }}>
                                    <div className="cdr-img">
                                        <img src="images/logo-arrow-back.png" className="card-businees_logo" alt="card" />
                                    </div>
                                    <div className="para text-end">
                                        <p className="name mb-0 fw-bold"><b>{props.formField.pc_name}</b></p>
                                        <p className="name mb-0 fw-bold"><b>{props.formField.name}</b></p>
                                        <p className="job_title mb-0 fw-bold">{props.formField.job_title}</p>
                                        {(props.formField.phone || props.formField.def_phone) && <p className="fw-bold m-0 p-0  mb-0 num">Direct: {props.formField.phone ? props.formField.phone : props.formField.def_phone}</p>}
                                        {props.formField.cell_phone && <p className="fw-bold m-0 p-0  mb-0 num">Cell: {props.formField.cell_phone && props.formField.cell_phone}</p>}
                                        {props.formField.help_desk_phone && <p className="fw-bold m-0 p-0  mb-0 num">Help Desk: {props.formField.help_desk_phone && props.formField.help_desk_phone}</p>}
                                        <p className="mb-0 email fw-bold"> <b>{props.formField.contact_email}</b></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {props?.formField && props.formField.card_type === 'both' &&
                        <div className={props.viewType !== 'preview' ? 'back' : 'mt-90pc'}>
                            {props.viewType !== 'preview' ? <span className="flipButton btn btn-secondary d-inline-block btn-theming" onClick={props.changeFlipCard}>Flip Card</span> : <h5 className="fs-4 fw-bold mb-3">Back View</h5>}
                            <section>
                                <div className="flip-box-back img_back_six">
                                    <div className="back-image position-relative">
                                        <img src="images/card-background-back.png" className="img-fluid" alt="card" />
                                    </div>
                                    <div className={`back-logo   ${props.viewType === 'preview' ? 'mt-25pc' : ''}`}>
                                        <img src={props.newImg ? props.newImg : 'images/logo-card11-bck.png'} className={`img-fluid ${props.changeBackViewClass ? "abc":""}`} alt="card" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Design6