import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap";
import './index.scss';
import './css/custom.css';

import Routing from './components/Routing';
import Icons from './components/Icons';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router >
            <Routing />
        </Router>
        <Icons />
    </React.StrictMode>
);

reportWebVitals();
