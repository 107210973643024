import React from 'react'
import { CardSection } from '../../components/Elements'

function EngagementStatus(props) {
    return (
        <CardSection title="Engagement Status">
            <div className="d-flex justify-content-center align-items-center align-slef-center text-success text-center">
                <svg className="icon fs-2" role="img"><use href="#icon_chat"></use></svg>
                <h3 className="mt-2 ps-2 pt-4 fw-bold">{props.data && props.data.total}</h3>
            </div>
            <h5 className='fw-bold text-blue text-center'>Engagements</h5>
            <hr className="bg-secondary opacity-20"></hr>

            <div className="d-flex justify-content-center align-items-center py-5">
                <div className="mx-5">
                    <div className="d-flex align-items-center text-success">
                        <svg className="icon fs-1 fw-bold" role="img"><use href="#icon_envalop"></use></svg>
                        <p className="mb-0 ps-2 pt-3 fw-bold fs-3">{props.data && props.data.open}</p>
                    </div>
                    <h5 className="fw-bold text-blue text-center mt-2">Open</h5>
                </div>
                <div className="mx-5">
                    <div className="d-flex align-items-center text-danger">
                        <svg className="icon fs-1 fw-bold" role="img"><use href="#icon_noti"></use></svg>
                        <p className="mb-0 ps-2 pt-3 fw-bold fs-3">{props.data && props.data.late}</p>
                    </div>
                    <h5 className="fw-bold text-blue text-center mt-2">Past Due</h5>
                </div>
            </div>
        </CardSection>
    )
}

export default EngagementStatus
