import React, { useContext, useEffect, useState } from "react";
import ReactDom from 'react-dom';
import { fetchData, EDIT_LOCATION, ADD_DROPDOWN_DATA, ADD_LOCATION, DELETE_LOCATION } from "../../components/Service";
import { initialFormState, validateForm } from "../../components/Helper";
import ImageCropper from "../../components/ImageCropper";
import * as Elements from "../../components/Elements";
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker';
import RequireInfo from "./RequireInfo";
import BusinessDetails from "./BusinessDetails";
import EmailPhoto from "./EmailPhoto";
import SocialMedia from "./SocialMedia";
import EnhancedContent from "./EnhancedContent";
import { addField, jsReload } from "./LocationJs";
import { now } from "moment";
import { Context } from "../../components/Context";

function LocationForm(props) {

    const [editId, setEditId] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [calender, setCalender] = useState([]);
    const [bios, setBios] = useState([]);
    const [menu, setMenu] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedCalender, setSelectedCalender] = useState([]);
    const [selectedBios, setSelectedBios] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState([]);
    const [allImage, setAllImage] = useState('');
    const [imageName, setImageName] = useState('');
    const [requireInfo, setRequireInfo] = useState('');
    const [businessDetail, setBusinessDetail] = useState('');
    const [emailPhotoInfo, setEmailPhotoInfo] = useState('');
    const [socialInfo, setSocialInfo] = useState('');
    const [enhancedInfo, setEnhancedInfo] = useState('');
    const [pageLoader, setPageLoader] = useState(false);
    const [context] = useContext(Context)

    const getDropdownData = (type) => {
        fetchData(`${ADD_DROPDOWN_DATA}?select=${type}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                if (type === 'category') { setCategory(res.records) }
                else if (type === 'product') { setProduct(res.records) }
                else if (type === 'event') { setCalender(res.records) }
                else if (type === 'menu') { setMenu(res.records) }
                else if (type === 'bios') { setBios(res.records) }
            }
        });
    }

    const showFeilds = (id = '') => {
        var element = document.getElementById(id);
        element.classList.toggle("d-none");
    }

    const resetForm = () => {
        $("#business_hours").removeAttr('checked');
        $("#hours").addClass('d-none')
        $("#add_phone").addClass('d-none')
        document.querySelectorAll('#payment_method input[type=checkbox]:checked').forEach(function (e) {
            e.removeAttribute('checked')
        })
        document.querySelectorAll('.editHours').forEach(function (e) {
            e.remove()
        })
        document.querySelectorAll(".remove").forEach(function (el) {
            el.parentElement.remove();
        });

        $(".react-select").removeClass('is-invalid is-valid');

        setEditId(false)
        setRequireInfo('')
        setEmailPhotoInfo('')
        setAllImage('')
        setImageName('')
        setSocialInfo('')
        setEnhancedInfo('')
        setBusinessDetail('')
        setSelectedBios([])
        setSelectedCalender([])
        setSelectedMenu([])
        setSelectedMenu([])
        setSelectedCategory([])
        initialFormState('locationForm')
    }

    useEffect(() => {
        resetForm();
        if (props.editId && typeof (props.editId) !== "boolean") {
            setPageLoader(true)
            setEditId(props.editId);
            fetchData(`${EDIT_LOCATION}?id=${props.editId}`, 'GET', '', true, false, (res) => {
                if (res.records) {
                    if (res.records.alternate_phone || res.records.fax_phone || res.records.mobile_phone || res.records.tty_phone) {
                        $("#add_phone").removeClass('d-none')
                    }
                    setRequireInfo({
                        business_name: res.records.buisness_name,
                        business_alias: res.records.business_alias,
                        address: res.records.address,
                        city: res.records.city,
                        state: res.records.state,
                        zip_code: res.records.zip_code,
                        main_phone: res.records.main_phone,
                        alternate_phone: res.records.alternate_phone,
                        fax_phone: res.records.free_phone,
                        mobile_phone: res.records.mobile_phone,
                        tty_phone: res.records.tty_phone,
                        featured_message: res.records.featured_message,
                        featured_message_url: res.records.featured_message_url,
                    })
                    setSelectedCategory(res.records.category_ids)
                    setSelectedProduct(res.records.product_id)
                    setSelectedBios(res.records.bio_id)
                    setSelectedCalender(res.records.event_id)
                    setSelectedMenu(res.records.menu_id)

                    if (res.records.business_hours && res.records.business_hours.length > 0) {
                        $("#business_hours").attr('checked', true);
                        $("#hours").removeClass('d-none')
                    }
                    setBusinessDetail({
                        business_description: res.records.business_description,
                        keywords: res.records.keywords,
                        competitors: res.records.competitors,
                        business_hours: res.records.business_hours,
                        additional_hours_info: res.records.additional_hours_info,
                        holiday_hours: res.records.holiday_hours,
                        payment_methods: res.records.payment_methods,
                    })
                    setEmailPhotoInfo({
                        contact_email: res.records.contact_email,
                        main_website: res.records.main_website,
                        display_website: res.records.display_website,
                        business_logo: res.records.business_logo,
                        more_images: res.records.more_images,
                        youtube_video: res.records.youtube_video,
                    })
                    setAllImage({
                        more_photo: res.records.more_images,
                        logo: res.records.business_logo,
                        google_cover: res.records.google_cover_img,
                        google_profile: res.records.google_profile_img,
                        facebook_cover: res.records.facebook_cover_img,
                        facebook_profile: res.records.facebook_profile_img,
                    })
                    setSocialInfo({
                        twitter_handle: res.records.twitter_handle,
                        facebook_page_url: res.records.facebook_page_url,
                        instagram_handle: res.records.instagram_handle,
                    })
                    setEnhancedInfo({
                        product_lists_label: res.records.product_lists_label,
                        menus_label: res.records.menus_label,
                        event_lists_label: res.records.event_lists_label,
                        bio_lists_label: res.records.bio_lists_label,
                    })

                    if (res.records.business_hours && res.records.business_hours.length > 0) {
                        res.records.business_hours.forEach((item) => {
                            document.getElementById("editHour").insertAdjacentHTML('beforebegin', `<div class="input-group mb-3 editHours">
                                <input type="text" readOnly class="form-control" name="days[]" value="${item.days}" />
                                <select class="form-select split_time" name="business_hourtype[]">
                                    <option value="">Select</option>
                                    <option value="open" ${item.business_hourtype === 'open' ? "selected" : ''}>Open</option>
                                    <option value="split" ${item.business_hourtype === 'split' ? "selected" : ''}>Split</option>
                                    <option value="24" ${item.business_hourtype === '24' ? "selected" : ''}>24 Hours</option>
                                    <option value="close" ${item.business_hourtype === 'close' ? "selected" : ''}>Close</option>
                                </select>
                                <input type="text" class="form-control"  name="business_hour_from[]" ${item.business_hourtype === 'open' || item.business_hourtype === 'split' ? "" : "readOnly"} placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${item.business_hour_from ?? '00:00'}" />
                                <span class="input-group-text">to</span>
                                <input type="text" class="form-control col-4" name="business_hour_to[]" ${item.business_hourtype === 'open' || item.business_hourtype === 'split' ? "" : "readOnly"} placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${item.business_hour_to ?? '00:00'}" />
                                ${item.business_hourtype === 'split' ?
                                    `<span class="input-group-text remove_split_time">and</span>
                                <input type="text" class="form-control col-4 remove_split_time" name="business_hour_split_from[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${item.business_hour_split_from ?? '00:00'}" />
                                <span class="input-group-text remove_split_time">to</span>
                                <input type="text" class="form-control col-4 remove_split_time" name="business_hour_split_to[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${item.business_hour_split_to ?? '00:00'}">` : ''}
                            </div>`);
                        });
                    }

                    if (res.records.holiday_hours && res.records.holiday_hours.length > 0) {
                        res.records.holiday_hours.forEach((item) => {
                            let hr_from = "";
                            let hr_to = "";
                            let split_hr_from = "";
                            let split_hr_to = "";
                            let holiday_hr_type = "";
                            let holiday_date = new Date(item.date)

                            let modified_holiday_date = holiday_date.getDate() + '-' + (holiday_date.getMonth() + 1) + '-' + holiday_date.getFullYear()

                            if (item.isRegularHours) {
                                holiday_hr_type = "regular_hours";
                            } else if (item.hours === "") {
                                holiday_hr_type = "close";
                            } else if (item.hours === "0:00:0:00" || item.hours === "00:00:00:00") {
                                holiday_hr_type = "24";
                            } else if (item.hours.indexOf(',') !== -1) {
                                holiday_hr_type = "split";
                                let split_hr = item.hours.split(',')
                                let split_first = split_hr[0].split(':')
                                let split_second = split_hr[1].split(':')

                                hr_from = split_first[0] + ':' + split_first[1]
                                hr_to = split_first[2] + ':' + split_first[3]

                                split_hr_from = split_second[0] + ':' + split_second[1]
                                split_hr_to = split_second[2] + ':' + split_second[3]
                            } else {
                                holiday_hr_type = "open";
                                let split_hr = item.hours.split(':')
                                hr_from = split_hr[0] + ':' + split_hr[1]
                                hr_to = split_hr[2] + ':' + split_hr[3]
                            }

                            $("#holiday_hours").prepend(`
                            <div class="input-group mb-3">
                                <input type="select" class="form-control select_date" placeholder="Select Date" required name="holiday_date[]" value="${modified_holiday_date}">
                                <select class="form-select split_time" name="holiday_type[]">
                                    <option value="">Select</option>
                                    <option value="open" ${holiday_hr_type === 'open' ? "selected" : ''}>Open</option>
                                    <option value="split" ${holiday_hr_type === 'split' ? "selected" : ''}>Split</option>
                                    <option value="24" ${holiday_hr_type === '24' ? "selected" : ''}>24 Hours</option>
                                    <option value="close" ${holiday_hr_type === 'close' ? "selected" : ''}>Close</option>
                                    <option value="regular_hours" ${holiday_hr_type === 'regular_hours' ? "selected" : ''}>Regular Hours</option>
                                </select>
                                <input type="text" class="form-control" name="holiday_hour_from[]" ${holiday_hr_type === 'open' || holiday_hr_type === 'split' ? "" : "readOnly"} placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${hr_from ?? '00:00'}">
                                <span class="input-group-text">to</span>
                                <input type="text" class="form-control col-4" name="holiday_hour_to[]" ${holiday_hr_type === 'open' || holiday_hr_type === 'split' ? "" : "readOnly"} placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${hr_to ?? '00:00'}">
                                ${holiday_hr_type === 'split' ? `
                                <span class="input-group-text remove_split_time">and</span>
                                <input type="text" class="form-control col-4 remove_split_time" name="holiday_split_hour_from[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${split_hr_from ?? '00:00'}" >
                                <span class="input-group-text remove_split_time">to</span>
                                <input type="text" class="form-control col-4 remove_split_time" name="holiday_split_hour_to[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" value="${split_hr_to ?? '00:00'}">` : ''}
                                <span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span>
                            </div > `);
                        });
                    }

                    if (res.records.keywords && res.records.keywords.length > 0) {
                        res.records.keywords.forEach((value) => {
                            document.getElementById("keyword").insertAdjacentHTML('beforebegin', `<div class="input-group mb-2"><input type="text" class="form-control" placehlder="Enter Keyword" value="${value}" name="keywords[]" /><span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span></div>`);
                        });
                    }
                    if (res.records.competitors && res.records.competitors.length > 0) {
                        res.records.competitors.forEach((item) => {
                            document.getElementById("competitor").insertAdjacentHTML('beforebegin', `<div class="input-group mb-2"><input type="text" class="form-control" placeholder="Name" value="${item && item.name ? item.name : ''}" name="competitors[]" /><input type="url" class="form-control" placeholder="Website URL" value="${item && item.website ? item.website : ''}" name="competitor_url[]" /><span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span></div>`);
                        });
                    }
                    if (res.records.contact_email && res.records.contact_email.length > 0) {
                        res.records.contact_email.forEach((value) => {
                            document.getElementById("addemail").insertAdjacentHTML('beforebegin', `<div class="input-group mb-2"><input type="text" class="form-control" placehlder="Enter Keyword" value="${value}" name="keywords[]" /><span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span></div>`);
                        });
                    }
                    setTimeout(() => {
                        addField('refresh')
                        jsReload()
                        setPageLoader(false)
                    }, 0);
                }
            });
        } else {
            setTimeout(() => {
                addField('refresh')
                jsReload()
            }, 0);
        }
    }, [props.editId])

    useEffect(() => {
        jsReload();
        getDropdownData('category')
        getDropdownData('product')
        getDropdownData('event')
        getDropdownData('menu')
        getDropdownData('bios')
    }, [])

    const deleteLocation = (locationId) => {
        fetchData(DELETE_LOCATION, 'DELETE', { location: locationId }, true, false, (res) => {
            setTinyloader(false)
            if (res.success) {
                document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
                if (props.refresh) props.refresh(now)
            }else{
                document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
            }

        })
    }

    const handleSubmitForm = (e) => {
        let formdata = new FormData(document.getElementById('locationForm'));
        formdata.append('category', selectedCategory)
        formdata.append('product', selectedProduct)
        formdata.append('event', selectedCalender)
        formdata.append('menu', selectedMenu)
        formdata.append('bios', selectedBios)
        formdata.append('business_logo', JSON.stringify(allImage.logo))
        formdata.append('more_images', JSON.stringify(allImage.more_photo))
        formdata.append('google_cover_img', JSON.stringify(allImage.google_cover))
        formdata.append('google_profile_img', JSON.stringify(allImage.google_profile))
        formdata.append('facebook_cover_img', JSON.stringify(allImage.facebook_cover))
        formdata.append('facebook_profile_img', JSON.stringify(allImage.facebook_profile))

        if (validateForm(e)) {
            setTinyloader(true)

            fetchData(editId ? `${EDIT_LOCATION}?id=${editId}` : ADD_LOCATION, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    if (props.refresh) props.refresh(now)
                    document.querySelector('#locationForm [data-bs-dismiss="modal"]').click();
                }
            }, false, false, false)
        }
    }

    return (
        <>
            {pageLoader && <div className="text-center py-5">
                <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-3">Please wait for response. This may take a while...</p>
            </div>}

            <form className={`needs-validation p-3 ${pageLoader ? 'd-none' : 'd-block'}`} id="locationForm">
                <input type="hidden" name="id" id="location_id" defaultValue={editId} />
                <div className="row mb-3">
                    <label htmlFor="country" className="col-md-3 text-md-end col-form-label">Select Country:</label>
                    <div className="col-md-8">
                        <select className="form-select" id="country" name="country">
                            <option value="US">United States</option>
                        </select>
                    </div>
                </div>
                {/* <div className="row mb-3">
                    <label htmlFor="location_name" className="col-md-3 text-md-end col-form-label">Location Tag</label>
                    <div className="col-md-8">
                        <select className="form-select" id="location_name" name="location_name">
                            <option value="CED">CED</option>
                        </select>
                    </div>
                </div> */}

                <RequireInfo category={category ?? []} setSelectedCategory={setSelectedCategory ?? []} value={selectedCategory} showFeilds={showFeilds} formdata={requireInfo} />

                <BusinessDetails addField={addField} showFeilds={showFeilds} formdata={businessDetail} edit={editId} />

                <EmailPhoto addField={addField} showFeilds={showFeilds} allImage={allImage} setAllImage={setAllImage} setImageName={setImageName} formdata={emailPhotoInfo} />

                <SocialMedia allImage={allImage} setImageName={setImageName} setAllImage={setAllImage} formdata={socialInfo} />

                <EnhancedContent
                    product={product}
                    setSelectedProduct={setSelectedProduct}
                    productValue={selectedProduct}
                    calender={calender}
                    setSelectedCalender={setSelectedCalender}
                    calenderValue={selectedCalender}
                    bios={bios}
                    setSelectedBios={setSelectedBios}
                    biosValue={selectedBios}
                    menu={menu}
                    setSelectedMenu={setSelectedMenu}
                    menuValue={selectedMenu}
                    formdata={enhancedInfo}
                />

                <div className="row text-end">
                    <div className="col-sm-4">
                        {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                            context.auth.permission && context.auth.permission.map((item, key) => {
                                return(
                                    <div key={key}>
                                        {item.permission === "Manage Location" &&
                                            <>
                                                {item.delete === "yes" ? 

                                                    <>
                                                        {editId && <button type="button" className="btn btn-danger px-4 ms-2" data-bs-toggle="modal" data-bs-target="#confModal" disabled={tinyloader}>Delete Location Permanently</button>}
                                                    </>
                                                
                                                : 
                                                    <button type="button" className="btn btn-danger px-4 ms-2" disabled title="No permission">Delete Location Permanently</button>
                                                }
                                            </>
                                        }
                                    </div>
                                )
                            })
                        : 
                            <>
                                {editId && <button type="button" className="btn btn-danger px-4 ms-2" data-bs-toggle="modal" data-bs-target="#confModal" disabled={tinyloader}>Delete Location Permanently</button>}
                            </>
                        }

                    </div>
                    <div className="col-sm-7">
                        <button type="button" className="btn btn-dark px-4 ms-2" onClick={(e) => handleSubmitForm(e)} disabled={tinyloader}>
                            {tinyloader ?
                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> : editId ? "Update Location" : "Add Location"}
                        </button>
                        {/* <button type="button" className="btn btn-dark px-4 ms-2" onClick={(e) => handleSubmitForm(e)}>Submit</button> */}
                        <button type="button" className="btn btn-secondary px-4 ms-2" data-bs-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                </div>
            </form>

            <React.Fragment>
                {ReactDom.createPortal(<ImageCropper name={imageName} cropImage={allImage} setCropImage={setAllImage} openModal={props.modalId ?? null} />, document.getElementById('second_root'))}
            </React.Fragment>

            <React.Fragment>
                {ReactDom.createPortal(<Elements.ConfirmationModal msg="Are you sure to delete this location?" method={() => deleteLocation(editId)} />, document.getElementById('second_root'))}
            </React.Fragment>

            {/* How to use */}
            {/* <Elements.ModalSection title={editId ? "Edit Location" : "Add Location"} modal_id="locationModal" size="xl">
            <LocationForm editId={editId} modalId="#locationModal" />
        </Elements.ModalSection> */}
        </>
    )
}

export default LocationForm
