import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { CardSection } from '../Elements';
Exporting(Highcharts);

function ListingDeviceByWeekDay(props) {
    useEffect(() => {
        let data = props.data
        if(data){
            Highcharts.chart('container', {
                chart: {
                    spacingBottom: 0,
                    spacingTop: 10,
                    spacingLeft: 0,
                    spacingRight: 0,
                    type: 'column',
        
        
                },
             colors: [
                 '#4662a0',
                 '#aadb87',
                 '#da495b',
                 '#a87bc6', 
                 '#fde5a5',
                 '#66ceed',
                 '#d565ad',
                 '#7ea45d',
                 '#eace6b',
                 '#66edc6',
                 '#fdb7a5'
              ],
                title: {
                    text: ''
                },
                xAxis: {
                    categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                // plotOptions: {
                //     column: {
                //         stacking: 'percent'
                //     }
                // },
                series: [{
                    name: 'Desktop',
                    data:  data && data.desktop ? Object.values(data.desktop): 0
                }, {
                    name: 'Mobile',
                    data:  data && data.mobile ? Object.values(data.mobile): 0
                }, {
                    name: 'Tablet',
                    data:  data && data.tablet ? Object.values(data.tablet): 0
                }, {
                    name: 'Other',
                    data:  data && data.other ? Object.values(data.other): 0
                }]
            });
        }else{
            document.getElementById('container').innerHTML = "No Review Found"
        }
    }, [props]);
    return (
        <CardSection title="Listing Device Usage by Weekday">
            <div id="container"></div>
        </CardSection>
    )
}

export default ListingDeviceByWeekDay
