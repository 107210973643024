import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

function LocationListingStatus(props) { 

    useEffect(() => {
        
        Highcharts.chart('container-status', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                // margin: [0, 0, 0, 0],
                spacingBottom: 0,
                spacingTop: 0,
                spacingLeft: 0,
                spacingRight: 0,
                type: 'pie'
            },
            colors: [
                '#4662a0',
                '#aadb87',
                '#da495b',
                '#a87bc6',
                '#fde5a5',
                '#66ceed',
                '#d565ad',
                '#7ea45d',
                '#eace6b',
                '#66edc6',
                '#fdb7a5'
            ],
            title: {
                text: ''
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    }
                }
            },
            series: [{
                name: '',
                colorByPoint: true,
                data: props.data
            }]
        });
    }, [props]);
    return (
        <div id="container-status" style={{height: '270px', margin:'0px auto'}}></div>
    )
}

export default LocationListingStatus
