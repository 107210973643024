import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';

Exporting(Highcharts);

function ReviewByMonth(props) {

    useEffect(() => {
        let negativeData  = props.data_negative;
        let positiveData = props.data_positive;
        let neutral = props.data_neutral;
        let average = props.data_average;
        Highcharts.chart('container', {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: negativeData ? Object.keys(negativeData) : 0
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: 'Positive',
                color :' #4662a0',
                data: positiveData ? Object.values(positiveData) : 0
            }, {
                name: 'Negative',
                color :' #da495b',
                data: negativeData ? Object.values(negativeData) : 0
            }, {
                name: 'Neutral',
                color : '#aadb87',
                data: neutral ? Object.values(neutral) : 0
            }, {
                type: 'spline',
                name: 'Average',
                data: average ? Object.values(average) : 0,
                marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: 'white'
                }
            }]
        });

    }, [props.data_negative, props.data_positive,props.data_neutral,props.data_average]);
    return (
        <div id="container"></div>
    )
}

export default ReviewByMonth
