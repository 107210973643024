import { useEffect, useState } from "react"
import { srcToBase64 } from "../../components/Helper"

function PdfView(props){
    const [src, setSrc] = useState('')
    useEffect(()=> {
        if(props && props.data && props.data.business_logo) {
            srcToBase64(props.data.business_logo, res => {
                setSrc(res)
            })
        }
    }, [props])
    return(
        <>
        {
            <style>{`
                .wrapper{
                    width: 100%;
                    max-width: 630pt; 
                    margin: 0 auto; 
                    box-shadow: none;
                    background: #fff;
                    position: relative;
                    overflow: hidden;
                    font-family: 'Open Sans', sans-serif;
                    color: #58585a;
                    font-size: 13px;
                }
                .row-pdf{
                    display: flex;
                    justify-content: space-between;
                }
                .row-pdf-x35{
                    padding: 0 35px;
                }
                .col-pdf-100{
                    width: 100%;
                    padding: 1px 50px;
                }
                .col-pdf-50{
                    width: 50%;
                    padding: 1px 15px;
                }
                .card-box-pdf{
                    display: flex;
                    align-items: flex-start;
                }
                .card-icon{
                    width: 80px;
                    min-width: 80px;
                    max-width: 80px;
                }
                .card-content{
                    padding-top: 12px;
                    padding-left: 5px;
                }
                .card-content h5{
                    margin: 0 0 4px 0;
                    font-size: 16px;
                    color: #404041;
                    font-weight: 700;
                }
                .card-content ul{
                    list-style-type: none;
                    padding: 0;
                    margin: 5px 0;
                    font-weight: 700;
                    font-size: 11px;
                    line-height: 20px;
                    color: #404041;
                }
                .card-content ul li img{
                    width:11px;
                    height:11px;
                    margin-bottom:-3px;
                    margin-right: 6px;
                }
                .img-orange, .img-green, .img-blue{ display: none; margin-bottom: 3px;}
                .wrapper-orange .img-orange, 
                .wrapper-green .img-green, 
                .wrapper-blue .img-blue{ display: block; }
                .welcome-text{
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: 0.5px;
                    margin: 0;
                }
                .wrapper-orange .welcome-text, .wrapper-orange .text-pdf-color, .text-pdf-link{
                    color: #ce624f;
                }
                .wrapper-green .welcome-text, .wrapper-green .text-pdf-color, .wrapper-green .text-pdf-link{
                    color: #499e65;
                }
                .wrapper-blue .welcome-text, .wrapper-blue .text-pdf-color, .wrapper-blue .text-pdf-link{
                    color: #3a74a4;
                }
                .customer-text{
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 36px;
                    letter-spacing: 0.5px;
                    margin: 0 0 8px 0;
                    color: #404041;
                }
                .text-pdf-bold,.text-pdf-color, .text-pdf-link{font-weight: 700; text-decoration: none;}
                .text-pdf-center{text-align: center;}
                .mt-10px{margin-top: 10px} .mt-15px{margin-top: 15px} .mt-20px{margin-top: 20px}
                p{
                    margin: 0 0 1px 0;
                }
                .footer-pdf{
                    padding-top: 140px;
                    position: relative;
                    width: 100%;
                    background: url(images/footer-bg.png);
                    z-index: 0;
                    display: flex;
                    align-items: flex-start;
                    color: #58585a;
                    font-size: 11px;
                    padding-bottom: 20px;
                }
                .footer-pdf h6{
                    font-size: 11px;
                    margin-top: 0;
                    margin-bottom: 1px;
                    font-weight: 700;
                }
                .footer-pdf a{
                    color: #58585a;
                    text-decoration: none;
                }
                .footer-logo{
                    padding-left: 50px;
                }
                .footer-logo img{
                    width: 110px;
                }
                .footer-address{
                    max-width: 240px;
                    padding-left: 20px;
                }
                .footer-tel{
                    padding-left: 20px;
                    margin-top: 13px;
                }
                .footer-contact{
                    margin-left: auto;
                    text-align: right;
                    padding-right: 50px;
                }
                .mtn-108{
                    margin-top: -108px
                }
                .w-80{
                    width: 80%;
                }
                .mbn-92{
                    margin-bottom: -92px
                }
                .download {
                    position: relative;
                    z-index: 2;
                }
            `}
            </style>
        }
            <div className={`wrapper wrapper-${props.data.viewtype === 'isn' ? 'blue': props.data.viewtype}`} id="container" >
                <img src="images/poster-orange.jpg" className="img-orange w-100" alt=""/>
                <img src="images/poster-green.jpg" className="img-green w-100" alt=""/>
                <img src="images/poster-blue.jpg" className="img-blue w-100" alt=""/>
                <div className="row position-relative mtn-108">
                    <div className="col-pdf-100">
                        <h3 className="welcome-text">Welcome to</h3>
                        <h2 className="customer-text">Customer Portal</h2>
                        <p>Our storefront at your fingertips. Ready to assist wherever and whenever you want.</p>
                        <p>Register today: <a href="http://123" className="text-pdf-link">{props.data.pc_url}</a></p>
                    </div>
                </div>
                <div className="row-pdf row-pdf-x35">
                    <div className="col-pdf-50 mt-20px">
                        <div className="card-box-pdf">
                            <div className="card-icon">
                                <img className="w-80" src="images/icon-1.svg" alt=""/>
                            </div>
                            <div className="card-content">
                                <h5>Product Search</h5>
                                <p>
                                    Explore our vast product catalog, and find what you are looking for using our powerful online search tools.
                                </p>
                                <ul>
                                    <li> <i className="bi bi-check"></i> Get real–time pricing &amp; availability</li>
                                    <li> <i className="bi bi-check"></i> Create custom catalogs</li>
                                    <li> <i className="bi bi-check"></i> Create keywords to easily find products</li>
                                    <li> <i className="bi bi-check"></i> Easy and quick re-ordering</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-pdf-50 mt-20px">
                        <div className="card-box-pdf">
                            <div className="card-icon">
                                <img className="w-80" src="images/icon-2.svg" alt=""/>
                            </div>
                            <div className="card-content">
                            <h5>Order Management</h5>
                            <p>
                                Customer Portal gives you a robust set of tools to manage, track, and view insights about your product orders.
                            </p>
                            <ul>
                                <li> <i className="bi bi-check"></i> Text notifications about orders</li>
                                <li> <i className="bi bi-check"></i> Track order status</li>
                                <li> <i className="bi bi-check"></i> See entire order history</li>
                                <li> <i className="bi bi-check"></i> Reorder and export orders</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-pdf row-pdf-x35">
                    <div className="col-pdf-50 mt-15px">
                        <div className="card-box-pdf">
                            <div className="card-icon">
                                <img className="w-80" src="images/icon-3.svg" alt=""/>
                            </div>
                            <div className="card-content">
                                <h5>Payments Made Easy</h5>
                                <p>
                                    We’ve made the checkout process simple, convenient, and best of all – it is designed to accomodate your preferences. 
                                </p>
                                <ul>
                                    <li> <i className="bi bi-check"></i> Order and pay on–account</li>
                                    <li> <i className="bi bi-check"></i> Pay statements on our payment portal</li>
                                    <li> <i className="bi bi-check"></i> Pay through QuickBooks</li>
                                    <li> <i className="bi bi-check"></i> Track and view all payment history</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-pdf-50 mt-15px">
                        <div className="card-box-pdf">
                            <div className="card-icon">
                                <img className="w-80" src="images/icon-4.svg" alt=""/>
                            </div>
                            <div className="card-content">
                                <h5>And Much More...</h5>
                                <p>
                                    Your customer portal, providing you with quick and easy access to the information you need to keep busines flowing.
                                </p>
                                <ul>
                                    <li> <i className="bi bi-check"></i>QuickBooks integration</li>
                                    <li> <i className="bi bi-check"></i>Manage jobs</li>
                                    <li> <i className="bi bi-check"></i>Download price files</li>
                                    <li> <i className="bi bi-check"></i>Request quotes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-pdf">
                    <div className="col-pdf-100 text-pdf-center">
                        <h4 className="fs-6 fw-bolder mt-5">Available for iOS and Android.</h4>
                        <p><span>Search for </span><span className="text-pdf-bold text-pdf-color">
                        {
                            props.data.viewtype ==='orange' ? <span>All Phase Connect</span> :
                            props.data.viewtype ==='green' ? <span>CED Greentech Connect</span>:
                            props.data.viewtype ==='blue' ? <span>CED Connect</span>:<span>ISN Connect</span>
                        }
                        </span> <span>on the Apple App Store or Google Play Store.</span></p>
                        <div className="mt-3 mbn-92 download">
                            <span title="App Store"><img className="mmw-80" src="images/app-store.svg" alt=""/></span>
                            <span  title="Play Store"><img className="mmw-80" src="images/play-store.svg" alt=""/></span>
                        </div>
                    </div>
                </div>
                <div className="footer-pdf">
                    <div className="footer-logo">
                        <img src={src ? src : props.data.default_logo} alt="logo"/>
                    </div>
                    <div className="footer-address">
                        <h6>{props.data.pc_name}</h6>
                        <p>{props.data.address}</p>
                        <a href="mailto:mode@xipetech.com">{props.data.contact_email}</a>
                        <p>Phone: {props.data.phone}</p>
                        { props.data.text_address && <p>Text Us: {props.data.text_address}</p>  }
                        
                    </div>
                    <div className="footer-contact">
                        <h6>Contact Us</h6>
                        <a href="mailto:support@shopportal.com">support@shopportal.com</a>
                        <p>972-582-8305</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PdfView