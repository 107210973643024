import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { CardSection } from '../Elements';
Exporting(Highcharts);

function GoogleViewMapSearch(props) {
    useEffect(() => {
        let category = props.data[0];
        let map_view = props.data[1];
        let search_view = props.data[2]
        Highcharts.chart('container6', {
            chart: {
                type: 'area',
                spacingBottom: 0,
                spacingTop: 10,
                spacingLeft: 0,
                spacingRight: 0,
            },
         colors: [
           '#4662a0',
           '#aadb87',
           '#da495b',
           '#a87bc6',
           '#fde5a5',
           '#66ceed',
           '#d565ad',
           '#7ea45d',
           '#eace6b',
           '#66edc6',
           '#fdb7a5'
                ],
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: category ? category : 0,
                tickmarkPlacement: 'on',
                title: {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            tooltip: {
                shared: true,
            },
            plotOptions: {
                area: {
    
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        } 
                    }
                }
            },
            series: [{
                name: 'Google Maps Views',
                data: map_view ? map_view : 0
            }, {
                name: 'Google Search Views',
                data: search_view ? search_view : 0
            }
            ]
        });
    }, [props]);
    return (
        <CardSection title="Google Views on Search and Maps">
            <div id="container6" style={{minWidth: '100%', height: '270px; margin: 0 auto'}}></div>
        </CardSection>
    )
}

export default GoogleViewMapSearch
