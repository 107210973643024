import React, { useEffect, useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function ImageCropper(props) {

    const ref = useRef()
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [croppedImage, setCroppedImage] = useState(undefined);
    const [imageRef, setImageRef] = useState();
    const [cropConfig, setCropConfig] = useState({
            unit: '%',
            width: 50,
            height: 40,
            // aspect: 5 / 3,
            // aspect: 1, //square
            x: 2,
            y: 5,
        });

    const cropImage = async(crop) => {
        document.getElementById('crop_image_error').innerText = ""
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(imageRef, crop, 'croppedImage.jpeg')
            setCroppedImage(croppedImage);
        }
    }

    const getCroppedImage = (sourceImage, cropConfig, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }
                    blob.name = fileName;
                    const croppedImageUrl = window.URL.createObjectURL(blob);
                    resolve(croppedImageUrl);
                }, 'image/jpeg'
            );
        });
    }

    const onUploadFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            let filename = event.target.files[0].name
            let ext = filename.split('.').pop();
            if(!(['jpg', 'jpeg', 'png'].includes(ext))){
                document.getElementById('crop_image_error').innerText = "Your image extention is not valid."
                return
            }
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                let image = new Image();
                image.src = reader.result;
                image.onload = function () {
                    if(image.height > 1192 || image.height < 200 || image.width < 200 || image.width > 2120){
                        document.getElementById('crop_image_error').innerText = "Please upload image with minimum 200 x 200 and maximum 2120 x 1192 pixel."
                        return
                    }else{
                        document.getElementById('drag_image_box').classList.add('d-none')
                    }
                    setImageToCrop(reader.result)
                }
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    useEffect(() => {
        document.getElementById("image_crop_modal").addEventListener('hidden.bs.modal', function (e) { 
            document.getElementById(props.openModal).click()
            document.getElementById('drag_image_box').classList.remove('d-none')
            ref.current.value = "";
            document.getElementById('crop_image_error').innerText = ""
            setImageToCrop(undefined)
            setCroppedImage(undefined)
            setImageRef('')
            setCropConfig({
                unit: '%',
                width: 50,
                height: 40,
                // aspect: 5 / 3,
                // aspect: 1, //square
                x: 2,
                y: 5,
            })
        });
    }, [props])

    const returnData = () => {
        if(!imageToCrop){
            document.getElementById('crop_image_error').innerText = "Please upload image."
            return
        }
        if(!croppedImage){
            document.getElementById('crop_image_error').innerText = "Please select crop area."
            return
        }
        let height = document.getElementById('cropImage').height
        let width = document.getElementById('cropImage').width
        if(height < 200 || width < 200){
            document.getElementById('crop_image_error').innerText = "Please upload image with minimum 200 x 200."
            return
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;  
            let info = {
            height: height,
            width: width,
            image:  croppedImage,
            url: base64data
            }    
            if(props.name === 'more_photo'){
                let old = []
                if(props.cropImage.more_photo){
                    old = props.cropImage.more_photo
                }
                old.push(info)
                props.setCropImage(prevState => ({
                    ...prevState,
                    [props.name]: old 
                }))
            }else{
                props.setCropImage(prevState => ({
                    ...prevState,
                    [props.name]: info 
                }))
            }      
        }
        
        (async () => {
          const response = await fetch(croppedImage)
          const imageBlob = await response.blob()
          reader.readAsDataURL(imageBlob);  
        })()

        document.querySelector('#image_crop_modal [data-bs-dismiss="modal"]').click();
    }

    return (
        <>
        <div className="modal fade" tabIndex="-1" id="image_crop_modal" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content rounded-10 shadow-lg">
                        <div className="modal-header px-sm-4 bg-light">
                            <h4 className="m-0">Crop Image</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body">
                            <div className="app">
                                <div id="drag_image_box">
                                    <label className="upload-files" style={{minHeight: '200px'}}>
                                        <input type="file" ref={ref} onChange={onUploadFile} name="selectedFile" accept=".jpg,.jpeg,.png,.gif" data-accept="jpg,jpeg,png,gif" data-fileupload="#imagenewfiles" multiple="" id="file" />
                                        <div className="pb-1">
                                            <i className="bi bi-cloud-arrow-up-fill fs-5 me-2"></i>
                                            Drop image here / Select Image
                                            <div className="small">(.png, .jpg, .jpeg)</div>
                                        </div>
                                    </label>
                                </div>
                                <ReactCrop
                                    src={imageToCrop}
                                    crop={cropConfig}
                                    ruleOfThirds
                                    keepSelection={true}
                                    minWidth={200}
                                    minHeight={200}
                                    className="d-block"
                                    onImageLoaded={(imageRef) => setImageRef(imageRef)}
                                    onComplete={(cropConfig) => cropImage(cropConfig)}
                                    onChange={(cropConfig) => setCropConfig(cropConfig)}
                                    crossorigin="anonymous"
                                />
                                <span className="text-danger" id="crop_image_error"></span>
                                { croppedImage && <div className="text-center bg-secondary mt-3 p-3">
                                    <h4 className="pb-2 text-white">Cropped Image</h4>
                                    <img alt="crop" className="img-fluid" id="cropImage" name="cropImage" src={croppedImage} /> 
                                    </div> 
                                }
                            </div>
                        </div>
                        <div className="modal-footer px-sm-4 bg-light">
                            <button type="button" className="btn btn-success px-4" title='Submit' onClick={returnData}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


function ShowImage(props){
    const removeImage = () => {
        if(props.name === 'more_photo'){
            let old = []
            if(props.cropImage.more_photo){
                old = props.cropImage.more_photo
            }
            old.splice(props.index, 1);
            props.setCropImage(prevState => ({
                ...prevState,
                [props.name]: old 
            }))
        }else{
            props.setCropImage(prevState => ({
                ...prevState,
                [props.name]: '' 
            }))
        }
    }

    return (
        <>
            { props.multiple ?
            <div className="ratio ratio-4x3 maxw-180 position-relative border rounded overflow-hidden m-2">
                <img src={props.src} alt="" className="objfit-cover" />
                <button type="button" onClick={removeImage} className="btn btn-sm btn-danger position-absolute mt-2 me-2 w-auto h-auto top-0 end-0" style={{left:'auto'}}>
                    <i className="bi bi-1p4 bi-trash"></i>
                </button>
            </div> : 
            <div className="d-flex flex-wrap rounded mt-2 border">
                <div className="ratio ratio-4x3 maxw-180 position-relative border rounded overflow-hidden m-2">
                    <img src={props.src} alt="" className="objfit-cover" />
                    <button type="button" onClick={removeImage} className="btn btn-sm btn-danger position-absolute mt-2 me-2 w-auto h-auto top-0 end-0" style={{left:'auto'}}>
                        <i className="bi bi-1p4 bi-trash"></i>
                    </button>
                </div>
            </div>}
        </>
    )
}

export default ImageCropper;
export { ShowImage }
