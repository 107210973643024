import { useContext } from "react";
import { Context } from "./Context";
import Unauthorized from "./Unauthorized";


const ProtectedRoute = ({permission, children}) => {

    const [context] = useContext(Context)
    let isPermission = '';
    let isSuperAdmin = context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "yes" ? true : false;
    let isClient = context && context.auth && context.auth.user_group_id === 3 ? true : false;

    


    {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" && context.auth.permission && context.auth.permission.map((ele, num) => {
        if(ele.permission === permission){
            
            if(ele.view === 'yes'){
                isPermission = true
            }
        }
    })}

    {context && context.auth && context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
        if(ele.permission === permission){
            
            if(ele.view === 'yes'){
                isPermission = true
            }
        }
    })}

    if(isPermission && context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no") {
        return children;
    }else if(isSuperAdmin){
        return children;
    }else if(isClient && isPermission){
        return children;
    }else{
        return <Unauthorized />
    }

}
export default ProtectedRoute;