import React, { useState } from 'react'
import DateRangeFilter from './DateRangeFilter'
import Select from "react-select"
import { downloadPdf, loadingData, toastNotify } from './Helper'
import { fetchData, DASHBOARD_OVERVIEW } from './Service'
import { useLocation } from 'react-router-dom'

const ChooseLocation = (props) => {
    const location = useLocation()
    const [selectedlocation, setSelectedLocation] = useState(props.default_location_selected);
    const selectLocation = (e) => {
        if (e.length > 10) {
            toastNotify({ error: 'You can select maximum 10 location at a time.' })
            return
        }
        setSelectedLocation(e ?? false)
    }

    const getDateRange = (startDate, endDate) => {
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = `${startDate} - ${endDate}`
        props.setFormfield(prevState => ({
            ...prevState, type: '', startDate: startDate, endDate: endDate
        }))
    }

    const refreshData = () => {
        document.querySelector('input[name="daterange"]').value = 'MM DD YYYY - MM DD YYYY'
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = 'Last 30 Days'
        props.setFormfield(prevState => ({
            ...prevState, type: 'thirtDay', startDate: '', endDate: ''
        }))
    }

    const getDateFilterData = (e, type) => {
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = e.target.innerText
        props.setFormfield(prevState => ({
            ...prevState, type: type, startDate: '', endDate: ''
        }))
    }

    const getData = (e) => {
        if (props.socialSection) {
            document.querySelector(".listings-locstasnacimg").classList.remove('active')
            var getData = document.querySelectorAll(".listings-locstasnacimg span[data-status=true]")
            getData.forEach(ele => ele.dataset.status = false)
        }
        let formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('location[]')
        if (location_selected.length <= 10) {
            if (location_selected.length > 0 && location_selected[0] !== '') {
                if (props && props.dashboardOverview) {
                    document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
                    document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
                    fetchData(`${DASHBOARD_OVERVIEW}?location=${location_selected}&type=${props.formfield.type}&startDate=${props.formfield.startDate}&endDate=${props.formfield.endDate}`, 'GET', '', true, false, (res) => {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                        if (res.records) {
                            props.setIncomingData(res.records)
                        } else { props.setIncomingData('0') }
                    }, false, false, false);
                }
                if (props && props.dashboardListing) {
                    document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
                    document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
                    fetchData(`${props.dashboardListing}?location=${location_selected}`, 'GET', '', true, false, (res) => {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                        if (res.records) {
                            props.setRecords(res.records)
                        } else { props.setRecords('0') }
                    }, false, false, false);
                }

                if (props && props.reviewList) {
                    document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
                    document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
                    fetchData(`${props.reviewList}?f_type=${location_selected}`, 'GET', '', true, false, (res) => {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                        if (res.records) {
                            props.setRecords(res.records)
                        } else { props.setRecords(0) }
                    }, false, false, false);
                }

                if (props && props.searchReport) {
                    document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
                    document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
                    fetchData(`${props.searchReport}?location=${location_selected}&type=${props.formfield.type}&startDate=${props.formfield.startDate}&endDate=${props.formfield.endDate}`, 'GET', '', true, false, (res) => {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                        if (res.records) {
                            props.setReport(res.records)
                        } else { props.setReport(0) }
                    }, false, false, false);
                }
            } else {
                toastNotify({ error: 'Please select atleast one location' })
                props.setIncomingData('0')
            }
        } else {
            toastNotify({ error: 'You can\'t select more than 10 location at a time.' })
        }
    }

    return (
        <div className="row my-3">
            <div className="col-4">
                <form className="col" id="searchForm">
                    <Select
                        value={selectedlocation}
                        name="location[]"
                        isClearable={true}
                        closeMenuOnSelect={false}
                        options={props && props.location ? props.location : []}
                        isMulti={props.single ?? true}
                        className="basic-multi-select react-select"
                        onChange={(e) => selectLocation(e)}
                        id="location_id"
                        required
                    />
                </form>
            </div>
            <div className="col">
                <input type="text" id="date_filter" readOnly className="form-control bg-white shadow-none pointer-event-active" defaultValue="Last 30 Days" onFocus={() => document.getElementById("show_list").classList.remove("d-none")} />
                <ul className="list-group show_list position-absolute bg-white shadow rounded d-none" id="show_list" style={{ listStyle: "none", width: '384px', zIndex: 2 }}>
                    <li className="list-group-item list-group-item-action">
                        <DateRangeFilter
                            daterange="MM DD YYYY - MM DD YYYY"
                            className="form-control"
                            maxDate={new Date()}
                            opens="left"
                            name='daterange'
                            reload={refreshData}
                            disableUpdateInput={false}
                            getDateRange={getDateRange}
                        />
                    </li>
                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "thirtDay")}>
                        Last 30 Days
                    </li>
                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastNinDay")}>
                        Last 90 Days
                    </li>
                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastTwelWeek")}>
                        Last 12 Week
                    </li>
                    {!(props.dashboardOverview || props.dashboardListing) && <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastSixtWeek")}>Last 16 Week</li>}

                    {(props.dashboardOverview || props.dashboardListing) && <>
                        <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastMonth")}>
                            Last Month
                        </li>
                        <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastThirtMonth")}>
                            Last 13 Month
                        </li>
                        <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastQuarter")}>
                            Last Quarter
                        </li> </>
                    }
                </ul>
            </div>
            <div className="col-2">
                <button className="btn btn-primary disabled-button" type="button" onClick={(e) => getData(e)} >Submit</button>
            </div>
            <div className="col">
                <div className="text-end">
                    <span className="btn btn-primary disabled-button me-1" onClick={(e) => getData(e)}><svg className="icon" role="button"><use href="#icon_refresh" /></svg></span>
                    <span className="btn btn-primary disabled-button" onClick={() => { loadingData(true); downloadPdf('generatePDF', 'AMAZIO_', false, location.pathname.match('/dashboard-listing') || location.pathname.match('/reputation-dashboard') ? false : true) }}><svg className="icon" role="button"><use href="#icon_pdf" /></svg></span>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ChooseLocation)