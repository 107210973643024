import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { BOOK_KEEPING_COUNT, fetchData } from "../../../components/Service"

const BookKeepingCard = (props) => {
    const [cardData, setCardData] = useState(0)
    const [tinyloader, setTinyloader] = useState(true)

    const changeCard = () => {
        props.changeCard && props.changeCard(props.cardType)
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (props.cardType === 'soci_users' || props.cardType === 'soci_locations') {
            setTimeout(() => {
                fetchData(BOOK_KEEPING_COUNT + '?cardType=' + props.cardType, 'GET', '', true, false, (res) => {
                    setTinyloader(false)
                    if (res.records) {
                        setCardData(res.records)
                    }
                }, false, signal, false);
            }, 500);
        } else {
            fetchData(BOOK_KEEPING_COUNT + '?cardType=' + props.cardType, 'GET', '', true, false, (res) => {
                setTinyloader(false)
                if (res.records) {
                    setCardData(res.records)
                }
            }, false, signal, false);
        }

        return () => {
            controller.abort()
        }
    }, [props.cardType])

    return (
        <div className="col-md-6 col-xl-2" onClick={changeCard}>
            <div className={`p-3 border rounded ${props.activeCardType === props.cardType && 'bg-blue-light-25'}`}>
                <p className="fw-medium fs-5">{props.title}</p>
                <div className="d-flex justify-content-between align-items-center">
                    {tinyloader ?
                        <Spinner className="spinner-border spinner-border-sm mx-3 wh-25">
                            <span className="visually-hidden"></span>
                        </Spinner>
                        :
                        <p className="fs-4 mb-0">{props.cardType === 'invoice_amount' && '$'}{cardData}</p>
                    }
                    <svg className="display-1 icon opacity-25" role="img">
                        {props.moduleType === 'user' && <use href="#icon_users" />}
                        {props.moduleType === 'location' && <use href="#icon_map" />}
                        {props.moduleType === 'invoice' && <use href="#icon_dollar" />}
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default BookKeepingCard