const VideoModal = () => {
    const closeModal = () =>{
        var modal = document.getElementById('video_play_modal');
        var append_video = document.getElementById('video_append_modal');
        append_video.innerHTML = '';
        modal.classList.remove('active');
    }
    return (
        <>
            <div className="video-modal" id="video_play_modal">
                <div className="video-closemodal" title="Close" onClick={() => closeModal()}>&times;</div>
                <div className="video-append" id="video_append_modal"></div>
            </div>
        </>
    )
}
export default VideoModal;