import React from 'react'
import { CardSection } from '../../components/Elements'

function ContentStatus(props) {
    return (
        <CardSection title="Content Status">
            { props.data && props.data.content_status && props.data.content_status.data ? <>
                <div className="d-flex justify-content-center align-items-center align-slef-center text-center py-2">
                    <svg className="icon text-blue fs-1 fw-bold" role="img"><use href="#icon_calender"></use></svg>
                    <p className="mb-0 h6 ps-2 pt-2 fw-bold text-success fs-3">{props.data.content_status.data.unpublished}</p>
                </div>
                <h5 className='fw-bold text-blue text-center'>Scheduled</h5>
                <hr className="bg-secondary opacity-20"></hr>

                <div className="d-flex justify-content-between my-5 align-items-center align-slef-center text-blue text-center p-3">
                    <div className="text-center">
                        <p className='mb-2 fs-5 fw-bold text-danger'><svg className="icon me-1" role="img"><use href="#icon_watch"></use></svg> {props.data.content_status.data.pending}</p>
                        <p className="mb-0 h6 fw-bold">Pending</p>
                    </div>
                    <div className="text-center">
                        <p className='mb-2 fs-5 fw-bold text-danger'><i className="bi bi-exclamation-lg fs-5 fw-bold"></i>{props.data.content_status.data.rejected}</p>
                        <p className="mb-0 h6 fw-bold">Rejected</p>
                    </div>
                    <div className="text-center">
                        <p className='mb-2 fs-5 fw-bold text-danger'><svg className="icon me-1" role="img"><use href="#icon_cross"></use></svg>{props.data.content_status.data.failed}</p>
                        <p className="mb-0 h6 fw-bold">Failed</p>
                    </div>
                </div>
            </> : 'No Review Found' }
        </CardSection>
    )
}

export default ContentStatus
