function SocailMediaContent(props) {

    let fbLikes = props.data && props.data.facebook && props.data.facebook.likes && props.data.facebook.likes.now
    let fbReach = props.data && props.data.facebook && props.data.facebook.reach && props.data.facebook.reach.now
    let fbEngaged = props.data && props.data.facebook && props.data.facebook.engaged && props.data.facebook.engaged.now
    let reachLast = (props.data) && (props.data.facebook) && (props.data.facebook.reach) && (props.data.facebook.reach.last)
    let engagedLast = props.data && props.data.facebook && props.data.facebook.engaged && props.data.facebook.engaged.last

    let twitLikes = props.data && props.data.twitter && props.data.twitter.followers && props.data.twitter.followers.now
    let twitReach = props.data && props.data && props.data.twitter && props.data.twitter.reach && props.data.twitter.reach.now
    let twitReachLast = props.data && props.data && props.data.twitter && props.data.twitter.reach && props.data.twitter.reach.last
    let twitEngaged = props.data && props.data.twitter && props.data.twitter.engaged && props.data.twitter.engaged.now
    let twitEngagedLast = props.data && props.data.twitter && props.data.twitter.engaged && props.data.twitter.engaged.last

    let gmbLikes = props.data && props.data.gmb && props.data.gmb.followers && props.data.gmb.followers.now && props.data.gmb.followers.now
    let gmbReach = props.data && props.data.gmb && props.data.gmb.views_maps && props.data.gmb.views_maps.now
    let gmbReachLast = props.data && props.data.gmb && props.data.gmb.views_maps && props.data.gmb.views_maps.last
    let gmbEngaged = props.data && props.data.gmb && props.data.gmb.views_search && props.data.gmb.views_search.now
    let gmbEngagedLast = props.data && props.data.gmb && props.data.gmb.views_search && props.data.gmb.views_search.last

    let linkdLikes = props.data && props.data.linkedin && props.data.linkedin.followers && props.data.linkedin.followers.now && props.data.linkedin.followers.now
    let linkdReach = props.data && props.data.linkedin && props.data.linkedin.likes && props.data.linkedin.likes.now
    let linkdReahLast = props.data && props.data.linkedin && props.data.linkedin.likes && props.data.linkedin.likes.last
    let linkdEngaged = props.data && props.data.linkedin && props.data.linkedin.comments && props.data.linkedin.comments.now
    let linkdEngagedLast = props.data && props.data.linkedin && props.data.linkedin.comments && props.data.linkedin.comments.last

    let instaLikes = props.data && props.data.instagram && props.data.instagram.followers && props.data.instagram.followers.now && props.data.instagram.followers.now
    let instaReach = props.data && props.data.instagram && props.data.instagram.likes && props.data.instagram.likes.now
    let instaReachLast = props.data && props.data.instagram && props.data.instagram.likes && props.data.instagram.likes.last
    let instaEngaged = props.data && props.data.instagram && props.data.instagram.comments && props.data.instagram.comments.now
    let instaEngagedLast = props.data && props.data.instagram && props.data.instagram.comments && props.data.instagram.comments.last

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <div className="card border-light shadow-sm border-0 h-100 card-loader">
                        <div className="card-body p-4 text-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="ratio ratio-1x1 mmw-60 rounded-pill bg-primary-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-info text-center">
                                        <svg className="icon fs-2" role="img"><use href="#icon_facebook"></use></svg>
                                    </div>
                                </div>
                                <span className="m-2">{fbLikes && fbLikes < 999 ? fbLikes : (Math.round(fbLikes / 100) / 10) > 0 ? ((Math.round(fbLikes / 100) / 10) + 'k') : <strong>0</strong>} </span>
                            </div>
                            <div className="">
                                <h6 className="text-info mb-1 mt-1">Page Like</h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="mb-1">Reach</p>
                                    {fbReach >= reachLast ?
                                        <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>
                                    }
                                    <span className="">{fbReach !== 0 ? fbReach : 0}</span>
                                </div>
                                <div className="">
                                    <p className="mb-1">Engaged</p>
                                    {fbEngaged >= engagedLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>
                                    }
                                    <span>{fbEngaged !== 0 ? fbEngaged : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card border-light shadow-sm border-0 h-100 card-loader">
                        <div className="card-body p-4 text-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="ratio ratio-1x1 mmw-60 rounded-pill bg-info-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-info text-center">
                                        <svg className="icon fs-2" role="img"><use href="#icon_twitter_x"></use></svg>
                                    </div>
                                </div>
                                <span className="m-2">{twitLikes && twitLikes < 999 ? twitLikes : (Math.round(twitLikes / 100) / 10) > 0 ? ((Math.round(twitLikes / 100) / 10) + 'k') : <strong>0</strong>}</span>
                            </div>
                            <div className="">
                                <h6 className="text-info mb-1 mt-1">Followers</h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="mb-1">Reach</p>
                                    {twitReach >= twitReachLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>
                                    }
                                    <span> {twitReach !== 0 ? twitReach : 0}</span>
                                </div>
                                <div className="">
                                    <p className="mb-1">Engaged</p>
                                    {twitEngaged >= twitEngagedLast ?
                                        <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg>
                                        : <i className="bi bi-arrow-down text-danger"></i>
                                    }
                                    <span> {twitEngaged !== 0 ? twitEngaged : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card border-light shadow-sm border-0 h-100 card-loader">
                        <div className="card-body p-4 text-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="ratio ratio-1x1 mmw-60 rounded-pill bg-danger-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-secondary text-center">
                                        <i className="bi bi-google"></i>
                                    </div>
                                </div>
                                <span className="m-2">{gmbLikes && gmbLikes < 999 ? gmbLikes : (Math.round(gmbLikes / 100) / 10) > 0 ? ((Math.round(gmbLikes / 100) / 10) + 'k') : <strong>0</strong>}</span>
                            </div>
                            <div className="">
                                <h6 className="text-info mb-1 mt-1">Followers</h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="mb-1">View Maps</p>
                                    {gmbReach >= gmbReachLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>}
                                    <span className="m-2">{gmbReach !== 0 ? gmbReach : 0}</span>
                                </div>
                                <div className="">
                                    <p className="mb-1">View Search</p>
                                    {gmbEngaged >= gmbEngagedLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>}
                                    <span className="m-2">{gmbEngaged !== 0 ? gmbEngaged : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card border-light shadow-sm border-0 h-100 card-loader">
                        <div className="card-body p-4 text-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="ratio ratio-1x1 mmw-60 rounded-pill bg-primary-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-primary text-center">
                                        <svg className="icon fs-2" role="img"><use href="#icon_linkedin"></use></svg>
                                    </div>
                                </div>
                                <span className="m-2">{linkdLikes && linkdLikes < 999 ? linkdLikes : (Math.round(linkdLikes / 100) / 10) > 0 ? ((Math.round(linkdLikes / 100) / 10) + 'k') : <strong>0</strong>}</span>
                            </div>
                            <div className="">
                                <h6 className="text-info mb-1 mt-1">Followers</h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="mb-1">Posts likes</p>
                                    {linkdReach >= linkdReahLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>}
                                    <span className="m-2">{linkdReach !== 0 ? linkdReach : 0}</span>
                                </div>
                                <div className="">
                                    <p className="mb-1">Comments</p>
                                    {linkdEngaged >= linkdEngagedLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>}
                                    <span className="m-2">{linkdEngaged !== 0 ? linkdEngaged : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card border-light shadow-sm border-0 h-100 card-loader">
                        <div className="card-body p-4 text-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="ratio ratio-1x1 mmw-60 rounded-pill bg-danger-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-danger text-center">
                                        <svg className="icon fs-2" role="img"><use href="#icon_instagram"></use></svg>
                                    </div>
                                </div>
                                <span className="m-2">{instaLikes && instaLikes < 999 ? instaLikes : (Math.round(instaLikes / 100) / 10) > 0 ? ((Math.round(instaLikes / 100) / 10) + 'k') : <strong>0</strong>}</span>
                            </div>
                            <div className="">
                                <h6 className="text-info mb-1 mt-1">Followers</h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="mb-1">Posts likes</p>
                                    {instaReach >= instaReachLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>}
                                    <span className="m-2">{instaReach !== 0 ? instaReach : 0}</span>
                                </div>
                                <div className="">
                                    <p className="mb-1">Comments</p>
                                    {instaEngaged >= instaEngagedLast ? <svg className="icon text-success" role="img"><use href="#icon_arrrowtop"></use></svg> : <i className="bi bi-arrow-down text-danger"></i>}
                                    <span className="m-2">{instaEngaged !== 0 ? instaEngaged : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SocailMediaContent
