import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

export default function ReviewPieChart(props) {
  useEffect(() => {
    Highcharts.chart(props.id === 'facebook' || props.id === 'listing' ? props.id : 'piechart', {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        legend: {
            layout: 'horizontal',
            maxHeight: 42,
            symbolWidth: 2,
            symbolHeight: 10,
            navigation: {
                activeColor: '#3E576F',
                animation: true,
                arrowSize: 12,
                inactiveColor: '#CCC',
                style: {
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '10px'
                }
            }
        },

          credits: {
             enabled: false
           },
           navigation:{
               buttonOptions: {
               enabled: false
             }
          },

         colors: [
           '#4662a0',
           '#aadb87',
           '#da495b',
           '#a87bc6',
           '#fde5a5',
           '#66ceed',
           '#d565ad',
           '#7ea45d',
           '#eace6b',
           '#66edc6',
           '#fdb7a5',
           '#fdb7b5',
           '#cdb7a5'
        ],
        
        series: [
            props && props.facebook ?
            {
                name: 'Recommendation',
                colorByPoint: true,
                data: props.facebook ? props.facebook: 0
            }
            :
            { 
                name: '',
                colorByPoint: true,
                data: props.data ? props.data: 0
            }
        ]
    });
    }, [props,props.data]);
    return (
        <div id={props.id === 'facebook' || props.id === 'listing' ? props.id : 'piechart'} style={{height: '270px', margin:'0px auto'}}></div>
    )
}
