import React, { useState, useEffect, useContext } from "react";
import { fetchData, GET_LOCATION_UNIQUE_URL, DASHBOARD_OVERVIEW, DASHBOARD_LISTING, GET_SOCIAL_DATA } from "../../components/Service";
import { Link } from "react-router-dom";
import ListingDeviceByWeekDay from "../../components/Dashboard/ListingDeviceByWeekDay";
import ListingProfileView from "../../components/Dashboard/ListingProfileView";
import LocationListingStatus from "../../components/Dashboard/LocationListingStatus";
import GoogleViewMapSearch from "../../components/Dashboard/GoogleViewMapSearch";
import GoogleCustomerAction from "../../components/Dashboard/GoogleCustomerAction";
import GooglePhoneCallHour from "../../components/Dashboard/GooglePhoneCallHour";
import ChooseLocation from "../../components/ChooseLocation";
import { Context } from '../../components/Context';
import { loadingData, toastNotify } from '../../components/Helper'
import { useLoadScript } from "@react-google-maps/api";
import LocationMap from "../../components/Dashboard/LocationMap";

function Listing() {
    const [context] = useContext(Context)
    let dashboardTitle = context && context.auth && context.auth.user_group_id === 1 ? 'Admin Dashboard' : 'User Dashboard'
    window.document.title = dashboardTitle;

    const [location, setLocation] = useState();
    const [records, setRecords] = useState(0);
    const [incomingData, setIncomingData] = useState(0)
    const [dashboardListing] = useState(DASHBOARD_LISTING)
    const [formfield, setFormfield] = useState({
        type: 'thirtDay',
        startDate: '',
        endDate: '',
    })

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCnsDaRuiQrNBJn9txd1VHFurxqdW-t1lk&callback=initMap&libraries=places" // Add your API key
    });

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records)
            }
        }, false, signal, false);

        return () => {
            controller.abort()
        }
    }, [])

    var selected_location = localStorage.getItem('selected_location_dashboard') ? JSON.parse(localStorage.getItem('selected_location_dashboard')) : [] //From Dashboard Page
    var default_location_selected;
    if (selected_location.length > 0) {
        default_location_selected = selected_location
    } else {
        default_location_selected = location && location[0] && location[0].value && location[0].value !== '' ? location[0] : false
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (location) {
            let selected_location = localStorage.getItem('selected_location_dashboard') ? JSON.parse(localStorage.getItem('selected_location_dashboard')) : []
            var default_location = [];
            selected_location.forEach(function (item, index) {
                default_location.push(item.value)
            })
            var defaultLocation
            if (default_location.length > 0) {
                defaultLocation = default_location.join(',')
            } else {
                defaultLocation = location && location[0] && location[0].value && location[0].value !== '' ? location[0].value : false
            }
            if (defaultLocation) {
                document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
                fetchData(`${DASHBOARD_LISTING}?location=${defaultLocation}&type=thirtDay`, 'GET', '', true, false, (res) => {
                    if (res.records) {
                        setRecords(res.records)
                    } else {
                        setRecords('0')
                    }
                }, false, signal, false);

                fetchData(`${DASHBOARD_OVERVIEW}?location=${defaultLocation}&type=thirtDay`, 'GET', '', true, false, (res) => {

                    if (res.records) {
                        setIncomingData(res.records)
                    } else {
                        setIncomingData('0')
                    }
                }, false, signal, false);
                document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                loadingData()
            } else {
                document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                loadingData()
            }
        }

        return () => {
            controller.abort()
        }
    }, [location])

    const getSocialData = (e) => {
        document.querySelector(".listings-locstasnacimg").classList.add('active')
        var element = e.target;

        if (element.dataset.status === 'false') {
            element.dataset.status = true
        } else if (element.dataset.status === 'true') {
            element.dataset.status = false
        }

        var get_data = document.querySelectorAll(".listings-locstasnacimg span[data-status=true]")
        var social_word = []
        get_data.forEach(ele => social_word.push(ele.dataset.select_type))

        var formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('location[]')

        if (location_selected.length <= 10) {
            if (location_selected.length > 0 && location_selected[0] !== '') {
                var formdata_new = { location: location_selected, social_word: social_word }
                if (social_word.length > 0) {
                    fetchData(GET_SOCIAL_DATA, 'POST', formdata_new, true, false, (res) => {
                        if (res.records) {
                            setRecords(prevState => ({ ...prevState, chart: res.records.chart, total_listing: res.records.total_listing, total_live: res.records.total_live, total_opted: res.records.total_opted, total_processing: res.records.total_processing, total_unavailable: res.records.total_unavailable }))
                        } else {
                            setRecords('0')
                        }
                    });
                } else {
                    var current_element = e.currentTarget
                    current_element.dataset.status = true
                }
            } else {
                toastNotify({ error: 'Please select atleast one location' })
                document.querySelector(".listings-locstasnacimg").classList.remove('active')
                var getData = document.querySelectorAll(".listings-locstasnacimg a[data-status=true]")
                getData.forEach(ele => ele.dataset.status = false)
            }
        } else {
            toastNotify({ error: 'You can\'t select more than 10 location at a time.' })
        }
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                            <h4 className="page-heading">{dashboardTitle}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto dashboard-link">
                            {context && context.auth && context.auth.user_group_id === 1  &&  context.auth.super_admin === "yes" &&
                                <>
                                    <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Overview</Link>
                                    <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex main-link active"> Listings </Link>
                                    <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Social insights</Link>
                                    <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3" >Reputation</Link>
                                </>
                            }
                            {context && context.auth && (context.auth.user_group_id === 3 || (context.auth.user_group_id === 1 && context.auth.super_admin === "no")) && context.auth.permission ? context.auth.permission.map((ele, num) => {
                                return (<>
                                    {ele.permission === 'Common Dashboard' && ele.view === 'yes' ? <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Overview</Link> : ''}
                                    {ele.permission === 'Listing Dashboard' && ele.view === 'yes' ? <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex main-link active"> Listings </Link> : ''}
                                    {ele.permission === 'Social Media Dashboard' && ele.view === 'yes' ? <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Social insights</Link> : ''}
                                    {ele.permission === 'Reputation Management Reviews' && ele.view === 'yes' ? <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Reputation</Link> : ''}
                                </>)
                            }) : ''
                            }
                        </div>
                    </div>
                    {default_location_selected && <ChooseLocation
                        location={location}
                        setFormfield={setFormfield}
                        formfield={formfield}
                        setIncomingData={setIncomingData}
                        setRecords={setRecords}
                        dashboardListing={dashboardListing}
                        dashboardOverview="dashboardOverview"
                        socialSection="socialSection"
                        default_location_selected={default_location_selected}
                    />
                    }
                    <div id="generatePDF">
                        <div className="row g-4">
                            {isLoaded ? <LocationMap data={incomingData && incomingData.location_map} /> : null}
                            <ListingDeviceByWeekDay data={incomingData && incomingData.usageByWeekday ? incomingData.usageByWeekday : 0} />
                            <ListingProfileView data={incomingData && incomingData.listingValue ? incomingData.listingValue : 0} />
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col-sm-12">
                                <div className="tab-charts card shadow-sm card-loader border-0">
                                    <h6 className="card-header fw-bold">Location Listings Status</h6>
                                    <div className="card-body">
                                        <div className="row mt-2">
                                            <div className="col-md-5 col-lg-5 col-sm-6 shadow-sm">
                                                <LocationListingStatus data={records && records.chart ? records.chart : 0} />
                                            </div>
                                            <div className="col-md-7 col-lg-7 col-sm-6">
                                                <div className="row mt-4">
                                                    <div className="col colmobile-33 bodter-toponlythis">
                                                        <div className="total-locationstatusbor total-locstatus-content shadow-sm">
                                                            <h2 id="all_live">{records && records.total_listing ? records.total_listing : 0}</h2>
                                                            <div className="loader-transparent" id="loader10"><i className="fa fa-spinner fa-spin grid_loader text-muted" ></i></div>
                                                            <p>All location listings</p>
                                                        </div>
                                                    </div>
                                                    <div className="col colmobile-33 ">
                                                        <div className="total-locstatus-content shadow-sm">
                                                            <h2 id="total_listinglive">{records && records.total_live ? records.total_live : 0}</h2>
                                                            <p>Live Listings</p>
                                                        </div>
                                                    </div>

                                                    <div className="col colmobile-33">
                                                        <div className="total-locstatus-content shadow-sm">
                                                            <h2 id="process">{records && records.total_processing ? records.total_processing : 0}</h2>
                                                            <div className="loader-transparent" id="loader7"  ><i className="fa fa-spinner fa-spin grid_loader text-muted"  ></i></div>
                                                            <p>Processing</p>
                                                        </div>
                                                    </div>

                                                    <div className="col colmobile-33">
                                                        <div className="total-locstatus-content shadow-sm">
                                                            <h2 id="unavailable">{records && records.total_unavailable ? records.total_unavailable : 0}</h2>
                                                            <div className="loader-transparent" id="loader8" ><i className="fa fa-spinner fa-spin grid_loader text-muted"  ></i></div>
                                                            <p>Unavailable</p>
                                                        </div>
                                                    </div>

                                                    <div className="col colmobile-33">
                                                        <div className="total-locstatus-content shadow-sm">
                                                            <h2 id="opt">{records && records.total_opted ? records.total_opted : 0}</h2>
                                                            <div className="loader-transparent" id="loader9" style={{ display: 'none' }}><i className="fa fa-spinner fa-spin grid_loader text-muted" style={{ fontSize: '33px' }}></i></div>
                                                            <p>Opted Out</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <input type="hidden" className="socialvalue" />
                                                        <ul className="listings-locstasnacimg">
                                                            <li><span className="social" data-select_type="GOOGLEMYBUSINESS" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/google_my_business.svg" alt="Google" /><br />Google My ..</span></li>
                                                            <li><span className="social" data-select_type="ALEXA" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/site-icon-359.svg" title="Image" alt="Amazon" /><br />Amazon Ale</span></li>
                                                            <li><span className="social" data-select_type="APPLE" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/apple.svg" alt="Apple" /><br />Apple</span></li>
                                                            <li><span className="social" data-select_type="BING" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/bing.svg" alt="BING" /><br />Bing</span></li>
                                                            <li><span className="social" data-select_type="FACEBOOK" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/facebook.svg" alt="FACEBOOK" /><br />Facebook</span></li>
                                                            <li><span className="social" data-select_type="YELP" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/yelp_symbol.svg" alt="YELP" /><br />Yelp</span></li>
                                                            <li><span className="social" data-select_type="SNAPCHAT" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/site-icon-267.svg" alt="SNAPCHAT" /><br />Snap Chat</span></li>
                                                            <li><span className="social" data-select_type="FOURSQUARE" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/site-icon-16.svg" alt="FOURSQUARE" /><br />Focussquare</span></li>
                                                            <li><span className="social" data-select_type="YAHOO" data-status="false" onClick={(e) => getSocialData(e)}><img className="pe-none" src="images/site-icon-19.svg" alt="YAHOO" /><br />Yahoo</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mt-2">
                            <div className="col-sm-12">
                                <div className="tab-charts card shadow-sm card-loader border-0">
                                    <h6 className="card-header fw-bold">Location Listings Engagement</h6>
                                    <div className="card-body">
                                        <div className="row mt-2 pb-2">
                                            <div className="col-sm-6 col-md-6 col-lg-3">
                                                <div className="profile-views text-center three-listingxt  shadow-sm">
                                                    <h3 className="engage-data" id="profile_view">{records && records.profile_sum ? records.profile_sum : 0}</h3>
                                                    <div className="view-text fw-bold"><i className="bi bi-eye"></i> Profile Views</div>
                                                    <div className="slogan-text">(Network data since launch)</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-lg-3">
                                                <div className="phone-calls text-center three-listingxt  shadow-sm">
                                                    <h3 className="engage-data" id="phone_calls">{records && records.phone_sum ? records.phone_sum : 0}</h3>
                                                    <div className="view-text fw-bold"><i className="bi bi-telephone"></i> Phone Calls</div>
                                                    <div className="slogan-text">(Google and Yelp data since launch)</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-lg-3">
                                                <div className="driving-directions text-center three-listingxt text-info  shadow-sm">
                                                    <h3 className="engage-data" id="driving_dir">{records && records.driving_sum ? records.driving_sum : 0}</h3>
                                                    <div className="view-text fw-bold"><i className="bi bi-truck"></i> Driving Directions</div>
                                                    <div className="slogan-text">(Google and Yelp data since launch)</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-lg-3">
                                                <div className="click-website text-center three-listingxt  shadow-sm">
                                                    <h3 className="engage-data" id="click_web">{records && records.website_sum ? records.website_sum : 0}</h3>
                                                    <div className="view-text fw-bold"><i className="bi bi-pc-display-horizontal"></i> Click to Website</div>
                                                    <div className="slogan-text">(Google and Yelp data since launch)</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <GoogleViewMapSearch data={incomingData && incomingData.viewSearchValue ? incomingData.viewSearchValue : 0} />
                            <GoogleCustomerAction data={incomingData && incomingData.googleCustomer ? incomingData.googleCustomer : 0} />
                            <GooglePhoneCallHour data={incomingData && incomingData.phoneCalls ? incomingData.phoneCalls : 0} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Listing
