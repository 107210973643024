import $ from 'jquery';

export const jsReload = () => {
    document.querySelectorAll(".split_time").forEach(function (el) {
        el.addEventListener('change', (e) => {
            el.parentElement.querySelectorAll('.remove_split_time').forEach(function (e) {
                e.remove()
            })
            if (e.target.value === 'split') {
                el.parentElement.insertAdjacentHTML('beforeend',
                    `<span class="input-group-text remove_split_time">and</span>
                <input type="text" class="form-control col-4 remove_split_time" name="business_hour_split_from[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" />
                <span class="input-group-text remove_split_time">to</span>
                <input type="text" class="form-control col-4 remove_split_time" name="business_hour_split_to[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" />`);
            } else {
                el.parentElement.querySelectorAll('.remove_split_time').forEach(function (e) {
                    e.remove()
                })
            }
            if (e.target.value === 'split' || e.target.value === 'open') {
                el.parentElement.querySelectorAll('input').forEach(function (e) {
                    e.removeAttribute('readonly')
                })
            } else {
                el.parentElement.querySelectorAll('input').forEach(function (e) {
                    e.setAttribute('readonly', true)
                })
            }

        })
    });
}


export const addField = (id) => {
    if (id === 'keyword') {
        document.getElementById(id).insertAdjacentHTML('beforebegin',
            `<div class="input-group mb-2"><input type="text" class="form-control" placehlder="Enter Keyword" name="keywords[]"><span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span></div>`);
    } else if (id === 'competitor') {
        document.getElementById(id).insertAdjacentHTML('beforebegin',
            `<div class="input-group mb-2"><input type="text" class="form-control" placeholder="Name" name="competitors[]"><input type="url" class="form-control" placeholder="Website URL" name="competitor_url[]"><span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span></div>`);
    } else if (id === 'addemail') {
        document.getElementById(id).insertAdjacentHTML('beforebegin',
            `<div class="input-group mb-2"><input type="text" class="form-control" name="contact_email[]"><span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span></div>`);
    } else if (id === 'holiday_hours') {
        document.getElementById(id).lastElementChild.insertAdjacentHTML('beforebegin',
            `<div class="input-group mb-3">
                <input type="select" class="form-control select_date" placeholder="Select Date" required name="holiday_date[]" />
                <select class="form-select split_time" name="holiday_type[]">
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="split">Split</option>
                    <option value="24">24 Hours</option>
                    <option value="close">Close</option>
                    <option value="regular_hours">Regular Hours</option>
                </select>
                <input type="text" class="form-control" name="holiday_hour_from[]" readonly placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" />
                <span class="input-group-text">to</span>
                <input type="text" class="form-control col-4" name="holiday_hour_to[]" readonly placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" />
                <span class="input-group-text remove"><svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_trash" /></svg></span>
            </div>`);
    }

    document.querySelectorAll(".remove").forEach(function (el) {
        el.addEventListener('click', (e) => {
            el.parentElement.remove();
        })
    });

    document.querySelectorAll("#holiday_hours .split_time").forEach(function (el) {
        el.addEventListener('change', (e) => {
            el.parentElement.querySelectorAll('.remove_split_time').forEach(function (e) {
                e.remove()
            })
            if (e.target.value === 'split') {
                el.parentElement.lastElementChild.insertAdjacentHTML('beforebegin',
                    `<span class="input-group-text remove_split_time">and</span>
                <input type="text" class="form-control col-4 remove_split_time" name="holiday_split_hour_from[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" />
                <span class="input-group-text remove_split_time">to</span>
                <input type="text" class="form-control col-4 remove_split_time" name="holiday_split_hour_to[]" placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" />`)
            } else {
                el.parentElement.querySelectorAll('.remove_split_time').forEach(function (e) {
                    e.remove()
                })
            }

            if (e.target.value === 'split' || e.target.value === 'open') {
                el.parentElement.querySelectorAll('input').forEach(function (e) {
                    e.removeAttribute('readonly')
                })
            } else {
                el.parentElement.querySelectorAll('input').forEach(function (e) {
                    e.setAttribute('readonly', true)
                })
            }
        })
    });

    document.querySelectorAll('.select_date').forEach(function (e) {
        $(e).datepicker({
            minDate: new Date(),
            dateFormat: 'dd-mm-yy',
            changeMonth: true,
            changeYear: true
        })
    })

}