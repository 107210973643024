import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Context } from './Context';
import { fetchData, GET_AUTH_USER_INFO, LOGOUT } from "../components/Service";

var ct = 0;
function AsideBar() {
    const [context, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const adminToken = localStorage.getItem('amazio-admin-token')

    const location = useLocation();
    const navigate = useNavigate();

    let commonDashboard = 0;
    let listingDashboard = 0;
    let socialDashboard = 0;
    let reputationDashboard = 0;

    let reviewRespond = 0;
    let sentimentAnalysis = 0;
    let competitiveAnalysis = 0;


    // Social Media Manager

    let socialManager = 0;
    let reports = 0;
    let postingApproval = 0;
    let onboarding = 0;

    context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
        return ((ele.permission === 'Social Media Manager' && ele.view === 'yes') ?
            (++socialManager) : (ele.permission === 'Reports' && ele.view === 'yes') ? (++reports) : (ele.permission === 'Posting Approval' && ele.view === 'yes') ? (++postingApproval) : (ele.permission === 'Onboarding' && ele.view === 'yes') ? (++onboarding) : '')
    })

    // Support Menu

    let requestToCall = 0;
    let rateUs = 0;
    let manageFaq = 0;
    let demoRequest = 0;
    let supports = 0;
    let websiteSupport = 0;


    // Accounting Menu

    let book_keeping = 0;
    let promo_code = 0;
    let manage_plan_price = 0;
    let payment_invoices = 0;

    context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
        return ((ele.permission === 'Payment Invoice' && ele.view === 'yes') ?
            (++payment_invoices) : (ele.permission === 'Manage Plan & Prices' && ele.view === 'yes') ? (++manage_plan_price) : (ele.permission === 'Book Keeping' && ele.view === 'yes') ? (++book_keeping) : (ele.permission === 'Promo Code' && ele.view === 'yes') ? (++promo_code) : '')
    })


    context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
        return ((ele.permission === 'Common Dashboard' && ele.view === 'yes') ?
            (++commonDashboard) : (ele.permission === 'Listing Dashboard' && ele.view === 'yes') ? (++listingDashboard) : (ele.permission === 'Social Media Dashboard' && ele.view === 'yes') ? (++socialDashboard) : (ele.permission === 'Reputation Management Reviews' && ele.view === 'yes') ? (++reputationDashboard) : '')
    })

    context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
        return ((ele.permission === 'Supports' && ele.view === 'yes') ?
            (++supports) : (ele.permission === 'Demo Requests' && ele.view === 'yes') ? (++demoRequest) : (ele.permission === 'Request to call' && ele.view === 'yes') ? (++requestToCall) : (ele.permission === 'Rate Us' && ele.view === 'yes') ? (++rateUs) : (ele.permission === 'Manage FAQs' && ele.view === 'yes') ? (++manageFaq) : (ele.permission === 'Website Admin' && ele.view === 'yes') ? (++websiteSupport) : '')
    })

    context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
        return ((ele.permission === 'Review Respond' && ele.view === 'yes') ?
            (++reviewRespond) : (ele.permission === 'Sentiment Analysis' && ele.view === 'yes') ? (++sentimentAnalysis) : (ele.permission === 'Competitive Analysis' && ele.view === 'yes') ? (++competitiveAnalysis) : '')
    })

    useEffect(() => { }, [context, location]);

    const menuToggle = () => {
        document.body.classList.toggle('menu-active');
    }

    const handleLogout = (type) => {
        let formData = new FormData(document.getElementById('userRatingForm'))
        formData.append('type', type)
        fetchData(LOGOUT, 'POST', formData, true, true, (res) => {
            if (res.success) {
                document.querySelector('#logoutModal [data-bs-dismiss="modal"]').click()
                localStorage.clear()
                setContext('')
                navigate(`/login`);
            }
        });
    }

    const [str, setStr] = useState(0);
    const starRating = (e) => {
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left;
        if (x <= 25) { setStr(1) }
        if (x >= 26 && x <= 51) { setStr(2) }
        if (x >= 52 && x <= 77) { setStr(3) }
        if (x >= 78 && x <= 103) { setStr(4) }
        if (x >= 104 && x <= 130) { setStr(5) }
    }

    const accountSetting = id => {
        ct = setInterval(() => {
            document.getElementById(id).click();
            clearTimeout(ct);
        }, 100)
    }

    const goToAdmin = () => {
        if (adminToken) {
            setTinyloader(false);
            localStorage.removeItem('amazio-admin-token')
            localStorage.setItem('amazio-token', adminToken)
            fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                if (res.records && context) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))

                    navigate('/admin/choose-location')
                }
            });
        }
    }
    
    return (
        <>
            <aside className="admin-aside">
                <div className="admin-logobox">
                    <Link to="/" className="admin-brandbox" title="Amazio">
                        <img src={'images/logo.svg'} alt="Amazio-Logo" width="170" height="31" />
                    </Link>

                    <div className="admin-dropdown dropdown">
                        <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-offset="110,0">
                            <img src="images/user.jpg" alt="User Avatar" width="30" height="30" />
                            <span>{context && context.auth && context.auth.first_name} {context && context.auth && context.auth.last_name}</span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-lg-end border-0 shadow rounded px-2">
                            {context && context.auth && (context.auth.first_name || context.auth.last_name) && <li>
                                <span title="Name" className="dropdown-item rounded py-2 pe-none">
                                    <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                        <use href="#icon_user" />
                                    </svg>
                                    {context && context.auth && <span>{context.auth.first_name} {context.auth.last_name}</span>}
                                </span>
                            </li>}
                            {context && context.auth && context.auth.email && <li>
                                <a href={`mailto:${context.auth.email}`} title="Email" className="dropdown-item rounded py-2">
                                    <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                        <use href="#icon_envalop" />
                                    </svg>
                                    <span>{context.auth.email}</span>
                                </a>
                            </li>}
                            {context && context.auth && context.auth.phone && <li>
                                <a href={`tel:${context && context.auth && context.auth.phone}`} title="Call" className="dropdown-item rounded py-2">
                                    <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                        <use href="#icon_whatsapp" />
                                    </svg>
                                    <span>{context.auth.phone}</span>
                                </a>
                            </li>}
                            <li>
                                {context && context.auth && (context.auth.user_group_id === 3 || (context.auth.user_group_id === 1 && context.auth.super_admin === 'no')) && context.auth.permission ?
                                    context.auth.permission.map((ele, num) => {
                                        return (ele.permission === "Profile" && ele.view === "yes" &&
                                            <Link to="account-setting" title="Edit" onClick={() => accountSetting('pills-home-tab')} key={num} className="dropdown-item rounded py-2">
                                                <svg className="icon tscale-1p1 opacity-75 me-3" role="img">
                                                    <use href="#icon_edit" />
                                                </svg>
                                                Edit
                                            </Link>
                                        )
                                    })
                                    :
                                    <Link to="account-setting" title="Edit" onClick={() => accountSetting('pills-home-tab')} className="dropdown-item rounded py-2">
                                        <svg className="icon tscale-1p1 opacity-75 me-3" role="img">
                                            <use href="#icon_edit" />
                                        </svg>
                                        Edit
                                    </Link>
                                }
                            </li>
                            <li>
                                {context && context.auth && (context.auth.user_group_id === 3) && context.auth.permission ? context.auth.permission.map((ele, num) => {
                                    return (ele.permission === "Profile" && ele.edit === "yes" &&
                                        <Link to="account-setting" onClick={() => accountSetting('pills-profile-tab')} className="dropdown-item rounded py-2" key={num} title="Change Password">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_lock" />
                                            </svg>
                                            Change Password
                                        </Link>
                                    )
                                })
                                    :
                                    <Link to="account-setting" onClick={() => accountSetting('pills-profile-tab')} className="dropdown-item rounded py-2" title="Change Password">
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                            <use href="#icon_lock" />
                                        </svg>
                                        Change Password
                                    </Link>
                                }
                            </li>
                            <li>
                                <hr className="dropdown-divider text-white opacity-50" />
                            </li>
                            {context && context.auth &&
                                <li>
                                    {context.auth.user_group_id === 3 && context.auth.rating.length === 0 ?
                                        <button type="button" className="dropdown-item rounded py-2" title="Logout" data-bs-toggle="modal" data-bs-target="#logoutModal">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_logout" />
                                            </svg>
                                            Logout
                                        </button>
                                        :
                                        <button type="button" className="dropdown-item rounded py-2" onClick={handleLogout} title="Logout">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_logout" />
                                            </svg>
                                            Logout
                                        </button>
                                    }
                                </li>
                            }
                        </ul>
                    </div>

                    {adminToken &&
                        <button type="button" className="btn btn-dark mx-3 mt-3" onClick={goToAdmin} disabled={tinyloader} title="Go To Admin">
                            {tinyloader ?
                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <span>Go To Admin</span>
                            }
                        </button>
                    }
                    <button className="aside-toggle" onClick={() => menuToggle()}>
                        <svg className="icon"><use href="#icon_menu" /></svg>
                    </button>
                </div>
                <div className="admin-navbox" id="navtoggle">
                    {context && context.auth && <>
                        {context.auth.user_group_id === 1 && context.auth.super_admin === 'yes' && <>
                            <div className="accordion-item">
                                <NavLink to="admin-dashboard" className={`main-link ${['/admin/admin-dashboard', '/admin/dashboard-listing', '/admin/soci-dashboard', '/admin/reputation-dashboard'].includes(location.pathname) ? 'active' : ''}`} title="Dashboard">
                                    <svg className="icon" role="img">
                                        <use href="#icon_dashboard" />
                                    </svg>
                                    Dashboard
                                </NavLink>
                            </div>
                            {context.auth.super_admin === 'yes' &&
                                <div className="accordion-item">
                                    <NavLink to="manage-admins-list" className="main-link" title="Manage Super Admins">
                                        <svg className="icon tscale-1p1" role="img">
                                            <use href="#icon_users" />
                                        </svg>
                                        Manage Super Admins
                                    </NavLink>
                                </div>
                            }

                            <div className="accordion-item">
                                <NavLink to="manage-users-list" className="main-link" title="Manage Users">
                                    <svg className="icon tscale-1p1" role="img">
                                        <use href="#icon_users" />
                                    </svg>
                                    Manage Users
                                </NavLink>
                            </div>

                            <div className="accordion-item">
                                <NavLink to="manage-business-list" className={`main-link ${location.pathname.match('/view-location/*') ? 'active' : ''}`} title="Manage Business Listing">
                                    <svg className="icon tscale-1p1" role="img">
                                        <use href="#icon_star" />
                                    </svg>
                                    Manage Business Listing
                                </NavLink>
                            </div>

                            <div className="accordion-item">
                                <div className={`main-link ${!['/admin/manage', '/admin/reports', '/admin/posting-approval', '/admin/add-unique-url'].includes(location.pathname) ? 'collapsed' : ''}`} data-bs-toggle="collapse" role="button" data-bs-target="#company_more" aria-expanded={['/admin/manage', '/admin/reports', '/admin/posting-approval', '/admin/add-unique-url'].includes(location.pathname) ? true : false} title="Social Media Manager">
                                    <svg className="icon" role="img"><use href="#icon_social" /></svg>
                                    Social Media Manager
                                </div>
                                <div id="company_more" className={`accordion-collapse collapse ${['/admin/manage', '/admin/reports', '/admin/posting-approval', '/admin/add-unique-url'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    <NavLink to="manage" className="sub-link" title='Manage'>Manage</NavLink>
                                    <NavLink to="reports" className="sub-link" title='Reports'>Reports</NavLink>
                                    <NavLink to="posting-approval" className="sub-link" title='Posting Approval'>Posting Approval</NavLink>
                                    <NavLink to="add-unique-url" className="sub-link" title='Onboarding'>Onboarding</NavLink>
                                </div>
                                <div className={`main-link ${!['/admin/review-response', '/admin/competitive-analysis', '/admin/sentiment-analysis'].includes(location.pathname) ? 'collapsed' : ''}`} role="button" data-bs-toggle="collapse" data-bs-target="#subscription_more" aria-expanded={['/admin/review-response', '/admin/competitive-analysis', '/admin/sentiment-analysis'].includes(location.pathname) ? true : false} title="Reputation Management">
                                    <svg className="icon" role="img" aria-label="more"><use href="#icon_loyalty" /></svg>
                                    Reputation Management
                                </div>
                                <div id="subscription_more" className={`accordion-collapse collapse ${['/admin/review-response', '/admin/competitive-analysis', '/admin/sentiment-analysis'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    <NavLink to="review-response" className="sub-link" title='Review & Response'>Review & Response</NavLink>
                                    <NavLink to="competitive-analysis" className="sub-link" title='Competitive Analysis'>Competitive Analysis</NavLink>
                                    <NavLink to="sentiment-analysis" className="sub-link" title='Sentiment Analysis'>Sentiment Analysis</NavLink>

                                </div>
                                <NavLink to="sale-agent-list" className="main-link" title='Sales Agent'>
                                    <svg className="icon tscale-1p1" role="img" aria-label="more">
                                        <use href="#icon_user" />
                                    </svg>
                                    Sales Agent
                                </NavLink>
                                <a href={`https://blog.amazio.com`} target="_blank" rel="noopener noreferrer" className="main-link" title='Blog'>
                                    <svg className="icon tscale-1p1" role="img" aria-label="more">
                                        <use href="#icon_category" /></svg>
                                    Blog
                                </a>
                                <NavLink to="manage-subscribers-list" className="main-link" title='Manage Subscribers'>
                                    <svg className="icon tscale-1p1" role="img" aria-label="more">
                                        <use href="#icon_suitcase" />
                                    </svg>
                                    Manage Subscribers
                                </NavLink>
                            </div>

                            <div className="accordion-item">
                                <div className={`main-link ${['/admin/pricing-list', '/admin/payment-invoice-list', '/admin/book-keeping', '/admin/promo-code'].includes(location.pathname) ? 'aside_menu_selected' : 'collapsed'}`} data-bs-toggle="collapse" role="button" data-bs-target="#ads_more" aria-expanded={['/admin/pricing-list', '/admin/payment-invoice-list', '/admin/book-keeping', '/admin/promo-code'].includes(location.pathname) ? true : false} title="Accounting">
                                    <svg className="icon" role="img"><use href="#icon_ads" /></svg>
                                    Accounting
                                </div>
                                <div id="ads_more" className={`accordion-collapse collapse ${['/admin/pricing-list', '/admin/payment-invoice-list', '/admin/book-keeping', '/admin/promo-code'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    <NavLink to="pricing-list" className="sub-link" title='Manage Plan and Prices'>Manage Plan and Prices</NavLink>
                                    <NavLink to="payment-invoice-list" className="sub-link" title='Payments & Invoices'>Payments & Invoices</NavLink>
                                    <NavLink to="book-keeping" className="sub-link" title='Book Keeping'>Book Keeping</NavLink>
                                    <NavLink to="promo-code" className="sub-link" title='Promo Code'>Promo Code</NavLink>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <a className={`main-link ${!['/admin/view-tickets-list', '/admin/faqs-list', '/admin/request-to-call', '/admin/request-demo-list', '/admin/rate-us-list', '/admin/view-website-support', '/admin/add-faqs'].includes(location.pathname) ? 'collapsed' : 'aside_menu_selected'}`} data-bs-toggle="collapse" href="/#category_more" aria-expanded={['/admin/view-tickets-list', '/admin/faqs-list', '/admin/request-to-call', '/admin/request-demo-list', '/admin/rate-us-list', '/admin/view-website-support', '/admin/add-faqs'].includes(location.pathname) ? true : false} title="Support">
                                    <svg className="icon icon-h18" role="img" aria-label="more">
                                        <use href="#icon_setting" />
                                    </svg>
                                    Support
                                </a>
                                <div id="category_more" className={`accordion-collapse collapse ${['/admin/view-tickets-list', '/admin/faqs-list', '/admin/request-to-call', '/admin/request-demo-list', '/admin/rate-us-list', '/admin/view-website-support', '/admin/add-faqs'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    <NavLink to="view-tickets-list" className="sub-link" title='Support'>Support</NavLink>
                                    <NavLink to="faqs-list" className={`sub-link ${['/admin/add-faqs', '/admin/faqs-list'].includes(location.pathname) ? 'active' : ''}`} title="Manage FAQs">Manage FAQs</NavLink>
                                    <NavLink to="request-to-call" className="sub-link" title='Request To Call'>Request To Call</NavLink>
                                    <NavLink to="request-demo-list" className="sub-link" title='Demo Requests'>Demo Requests</NavLink>
                                    <NavLink to="rate-us-list" className="sub-link" title='Rate Us'>Rate Us</NavLink>
                                    <NavLink to="view-website-support" className="sub-link" title='Website Support'>Website Support</NavLink>
                                </div>
                            </div>

                            {context.auth.user_group_id === 1 && context.auth.super_admin === 'yes' && <>
                                <div className="accordion-item">
                                    <NavLink to="onboarding-details" className="main-link" title='Onboarding Details'>
                                        <svg className="icon tscale-1p1" role="img" aria-label="more">
                                            <use href="#icon_suitcase" />
                                        </svg>
                                        Onboarding Details
                                    </NavLink>
                                </div>
                            </>
                            }

                            <div className="accordion-item">
                                <NavLink to="sso-mapping" className="main-link" title="SSO Mapping">
                                    <svg className="icon tscale-1p1" role="img">
                                        <use href="#icon_globe" />
                                    </svg>
                                    SSO Mapping
                                </NavLink>
                            </div>

                            <div className="accordion-item">
                                <NavLink to="user-activity" className="main-link" title="User Activity">
                                    <svg className="icon tscale-1p1" role="img">
                                        <use href="#icon_bell" />
                                    </svg>
                                    User Activity
                                </NavLink>
                            </div>

                            {context.auth.super_admin === 'yes' &&
                                <>
                                    <div className="accordion-item">
                                        <NavLink to="setting-panel" className="main-link" title="Setting Panel">
                                            <svg className="icon icon-h18" role="img" aria-label="more">
                                                <use href="#icon_setting" />
                                            </svg>
                                            Setting Panel
                                        </NavLink>
                                    </div>

                                    <div className="accordion-item">
                                        <a className={`main-link ${!['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? 'collapsed' : 'aside_menu_selected'}`} data-bs-toggle="collapse" href="/#imprints_history" aria-expanded={['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? true : false} title="Imprints History">
                                            <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                <use href="#icon_menu" />
                                            </svg>
                                            Imprints History
                                        </a>
                                        <div id="imprints_history" className={`accordion-collapse collapse ${['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                            <NavLink to="ced-custom-portal" className="sub-link" title='Ced Custom Portal'>Ced Custom Portal</NavLink>
                                            <NavLink to="arclight-list" className="sub-link" title='Arclight'>Arclight</NavLink>
                                            <NavLink to="business-card-list" className="sub-link" title='Business Card'>Business Card</NavLink>
                                            <NavLink to="vmi-flyer-list" className="sub-link" title='Vmi Flyer'>Vmi Flyer</NavLink>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <NavLink to="active-locations" className="main-link" title="Active Location">
                                            <svg className="icon icon-h18" role="img" aria-label="more">
                                                <use href="#icon_setting" />
                                            </svg>
                                            GMB Active Location
                                        </NavLink>
                                    </div>
                                </>
                            }
                        </>}

                        {/* Admin Section */}

                        {context.auth.user_group_id === 1 && context.auth.super_admin === 'no' &&
                            <>

                                {(commonDashboard !== 0 || listingDashboard !== 0 || socialDashboard !== 0 || reputationDashboard !== 0) &&
                                    <div className="accordion-item">
                                        <NavLink to={`${commonDashboard !== 0 ? 'admin-dashboard' : listingDashboard !== 0 ? 'dashboard-listing' : socialDashboard !== 0 ? 'soci-dashboard' : reputationDashboard !== 0 ? 'reputation-dashboard' : ''}`} className={`main-link ${['/admin/admin-dashboard', '/admin/dashboard-listing', '/admin/soci-dashboard', '/admin/reputation-dashboard'].includes(location.pathname) ? 'active' : ''}`} title="Dashboard">
                                            <svg className="icon" role="img">
                                                <use href="#icon_dashboard" />
                                            </svg>
                                            Dashboard
                                        </NavLink>
                                    </div>
                                }

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Manage Users" && ele.view === "yes" &&
                                            <NavLink to="manage-users-list" className={`main-link ${location.pathname.match('/manage-users-list/*') ? 'active' : ''}`} title="Manage Users">
                                                <svg className="icon tscale-1p1" role="img">
                                                    <use href="#icon_users" />
                                                </svg>
                                                Manage Users
                                            </NavLink>}
                                    </div>)
                                })}

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Manage Location" && ele.view === "yes" &&
                                            <NavLink to="manage-business-list" className={`main-link ${location.pathname.match('/manage-business-list/*') ? 'active' : ''}`} title="Manage Business Listing">
                                                <svg className="icon tscale-1p1" role="img">
                                                    <use href="#icon_star" />
                                                </svg>
                                                Manage Business Listing
                                            </NavLink>}
                                    </div>)
                                })}

                                {(socialManager !== 0 || reports !== 0 || postingApproval !== 0 || onboarding !== 0) &&
                                    <>
                                        <div className="accordion-item">
                                            <div className={`main-link ${!['/admin/manage', '/admin/reports', '/admin/posting-approval', '/admin/add-unique-url'].includes(location.pathname) ? 'collapsed' : ''}`} data-bs-toggle="collapse" role="button" data-bs-target="#company_more" aria-expanded={['/admin/manage', '/admin/reports', '/admin/posting-approval', '/admin/add-unique-url'].includes(location.pathname) ? true : false} title="Social Media Manager">
                                                <svg className="icon" role="img"><use href="#icon_social" /></svg>
                                                Social Media Manager
                                            </div>
                                            <div id="company_more" className={`accordion-collapse collapse ${['/admin/manage', '/admin/reports', '/admin/posting-approval', '/admin/add-unique-url'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                                {socialManager > 0 &&
                                                    <NavLink to="manage" className="sub-link" title='Manage'>Manage</NavLink>
                                                }
                                                {reports > 0 &&
                                                    <NavLink to="reports" className="sub-link" title='Reports'>Reports</NavLink>
                                                }
                                                {postingApproval > 0 &&
                                                    <NavLink to="posting-approval" className="sub-link" title='Posting Approval'>Posting Approval</NavLink>
                                                }
                                                {onboarding > 0 &&
                                                    <NavLink to="add-unique-url" className="sub-link" title='Onboarding'>Onboarding</NavLink>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                {(reviewRespond !== 0 || sentimentAnalysis !== 0 || competitiveAnalysis !== 0) &&
                                    <>
                                        <div className="accordion-item">
                                            <span className={`main-link ${!['/admin/review-response', '/admin/competitive-analysis', '/admin/sentiment-analysis'].includes(location.pathname) ? 'collapsed' : ''}`} role="button" data-bs-toggle="collapse" data-bs-target="#subscription_more" aria-expanded={['/admin/review-response', '/admin/competitive-analysis', '/admin/sentiment-analysis'].includes(location.pathname) ? true : false} title="Reputation Management">
                                                <svg className="icon" role="img" aria-label="more"><use href="#icon_loyalty" /></svg>
                                                Reputation Management
                                            </span>
                                        </div>
                                        <div id="subscription_more" className={`accordion-collapse collapse ${['/admin/review-response', '/admin/competitive-analysis', '/admin/sentiment-analysis'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                            {reviewRespond !== 0 && <NavLink to="review-response" className="sub-link" title='Review & Response'>Review & Response</NavLink>}
                                            {competitiveAnalysis !== 0 && <NavLink to="competitive-analysis" className="sub-link" title='Competitive Analysis'>Competitive Analysis</NavLink>}
                                            {sentimentAnalysis !== 0 && <NavLink to="sentiment-analysis" className="sub-link" title='Sentiment Analysis'>Sentiment Analysis</NavLink>}
                                        </div>
                                    </>
                                }

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Blog" && ele.view === "yes" &&
                                            <a href={`https://blog.amazio.com/?redirect=${context.auth.id}`} target="_blank" rel="noopener noreferrer" className="main-link" title='Blog'>
                                                <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                    <use href="#icon_category" /></svg>
                                                Blog
                                            </a>}
                                    </div>)
                                })}

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Manage Subscribers" && ele.view === "yes" &&
                                            <NavLink to="manage-subscribers-list" className="main-link" title='Manage Subscribers'>
                                                <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                    <use href="#icon_suitcase" />
                                                </svg>
                                                Manage Subscribers
                                            </NavLink>
                                        }
                                    </div>)
                                })}
                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Sales Agent" && ele.view === "yes" &&
                                            <NavLink to="sale-agent-list" className="main-link" title='Sales Agent'>
                                                <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                    <use href="#icon_user" />
                                                </svg>
                                                Sales Agent
                                            </NavLink>
                                        }
                                    </div>)
                                })}

                                {(manage_plan_price !== 0 || promo_code !== 0 || book_keeping !== 0 || payment_invoices !== 0) &&
                                    <>
                                        <div className="accordion-item">
                                            <div className={`main-link ${['/admin/pricing-list', '/admin/payment-invoice-list', '/admin/book-keeping', '/admin/promo-code'].includes(location.pathname) ? 'aside_menu_selected' : 'collapsed'}`} data-bs-toggle="collapse" role="button" data-bs-target="#ads_more" aria-expanded={['/admin/pricing-list', '/admin/payment-invoice-list', '/admin/book-keeping', '/admin/promo-code'].includes(location.pathname) ? true : false} title="Accounting">
                                                <svg className="icon" role="img"><use href="#icon_ads" /></svg>
                                                Accounting
                                            </div>
                                            <div id="ads_more" className={`accordion-collapse collapse ${['/admin/pricing-list', '/admin/payment-invoice-list', '/admin/book-keeping', '/admin/promo-code'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                                {manage_plan_price > 0 &&
                                                    <NavLink to="pricing-list" className="sub-link" title='Manage Plan and Prices'>Manage Plan and Prices</NavLink>
                                                }
                                                {payment_invoices > 0 &&
                                                    <NavLink to="payment-invoice-list" className="sub-link" title='Payments & Invoices'>Payments & Invoices</NavLink>
                                                }
                                                {book_keeping > 0 &&
                                                    <NavLink to="book-keeping" className="sub-link" title='Book Keeping'>Book Keeping</NavLink>
                                                }
                                                {promo_code > 0 &&
                                                    <NavLink to="promo-code" className="sub-link" title='Promo Code'>Promo Code</NavLink>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                {(supports !== 0 || manageFaq !== 0 || requestToCall !== 0 || demoRequest !== 0 || rateUs !== 0 || websiteSupport !== 0) &&
                                    <>
                                        <div className="accordion-item">
                                            <a className={`main-link ${!['/admin/view-tickets-list', '/admin/faqs-list', '/admin/request-to-call', '/admin/request-demo-list', '/admin/rate-us-list', '/admin/view-website-support', '/admin/add-faqs'].includes(location.pathname) ? 'collapsed' : 'aside_menu_selected'}`} data-bs-toggle="collapse" href="/#category_more" aria-expanded={['/admin/view-tickets-list', '/admin/faqs-list', '/admin/request-to-call', '/admin/request-demo-list', '/admin/rate-us-list', '/admin/view-website-support', '/admin/add-faqs'].includes(location.pathname) ? true : false} title="Support">
                                                <svg className="icon icon-h18" role="img" aria-label="more">
                                                    <use href="#icon_setting" />
                                                </svg>
                                                Support
                                            </a>
                                            <div id="category_more" className={`accordion-collapse collapse ${['/admin/view-tickets-list', '/admin/faqs-list', '/admin/request-to-call', '/admin/request-demo-list', '/admin/rate-us-list', '/admin/view-website-support', '/admin/add-faqs'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                                {supports > 0 &&
                                                    <NavLink to="view-tickets-list" className="sub-link" title='Support'>Support</NavLink>
                                                }
                                                {manageFaq > 0 &&
                                                    <NavLink to="faqs-list" className={`sub-link ${['/admin/add-faqs', '/admin/faqs-list'].includes(location.pathname) ? 'active' : ''}`} title="Manage FAQs">Manage FAQs</NavLink>
                                                }
                                                {requestToCall > 0 &&
                                                    <NavLink to="request-to-call" className="sub-link" title='Request To Call'>Request To Call</NavLink>
                                                }
                                                {demoRequest > 0 &&
                                                    <NavLink to="request-demo-list" className="sub-link" title='Demo Requests'>Demo Requests</NavLink>
                                                }
                                                {rateUs > 0 &&
                                                    <NavLink to="rate-us-list" className="sub-link" title='Rate Us'>Rate Us</NavLink>
                                                }
                                                {websiteSupport > 0 &&
                                                    <NavLink to="view-website-support" className="sub-link" title='Website Support'>Website Support</NavLink>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Imprints" && ele.view === "yes" &&
                                            <>
                                                <a className={`main-link ${!['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? 'collapsed' : 'aside_menu_selected'}`} data-bs-toggle="collapse" href="/#imprints_history" aria-expanded={['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? true : false} title="Imprints History">
                                                    <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                        <use href="#icon_menu" />
                                                    </svg>
                                                    Imprints History
                                                </a>
                                                <div id="imprints_history" className={`accordion-collapse collapse ${['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                                    <NavLink to="ced-custom-portal" className="sub-link" title='Ced Custom Portal'>Ced Custom Portal</NavLink>
                                                    <NavLink to="arclight-list" className="sub-link" title='Arclight'>Arclight</NavLink>
                                                    <NavLink to="business-card-list" className="sub-link" title='Business Card'>Business Card</NavLink>
                                                    <NavLink to="vmi-flyer-list" className="sub-link" title='Vmi Flyer'>Vmi Flyer</NavLink>
                                                </div>
                                            </>
                                        }
                                    </div>)
                                })}

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Onboarding Details" && ele.view === "yes" &&
                                        <>
                                            <div className="accordion-item">
                                                <NavLink to="onboarding-details" className="main-link" title='Onboarding Details'>
                                                    <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                        <use href="#icon_suitcase" />
                                                    </svg>
                                                    Onboarding Details
                                                </NavLink>
                                            </div>
                                        </>

                                        }
                                        </div>)
                                })}

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "CED Mapping" && ele.view === "yes" &&
                                            <NavLink to="sso-mapping" className="main-link" title="CED Mapping">
                                                <i className="bi bi-diagram-3 me-3 fs-5"></i>
                                                CED Mapping
                                            </NavLink>}
                                    </div>)
                                })}

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "GMB Active Location" && ele.view === "yes" &&
                                            <NavLink to="active-locations" className="main-link" title="GMB Active Location">
                                                <svg className="icon icon-h18" role="img" aria-label="more">
                                                    <use href="#icon_setting" />
                                                </svg>
                                                GMB Active Location
                                            </NavLink>
                                        }
                                    </div>)
                                })}

                                {context.auth.permission && context.auth.permission.map((ele, num) => {
                                    return (<div className="accordion-item" key={num}>
                                        {ele.permission === "Profile" && ele.view === "yes" &&
                                            <NavLink to="/admin/account-setting" className="main-link" title="Account Setting">
                                                <i className="bi bi-person-badge me-3 fs-5"></i>
                                                Account Setting
                                            </NavLink>}
                                    </div>)
                                })}
                            </>
                        }


                        {/* User Section  */}

                        {context.auth.user_group_id === 3 && (commonDashboard !== 0 || listingDashboard !== 0 || socialDashboard !== 0 || reputationDashboard !== 0) &&
                            <div className="accordion-item">
                                <NavLink to={`${commonDashboard !== 0 ? 'admin-dashboard' : listingDashboard !== 0 ? 'dashboard-listing' : socialDashboard !== 0 ? 'soci-dashboard' : reputationDashboard !== 0 ? 'reputation-dashboard' : ''}`} className={`main-link ${['/users/admin-dashboard', '/users/dashboard-listing', '/users/soci-dashboard', '/users/reputation-dashboard'].includes(location.pathname) ? 'active' : ''}`} title="Dashboard">
                                    <svg className="icon" role="img">
                                        <use href="#icon_dashboard" />
                                    </svg>
                                    Dashboard
                                </NavLink>
                            </div>
                        }

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Business listing" && ele.view === "yes" &&
                                    <NavLink to="view-location" className="main-link" title="Digital Business listing">
                                        <svg className="icon tscale-1p1" role="img">
                                            <use href="#icon_star" />
                                        </svg>
                                        Digital Business listing
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Social Media Manager" && ele.view === "yes" &&
                                    <NavLink to="manage" className="main-link" title="Social Media Manager">
                                        <svg className="icon" role="img"><use href="#icon_social" /></svg>
                                        Social Media Manager
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && (reviewRespond !== 0 || sentimentAnalysis !== 0 || competitiveAnalysis !== 0) &&
                            <>
                                <div className="accordion-item">
                                    <span className={`main-link ${!['/users/review-response', '/users/competitive-analysis', '/users/sentiment-analysis'].includes(location.pathname) ? 'collapsed' : ''}`} role="button" data-bs-toggle="collapse" data-bs-target="#subscription_more" aria-expanded={['/users/review-response', '/users/competitive-analysis', '/users/sentiment-analysis'].includes(location.pathname) ? true : false} title="Reputation Management">
                                        <svg className="icon" role="img" aria-label="more"><use href="#icon_loyalty" /></svg>
                                        Reputation Management
                                    </span>
                                </div>
                                <div id="subscription_more" className={`accordion-collapse collapse ${['/users/review-response', '/users/competitive-analysis', '/users/sentiment-analysis'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    {reviewRespond !== 0 && <NavLink to="review-response" className="sub-link" title='Review & Response'>Review & Response</NavLink>}
                                    {competitiveAnalysis !== 0 && <NavLink to="competitive-analysis" className="sub-link" title='Competitive Analysis'>Competitive Analysis</NavLink>}
                                    {sentimentAnalysis !== 0 && <NavLink to="sentiment-analysis" className="sub-link" title='Sentiment Analysis'>Sentiment Analysis</NavLink>}
                                </div>
                            </>
                        }

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Supports" && ele.view === "yes" &&
                                    <NavLink to="support" className={`main-link ${location.pathname.match('/users/create-ticket/*') ? 'active' : ''}`} title="Support">
                                        <i className="bi bi-life-preserver me-3 fs-5"></i>
                                        Support
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>

                                {ele.permission === "Payment Invoice" && ele.view === "yes" &&
                                    <NavLink to="payment-invoice-list" className="main-link" title="Payment & Invoice">
                                        <i className="bi bi-receipt me-3 fs-5"></i>
                                        Payment & Invoice
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Reports" && ele.view === "yes" &&
                                    <NavLink to="reports" className="main-link" title="Reports">
                                        <i className="bi bi-file-earmark-bar-graph me-3 fs-5"></i>
                                        Reports
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Posting Approval" && ele.view === "yes" &&
                                    <NavLink to="posting-approval" className="main-link" title="Posting Approval">
                                        <i className="bi bi-postcard me-3 fs-5"></i>
                                        Posting Approval
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Website" && ele.view === "yes" &&
                                    <NavLink to="website-support" className="main-link" title="Website">
                                        <i className="bi bi-globe me-3 fs-5"></i>
                                        Website
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Imprints" && ele.view === "yes" &&
                                    <NavLink to="imprints" className={`main-link ${['/users/ced-portal', '/users/arclight', '/users/business-card', '/users/flyer'].includes(location.pathname) ? 'active' : ''}`} title="Imprints">
                                        <i className="bi bi-card-heading me-3 fs-5"></i>
                                        Imprints
                                    </NavLink>}
                            </div>)
                        })}
                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Imprints" && ele.view === "yes" &&
                                    <>
                                        <a className={`main-link ${!['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? 'collapsed' : 'aside_menu_selected'}`} data-bs-toggle="collapse" href="/#imprints_history" aria-expanded={['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? true : false} title="Imprints History">
                                            <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                <use href="#icon_menu" />
                                            </svg>
                                            Order History
                                        </a>
                                        <div id="imprints_history" className={`accordion-collapse collapse ${['/admin/ced-custom-portal', '/admin/arclight-list', '/admin/business-card-list', '/admin/vmi-flyer-list'].includes(location.pathname) ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                            <NavLink to="business-card-list" className="sub-link" title='Business Card'>Business Card</NavLink>
                                        </div>
                                    </>
                                }
                                    </div>)
                            })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "CED Mapping" && ele.view === "yes" &&
                                    <NavLink to="sso-mapping" className="main-link" title="CED Mapping">
                                        <i className="bi bi-diagram-3 me-3 fs-5"></i>
                                        CED Mapping
                                    </NavLink>}
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "GMB Active Location" && ele.view === "yes" &&
                                    <NavLink to="active-locations" className="main-link" title="GMB Active Location">
                                        <svg className="icon icon-h18" role="img" aria-label="more">
                                            <use href="#icon_setting" />
                                        </svg>
                                        GMB Active Location
                                    </NavLink>
                                }
                            </div>)
                        })}

                        {context.auth.user_group_id === 3 && context.auth.permission && context.auth.permission.map((ele, num) => {
                            return (<div className="accordion-item" key={num}>
                                {ele.permission === "Profile" && ele.view === "yes" &&
                                    <NavLink to="/users/account-setting" className="main-link" title="Account Setting">
                                        <i className="bi bi-person-badge me-3 fs-5"></i>
                                        Account Setting
                                    </NavLink>}
                            </div>)
                        })}
                       
                    </>}
                </div>
                <div className="admin-copyrightsbox"><p><i className="bi bi-c-circle"></i> {new Date().getFullYear()} AMAZIO. All rights reserved.</p></div>
            </aside>

            <div className="overlay-onmobile" onClick={() => menuToggle()}></div>

            {/** Logout Model  */}

            <div className="modal fade" id="logoutModal" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">Rate Us</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form name="userRatingForm" method="post" id="userRatingForm">
                                <div className="mb-2 row">
                                    <label htmlFor="star" className="col-sm-2 col-form-label">Rating:</label>
                                    <div className="col-sm-10">
                                        <div className="d-flex justify-content-between">
                                            <input type="hidden" value={str} name="star" id="star" required />
                                            <div className="star-rating mx-3" role="button" onClick={(e) => starRating(e)}>
                                                <span style={{ width: Number(str * 20) + '%' }}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <label htmlFor="description" className="col-sm-2 col-form-label">Comment:</label>
                                    <div className="col-sm-10">
                                        <textarea className="form-control" name="description" id="description" cols="10" defaultValue="" rows="4" placeholder="Write here..." required></textarea>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-10 offset-sm-2">
                                        <button type="button" className="btn btn-dark minw-100 mx-2" onClick={() => handleLogout('rating')}>Submit</button>
                                        <button type="button" className="btn btn-secondary minw-100" onClick={() => handleLogout('cancel')}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AsideBar;
