import { useState } from "react";
import { initialFormState } from "../../components/Helper";
import { fetchData, GENERATE_CED_LOGIN_TOKEN } from "../../components/Service";

function GenerateCedLoginToken() {
    const [tinyloader, setTinyloader] = useState(false);
    const [token, setToken] = useState(false);

    const submitForm = (e) => {
        let formdata = new FormData(document.getElementById('generateTokenForm'));

        fetchData(GENERATE_CED_LOGIN_TOKEN, 'POST', formdata, true, true, (res) => {
            setTinyloader(false)
            if (res.records) {
                setToken(res.records)
                initialFormState("generateTokenForm")
            }
        });
    }

    return (
        <section className="container py-5">
            <div className="row justify-content-center g-4">
                <div className="col-sm-12 col-md-9 col-xl-6">
                    <h2 className="fw-bolder lh-base pb-3 text-center">Generate CED Login Token</h2>

                    {token ? <>
                        <div className="row mb-3">
                            <div className="col-sm-12">
                                <span className="" style={{wordBreak: "break-all"}}>{token}</span>
                            </div>
                            <div className="col-sm-12 mt-3">
                                <button type="button" className="btn btn-outline-danger minw-100" onClick={() => setToken(false)}>Back</button>
                            </div>
                        </div>
                    </> :
                        <form className={`needs-validation p-3`} id="generateTokenForm">
                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="jti" name="jti" placeholder="jti"/>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <input type="datetime-local" className="form-control" id="iat" name="iat" placeholder="iat"/>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="location_id" name="location_id" placeholder="Location Id"/>
                                        <span className="text-muted" style={{fontSize: "14px"}}>Hint- 1001,1002,1003</span>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="username" name="username" placeholder="User Name"/>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="firstname" name="firstname" placeholder="First Name"/>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="lastname" name="lastname" placeholder="Last Name"/>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="phone" name="phone" placeholder="Phone"/>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <select className="form-select" id="role" name="role">
                                            <option value="">Select Role</option>
                                            <option value="manager" selected>Manager</option>
                                            <option value="delegate">Delegate</option>
                                            <option value="public">Public</option>
                                        </select>
                                    </div>
                                </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-dark minw-100" disabled={tinyloader} onClick={submitForm}>
                                        {tinyloader ?
                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> 
                                        : 'Generate'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </section>

    )
}

export default GenerateCedLoginToken