import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client'
import { __echoText, initialFormState, validateForm } from "../../components/Helper";
import { fetchData, FAQ_LIST, DELETE_FAQ, UPDATE_FAQ } from "../../components/Service";
import { useNavigate } from "react-router-dom";
import * as Elements from "../../components/Elements";
import { now } from "lodash";
import DataTables, { redrawDataTable } from "../../components/Datatables";
import $ from 'jquery'
import { Context } from "../../components/Context";

function ManageFaq() {
    window.document.title = "Manage FAQs"
    const navigate = useNavigate()
    const [context] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [refresh, setRefresh] = useState(now())
    const [formField, setFormField] = useState({
        id: '',
        question: '',
        answer: ''
    })

    const editForm = (ele) => {
        setFormField(ele)
        setPageLoader(false)
    }

    const deleteRecords = (id) => {
        fetchData(DELETE_FAQ + '?id=' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    const updateFaqData = (e) => {
        let formData = new FormData(document.getElementById('editFaqForm'));

        if (validateForm(e, 'editFaqForm')) {
            setTinyloader(true);
            fetchData(UPDATE_FAQ, 'POST', formData, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    document.querySelector('#editModal [data-bs-dismiss="modal"]').click()
                    setRefresh(now())
                }
            })
        }
    }

    const [dt] = useState({
        dt_url: FAQ_LIST,
        dt_name: 'faqList',
        dt_export: true,
        dt_column: [
            { data: 'rank', name: 'rank', title: 'Rank' },
            { data: 'question', name: 'question', title: 'QUESTION', width: "30%" },
            { data: 'answer', name: 'answer', title: 'ANSWER', width: "60%" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                target: 0,
                visible: false,
                searchable: false
            },
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.question)}</>)
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.answer)}</>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Manage FAQs" &&
                                                <>
                                                    {item.edit === "yes" ?
                                                        <button className="btn btn-sm text-success" title="Edit" data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => { setPageLoader(true); editForm(records) }}>
                                                            <svg className="icon fs-5 lh-1" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                    : 
                                                        <Elements.DisableButton btnType="success" icon="#icon_edit" />
                                                    
                                                    }
                                                    {item.delete === "yes" ?
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormField(prevState => ({
                                                            ...prevState, id: records.id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    :
                                                        <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            : 
                                <>
                                    <button className="btn btn-sm text-success" title="Edit" data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => { setPageLoader(true); editForm(records) }}>
                                        <svg className="icon fs-5 lh-1" role="img"><use href="#icon_edit" /></svg>
                                    </button>
                                    <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormField(prevState => ({
                                        ...prevState, id: records.id
                                    }))}>
                                        <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                    </button>
                                </>
                            }
                        </div>
                    )
                },
            }
        ],
        dt_order: [[0, 'asc']]
    });

    window.navigateToAddFaq = () => {
        navigate('/admin/add-faqs')
    }

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        if (!$('.dt-custom-filter button').hasClass('add-new')) {

            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                context.auth.permission && context.auth.permission.map((item, key) => {
                    return(
                        <div key={key}>
                            {item.permission === "Manage FAQs" &&
                                <>
                                    {item.add === "yes" && 
                                        $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" onclick="navigateToAddFaq()">
                                            <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>Add New FAQs
                                        </button>`)
                                    }
                                </>
                            }
                        </div>
                    )
                        })
            : 
                $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" onclick="navigateToAddFaq()">
                    <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>Add New FAQs
                </button>`)
            }
            
        }
    }, [dt, refresh])

    return (
        <>
            <Elements.ListSection title='FAQs Listing'>
                <DataTables dt_name="faqList" dataPageLength="15" />
            </Elements.ListSection>

            <Elements.ModalSection title="Edit FAQs" setData={formField} form_id="editFaqForm" modal_id="editModal" size="lg" page_loader={pageLoader}>
                <form className="needs-validation p-3" method="post" id="editFaqForm" noValidate>
                    <div className="mb-3 row">
                        <input type="hidden" id="rowid" name="id" defaultValue={formField.id} />
                        <label htmlFor="question" className="col-sm-3 col-form-label">Question<span className="text-danger">*</span> :</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="question" name="question" defaultValue={formField.question} placeholder="Enter Question" required />
                            <div className="invalid-feedback">This question field is required.</div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="answer" className="col-sm-3 col-form-label">Answer<span className="text-danger">*</span> :</label>
                        <div className="col-sm-9">
                            <textarea className="form-control" name="answer" id="answer" cols="10" defaultValue={formField.answer} rows="4" placeholder="Enter Answer" required></textarea>
                            <div className="invalid-feedback">This answer field is required.</div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" onClick={() => initialFormState('editFaqForm', formField)} data-bs-dismiss="modal" className="btn btn-outline-danger px-4">Back</button>
                        <button type="button" className="btn btn-dark px-4 ms-3" onClick={(e) => updateFaqData(e)} disabled={tinyloader}>
                            {
                                !tinyloader ? 'Update' :
                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                            }
                        </button>
                    </div>
                </form>
            </Elements.ModalSection>

            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formField.id)} />
        </>
    )
}

export default ManageFaq
