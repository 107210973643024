import React from 'react'
import Chart from 'chart.js/auto';
import { useEffect } from 'react';

export default function ChartComponant(props){
    
    useEffect(() => {
        var ctx = document.getElementById(props.id).getContext("2d");
        new Chart(ctx, {
            type:props.type ,
            labels:props.label,
            data: props.data ?? [],
            options: {
                plugins:  
                    props.plugins 
            }
            
        });

        return () => {
            let chartStatus = Chart.getChart(props.id); 
            if (chartStatus !== undefined) {
                chartStatus.destroy();
            }
        }
    })
    return(
        <canvas id={props.id}></canvas>
    )
}
