import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

export default function RatingBackDrops(props) {
  useEffect(() => {
    Highcharts.chart('dropchart', {
        chart: {
            type: 'column'
        },
          colors: [
           '#4662a0',
           '#aadb87',
           '#da495b',
           '#a87bc6',
           '#fde5a5',
           '#66ceed',
           '#d565ad',
           '#7ea45d',
           '#eace6b',
           '#66edc6',
           '#fdb7a5'
          ],
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        legend: {
            enabled: false
        },
        credits: {
           enabled: false
         },
         navigation:{
             buttonOptions: {
             enabled: false
           }
         },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:f}'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:f}</b> of total<br/>'
        },
        "series": [
            {
                "name": "Browsers",
                "colorByPoint": true,
                "data": props.data
            }
        ],
    });
    }, [props,props.data]);
    return (
        <div id='dropchart' style={{height: '270px', margin:'0px auto'}}></div>
    )
}
