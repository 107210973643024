import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

export default function CompetitiveLineChart(props) {
  useEffect(() => {
    Highcharts.chart('line-chart', {

        title: {
          text: ''
        },
      
        subtitle: {
          text: ''
        },
      
        yAxis: {
          title: {
            text: ' '
          }
        },
      
        xAxis: {
          categories: props.data['0'],
          tickmarkPlacement: 'on',
          accessibility: {
            rangeDescription: 'Range: 2010 to 2017'
          }
        },
      
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        },

        credits: {
            enabled: false
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        tooltip: {
            shared: true,
        },
      
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 3,
                marker: {
                    lineWidth: 3,
                    lineColor: '#666666'
                }
            }
        },
      
        series: [{
          name: 'Local Pack Share Of Search',
          data: props.data[1]
        }, {
          name: 'Share Of Intelligent Search',
          data: props.data[2]
        }, {
          name: 'Organic Share of Search',
          data: props.data[3]
        }],

        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }
      
      });
    }, [props.data]);
    return (
        <div id="line-chart" style={{height: '270px', margin:'0px auto'}}></div>
    )
}
