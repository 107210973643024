const Design1 = (props) => {
    return (
        <div className={`card-box-container ${props.viewType === 'preview' ? 'bg-white' : ''}`} id="Design1">
            <div className="card-box" id="printing-card-box">
                <div className={`card1 ${props.flipCard === 'back' ? 'flip' : ''} ${props.viewType === 'preview' ? 'mt-0' : ''}`}>
                    <div className="front p-2">
                        {props?.formField && props.formField.card_type === 'both' &&
                            props.viewType !== 'preview' ? <span className="flipButton btn btn-secondary d-inline-block btn-theming" onClick={props.changeFlipCard}>Flip Card</span> : <h5 className="fs-4 fw-bold mb-3">Front View</h5>
                        }
                        <section className={props.viewType === 'preview' ? 'border' : ''}>
                            <div className={`flip-box-front d-flex flex-column ${props.viewType !== 'preview' ? 'border-dotted' : ''}`}>
                                <div className="set-logo text-center py-2 logo-bgcolor" style={{ background: props.formField.logo_bg_color, fontFamily: props.formField.font, color: props.formField.text_color }}>
                                  
                                    <img src={props.newImg ? props.newImg : 'images/CED.png'} className="card-logo" alt="card" />
                             
                                    <p className="fw-bold card-fs mb-0  tag_line pt-2" >{props.formField.tag_line}</p>
                                </div>
                                <div className="card-background p-1 h-100 pb-3" style={{ background: props.formField.bg_color, fontFamily: props.formField.font, color: props.formField.text_color }} >
                                    <h6 className="card-fs m-0 pt-2 text-center"><b>{props.formField.pc_name}</b></h6>
                                    <div className="d-flex justify-content-between h-100">
                                        <div className="text-left align-self-end pr-2 w-45per">
                                            <h6 className="card-fs m-0 pt-2  name"><b>{props.formField.name}</b></h6>
                                            <h6 className="card-fs m-0 pt-2  job_title">{props.formField.job_title}</h6>
                                            <p className="card-fs m-0 p-0 pt-1  mb-0 street_address">{props.formField.street}</p>
                                            <p className="mb-0">
                                                <span className="card-fs m-0 p-0 pt-1 pe-1 mb-0 city">{props.formField.city}</span>
                                                <span className="card-fs m-0 p-0 pt-1 pe-1 mb-0 state">{props.formField.state}</span>
                                                <span className="card-fs m-0 p-0 pt-1 pe-1 mb-0 zip">{props.formField.zip}</span>
                                            </p>

                                        </div>
                                        <div className="text-end align-self-end w-55per">
                                            <h6 className="card-fs m-0 pt-2  email"><b>{props.formField.contact_email}</b></h6>
                                            <p className="card-fs m-0 p-0 pt-1  mb-0 url">{props.formField.pc_url}</p>
                                            {(props.formField.phone || props.formField.def_phone) && <p className="card-fs m-0 p-0  mb-0 num">Direct: {props.formField.phone ? props.formField.phone : props.formField.def_phone}</p>}
                                            {props.formField.cell_phone && <p className="card-fs m-0 p-0  mb-0 num">Cell: {props.formField.cell_phone && props.formField.cell_phone}</p>}
                                            {props.formField.help_desk_phone && <p className="card-fs m-0 p-0  mb-0 num">Help Desk: {props.formField.help_desk_phone && props.formField.help_desk_phone}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {props?.formField && props.formField.card_type === 'both' &&
                        <div className={props.viewType !== 'preview' ? 'back' : 'mt-90pc p-2'}>
                            
                            {props.viewType !== 'preview' ? <span className="flipButton btn btn-secondary d-inline-block btn-theming" onClick={props.changeFlipCard}>Flip Card</span> : <h5 className="fs-4 fw-bold mb-3">Back View</h5>}
                            <section>
                                <div className={`logo-box logo-back text-center py-4 ${props.viewType !== 'preview' ? 'border-dotted' : 'border'}`}>
                                    <p className="mb-0 card-fs name">{props.formField.pc_name}</p>
                                    <p className="mb-0 card-fs name">{props.formField.name}</p>
                                    <p className="mb-0 card-fs  num">{props.formField.phone ? props.formField.phone : props.formField.def_phone}</p>
                                    <p className="mb-0 card-fs  email">{props.formField.contact_email}</p>
                                    <p className="mb-0 card-fs  street_address">{props.formField.street}</p>
                                    <p className="mb-0">
                                        <span className="mb-0 card-fs pe-1 city" >{props.formField.city}</span>
                                        <span className="mb-0 card-fs pe-1 state">{props.formField.state}</span>
                                        <span className="mb-0 card-fs pe-1 zip">{props.formField.zip}</span>
                                    </p>
                                </div>
                            </section>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Design1