import Select from 'react-select'
function EnhancedContent(props) {
    return (
        <>
            <div className="row g-0 mb-3 py-2 bg-light">
                <div className="col-sm-12 px-3">
                    <div className="fw-medium text-primary">Enhanced Content Lists</div>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Products and Services</label>
                <div className="col-md-8">
                    <div className="mt-2">
                        <div className="row mb-3">
                            <label htmlFor="display_link" className="col-md-5 text-md-end col-form-label">Display link on publisher sites as:</label>
                            <div className="col-md-7">
                                <input type="text" className="form-control" name="display_link[]" disabled defaultValue={props.formdata && props.formdata.product_lists_label} />
                            </div>
                        </div>
                        <Select
                            options={props.product}
                            isMulti
                            isClearable={true}
                            closeMenuOnSelect={false}
                            value={props.productValue}
                            onChange={(e) => props.setSelectedProduct(e ?? [])}
                            className="basic-multi-select react-select"
                            name="product_id[]"
                            id="product_id"
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Calender</label>
                <div className="col-md-8">
                    <div className="mt-2">
                        <div className="row mb-3">
                            <label htmlFor="display_link" className="col-md-5 text-md-end col-form-label">Display link on publisher sites as:</label>
                            <div className="col-md-7">
                                <input type="text" className="form-control" disabled defaultValue={props.formdata && props.formdata.event_lists_label} name="display_link[]" required />
                            </div>
                        </div>
                        <Select
                            options={props.calender}
                            isMulti
                            isClearable={true}
                            closeMenuOnSelect={false}
                            name="event_id[]"
                            className="basic-multi-select react-select"
                            id="calender"
                            value={props.calenderValue}
                            onChange={(e) => props.setSelectedCalender(e ?? [])}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Staff Bios</label>
                <div className="col-md-8">
                    <div className="mt-2">
                        <div className="row mb-3">
                            <label htmlFor="display_link" className="col-md-5 text-md-end col-form-label">Display link on publisher sites as:</label>
                            <div className="col-md-7">
                                <input type="text" className="form-control" disabled defaultValue={props.formdata && props.formdata.bio_lists_label} name="display_link[]" required />
                            </div>
                        </div>
                        <Select
                            options={props.bios}
                            isMulti
                            isClearable={true}
                            closeMenuOnSelect={false}
                            name="bio_id[]"
                            className="basic-multi-select react-select"
                            id="bois"
                            value={props.biosValue}
                            onChange={(e) => props.setSelectedBios(e ?? [])}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Menus</label>
                <div className="col-md-8">
                    <div className="mt-2">
                        <div className="row mb-3">
                            <label htmlFor="display_link" className="col-md-5 text-md-end col-form-label">Display link on publisher sites as:</label>
                            <div className="col-md-7">
                                <input type="text" className="form-control" disabled defaultValue={props.formdata && props.formdata.menus_label} name="display_link[]" required />
                            </div>
                        </div>
                        <Select
                            options={props.menu}
                            isMulti
                            isClearable={true}
                            closeMenuOnSelect={false}
                            name="menu_id[]"
                            className="basic-multi-select react-select"
                            id="menu"
                            value={props.menuValue}
                            onChange={(e) => props.setSelectedMenu(e ?? [])}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnhancedContent