import React from "react";
import { state } from "../../components/Helper"
import $ from 'jquery'
import Design1 from "./CardDesign/Design1";
import Design2 from "./CardDesign/Design2";
import Design3 from "./CardDesign/Design3";
import Design4 from "./CardDesign/Design4";
import Design5 from "./CardDesign/Design5";
import Design6 from "./CardDesign/Design6";
import * as htmlToImage from 'html-to-image';
import Design7 from "./CardDesign/Design7";

function PreviewCard(props) {

    const saveCardInfo = async (e) => {
        if(props && props.data && props.data.part === 'part_two'){
            let card_element = document.getElementById("card_preview_section");
            let dataUrl = await htmlToImage.toPng(card_element)
            props.saveCardDetail(e, dataUrl)
        }
    }
    
    return (<>
        <div className="container">
            <div className="row px-4 mt-4 justify-content-center justify-content-lg-between">
                <div className="col-md-12 col-lg-5 my-5" id={props?.data && props.data.card_type === 'front' ? 'card_preview_section' : ''}>
                    <div className="col-md-12 col-lg-12 py-2 px-1" id={props?.data && props.data.card_type === 'both' ? 'card_preview_section' : ''}>
                        {props.design === 'Design1' && <Design1 newImg={props.newImg} formField={props.data} viewType="preview" />}
                        {props.design === 'Design2' && <Design2 newImg={props.newImg} formField={props.data} viewType="preview" />}
                        {props.design === 'Design3' && <Design3 newImg={props.newImg} formField={props.data} viewType="preview" />}
                        {props.design === 'Design4' && <Design4 newImg={props.newImg} formField={props.data} viewType="preview" />}
                        {props.design === 'Design5' && <Design5 newImg={props.newImg} formField={props.data} viewType="preview" />}
                        {props.design === 'Design6' && <Design6 newImg={props.newImg} formField={props.data} changeBackViewClass={true} frontView={true} viewType="preview" />}
                        {props.design === 'Design7' && <Design7 newImg={props.newImg} formField={props.data} viewType="preview" />}
                    </div>
                    
                </div>
                <div className="col-md-12 col-lg-7">
                    <form className="" id="second_step" noValidate>
                        <h4 className="">Quantity</h4>
                        <div className="">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="quantity" id="flexRadioDefault1" value="500" onClick={(e) => props.setFormField(prevState => ({
                                    ...prevState, quantity: e.target.value
                                }))} defaultChecked />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    500 cards $40.00 {props?.data && props.data.card_type === 'both' ? '+ $10.00 for back side' : ''}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="quantity" value="1000" id="flexRadioDefault2" onClick={(e) => props.setFormField(prevState => ({
                                    ...prevState, quantity: e.target.value
                                }))} />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    1000 cards $50.00 {props?.data && props.data.card_type === 'both' ? '+ $10.00 for back side' : ''}
                                </label>
                            </div>
                            <div className="mt-3">
                                <span className="">
                                    Shipping Charge $15.00
                                </span>
                            </div>
                        </div>
                        <div className="mt-3">
                            <label>Notes</label>
                            <textarea name="notes" id="notes" className="form-control" onKeyUp={(e) => props.setFormField(prevState => ({
                                ...prevState, notes: e.target.value
                            }))}></textarea>
                        </div>
                        <div className="my-3">
                            <h4>Billing</h4>
                            <div>
                                <div className="align-items-center d-flex flex-sm-nowrap flex-wrap mb-2">
                                    <label className="form-check mb-2" htmlFor="pc">
                                        <input className="form-check-input" type="radio" name="billing_type" id="pc" value="pc_number" onClick={(e) => props.setFormField(prevState => ({
                                            ...prevState, billing_type: e.target.value, cost_center: '', phone_pay: ''
                                        }))} defaultChecked />
                                        <div className="form-check-label mmw-220">Bill to PC</div>
                                    </label>
                                    <input type="text" name="pc_number" className="form-control billing-value-box" defaultValue={props && props.data && props.data.pc_number} disabled={$('#pc').is(':checked') ? false : true} placeholder="Enter PC" onKeyUp={(e) => props.setFormField(prevState => ({
                                        ...prevState, pc_number: e.target.value
                                    }))} />
                                </div>
                                <div className="align-items-center d-flex flex-sm-nowrap flex-wrap mb-2">
                                    <label className="form-check mb-2" htmlFor="cost">
                                        <input className="form-check-input" type="radio" name="billing_type" id="cost" value="cost_center"
                                            onClick={(e) => props.setFormField(prevState => ({
                                                ...prevState, billing_type: e.target.value, pc_number: '', phone_pay: '',
                                            }))}
                                        />
                                        <div className="form-check-label mmw-220">Bill to Cost Center</div>
                                    </label>
                                    <input type="text" name="cost_center" className="form-control billing-value-box" disabled={$('#cost').is(':checked') ? false : true} placeholder="Enter Cost Center" onKeyUp={(e) => props.setFormField(prevState => ({
                                        ...prevState, cost_center: e.target.value
                                    }))} />
                                </div>
                                <div className="align-items-center d-flex flex-sm-nowrap flex-wrap mb-2">
                                    <label className="form-check mb-2" htmlFor="phone">
                                        <input className="form-check-input" type="radio" name="billing_type" id="phone_pay" value="phone_pay" onClick={(e) => props.setFormField(prevState => ({
                                            ...prevState, billing_type: e.target.value, pc_number: '', cost_center: ''
                                        }))} />
                                        <div className="form-check-label mmw-220">Pay by Phone</div>
                                    </label>
                                    <input type="text" name="phone_pay" className="form-control billing-value-box" placeholder="Enter Contact" disabled={$('#phone_pay').is(':checked') ? false : true} onKeyUp={(e) => props.setFormField(prevState => ({
                                        ...prevState, phone_pay: e.target.value
                                    }))} />
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <h4>Shipping</h4>
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <textarea name="shipping_address" id="shipping_address" className="form-control" placeholder="Shipping Address Line" onKeyUp={(e) => props.setFormField(prevState => ({
                                            ...prevState, shipping_address: e.target.value
                                        }))} required="required"></textarea>
                                        <div className="invalid-feedback">The shipping Field is required</div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mt-2">
                                        <div className="input Select required">
                                            <select className="form-select scan-select px-2" name="shipping_state" id="shipping_state" defaultValue="" placeholder="State" onChange={(e) => props.setFormField(prevState => ({
                                                ...prevState, shipping_state: e.target.value
                                            }))} required>
                                                <option value="">--Select State--</option>
                                                { 
                                                    Object.keys(state).map((ele, num) => <option value={ele} key={num}>{state[ele]}</option>)
                                                }
                                            </select>
                                            <div className="invalid-feedback">The state field is required</div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 mt-2">
                                        <input type="text" name="shipping_city" id="shipping_city" className="form-control mb-2" placeholder="City" onKeyUp={(e) => props.setFormField(prevState => ({
                                            ...prevState, shipping_city: e.target.value
                                        }))} required />
                                        <div className="invalid-feedback">The city field is required</div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 mt-2">
                                        <div className="input Select">
                                            <select name="shipping_country" id="shipping_country" placeholder="Country *" className="form-select" onChange={(e) => props.setFormField(prevState => ({
                                                ...prevState, shipping_country: e.target.value
                                            }))} required="required">
                                                <option value="US">United States</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mt-2">
                                        <input type="text" minLength={4} maxLength={9} onKeyPress={e => { if (!(e.charCode >=48 && e.charCode <= 57) ) { e.preventDefault(); } }} name="shipping_zip" id="shipping_zip" className="form-control mb-2" placeholder="Zip" onKeyUp={(e) => props.setFormField(prevState => ({
                                            ...prevState, shipping_zip: e.target.value
                                        }))} required />
                                        <div className="invalid-feedback">The zip field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-dark cursor-pointer" onClick={(e) => saveCardInfo(e)} disabled={props.tinyloaderII}>
                                {
                                    !props.tinyloaderII ? 'Submit'
                                    :
                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default PreviewCard