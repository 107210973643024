import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function MetaComponent() {
    const location = useLocation();

    const meta = useMemo(() => {
        switch (location.pathname) {

            case "/":
                return {
                    title: "Top Business Listing & Social Media Management Solutions | Amazio",
                    description: "Amazio offers comprehensive Business Listing and Social Media Management services. Enhance your online presence and optimize your business listings effectively.",
                    keywords: "Business listing management, social media services, online presence enhancement, digital marketing solutions, business optimization, SEO management, social media strategy, listing optimization, business growth, marketing management",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/home":
                return {
                    title: "Top Business Listing & Social Media Management Solutions | Amazio",
                    description: "Amazio offers comprehensive Business Listing and Social Media Management services. Enhance your online presence and optimize your business listings effectively.",
                    keywords: "Business listing management, social media services, online presence enhancement, digital marketing solutions, business optimization, SEO management, social media strategy, listing optimization, business growth, marketing management",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/services":
                return {
                    title: "Business Listing & Social Media Management Services | Amazio",
                    description: "Explore Amazio's comprehensive services, including Business Listing Management and Social Media Management, to enhance your online presence and drive business growth.",
                    keywords: "Business listing management, social media management, digital marketing, SEO services, business growth, online visibility, social media optimization, listing services, directory management, marketing solutions",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/business-listing-management-usa": //new added
                return {
                    title: "Business Listing Management Services | Amazio USA Services",
                    description: "Boost your local SEO with Amazio’s Business Listing Management.Our USA based services ensure effective listing optimization and improved online visibility.",
                    keywords: "Business listing management, Local SEO services, Online business listings, Listing optimization, Local business visibility, Business directory management, USA business listing services, Improve local SEO, Business profile management, Enhance online visibility",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/industries":
                return {
                    title: "Explore Our Industry Solutions | Expert Services for Every Sector",
                    description: "Amazio offers expert services across various industries. Explore our industry solutions and find out how we can support your business with tailored strategies.",
                    keywords: "Industries served, sector solutions, industry expertise, tailored services, business solutions, Amazio, industry specific services, sector-focused strategies",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/plans-pricing": // New changes
                return {
                    title: "Flexible Plans & Pricing | Customized Business Solutions | Amazio",
                    description: "Discover Amazio’s flexible Plans & Pricing, offering customized solutions for businesses of all sizes. Find the perfect plan to match your budget and needs.",
                    keywords: "Flexible plans, pricing options, business solutions, custom pricing, tailored business plans, Amazio, affordable packages, service pricing, competitive solutions",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/business-listing-management-usa":
                return {
                    title: "Home",
                    description: "description",
                    keywords: "keyword",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/digital-content-imaging-services": // New changes
                return {
                    title: "Amazio Digital Content & Imaging Services | Boost Your Brand",
                    description: "Enhance your brand’s impact with Amazio’s Digital Content & Imaging Services. Create high quality content and visuals that captivate and grow your audience online. ",
                    keywords: "Digital content services, imaging solutions, visual storytelling, content creation, brand enhancement, content marketing, digital imaging, graphic design, online branding, creative content",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/social-media-management": // New changes
                return {
                    title: "Social Media Management for Business Growth | Amazio",
                    description: "Amazio provides tailored Social Media Management solutions to boost your online presence. Drive engagement, grow your audience, and manage your brand's social platforms.",
                    keywords: "Professional social media management, social media marketing, brand engagement, online visibility, social media strategy, content creation, audience growth, social media experts, digital marketing services, online presence management",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/reviews-reputation-sentiment-analysis": // New Added
                return {
                    title: "Reviews & Reputation Management Services | Amazio",
                    description: "Enhance your brand’s image with Amazio’s Reviews & Reputation Management services. Analyze customer sentiment and maintain a positive online presence.",
                    keywords: "Review management, reputation management, sentiment analysis, online reputation, brand monitoring, customer reviews, online feedback, brand sentiment, review analysis, reputation protection",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/Blogs": // New Added no exist in amazio react app
                return {
                    title: "Amazio Blog | Latest Marketing Insights & Digital Strategies",
                    description: "Visit the Amazio blog for the latest insights into marketing, digital strategies, and SEO. Stay ahead with tips and trends for growing your business online.",
                    keywords: "Marketing insights, digital strategies, SEO tips, Amazio blog, business growth, online marketing trends, expert advice, business blogs, digital marketing",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/Case Studies":  // New Added no exist in amazio react app
                return {
                    title: "Case Studies | Successful Digital Marketing & Business Solutions",
                    description: "Read Amazio’s case studies to see how we deliver successful digital marketing, SEO, and business solutions. Learn how our clients achieved measurable growth.",
                    keywords: "Digital marketing case studies, SEO success stories, business growth, Amazio success, client results, business solutions, case studies, measurable success, Amazio case studies",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/knowledge-base":
                return {
                    title: "Knowledge Base | Expert Resources & Support | Amazio",
                    description: "Find detailed guides and FAQs in Amazio’s Knowledge Base. Access self-help resources and solutions to common issues, and learn how to maximize our services.",
                    keywords: "Knowledge base, self help resources, guides, FAQs, Amazio support, tutorials, service help, customer support, solutions, help center",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/request-demo":
                return {
                    title: "Schedule a Demo | Discover Amazio’s Solutions for Your Business",
                    description: "Discover how Amazio’s solutions can benefit your business. Request a demo to see our services in action and explore the advantages of partnering with us.",
                    keywords: "Schedule a Demo, Amazio solutions, business benefits, demo request, discover services, digital solutions, personalized demo, Amazio services, business growth",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/web-mobile-informational-tags": // New Changes
                return {
                    title: "Optimized Web & Mobile Informational Tagging Services | Amazio",
                    description: "Optimize your website and mobile app with Amazio’s Web & Mobile Informational Tags. Improve SEO, UX, and overall performance with structured tagging solutions.",
                    keywords: "Web tags, mobile tags, SEO optimization, informational tagging, user experience, website navigation, UX improvement, metadata, web development, mobile app optimization",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/web-app-development-maintenance": // New added
                return {
                    title: "Web & App Development with Maintenance Services | Amazio",
                    description: "Amazio offers professional Web & App Development with Maintenance services. Build, maintain, and optimize your digital platforms with our expert solutions.",
                    keywords: "Web development, app development, maintenance services, website maintenance, mobile app support, digital platforms, website optimization, app updates, web solutions, app solutions",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/competitive-intelligence-insights-analytics": // New changes
                return {
                    title: "Competitive Intelligence & Data Analytics Solutions | Amazio",
                    description: "Make data-driven decisions with Amazio’s Competitive Intelligence and Analytics. Uncover market insights and competitor strategies to gain a competitive advantage.",
                    keywords: "Competitive intelligence, business insights, analytics services, market analysis, data-driven decisions, competitor analysis, business strategy, market research, business intelligence, analytics solutions",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/print-media":
                return {
                    title: "Print Media Design & Marketing Services | Amazio",
                    description: "Amazio provides full service Print Media solutions. From design to delivery, we create impactful print materials for effective marketing campaigns.",
                    keywords: "Print media, print design services, marketing materials, print advertising, brochures, posters, flyers, catalogs, business cards, print solutions",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/voice-search-pro":
                return {
                    title: "Optimize Your Website with Voice Search Pro | Amazio",
                    description: "Stay ahead of the competition with Amazio's Voice Search Pro. Optimize your site for voice search to attract more users and improve your SEO performance.",
                    keywords: "Voice search optimization, voice SEO services, voice search technology, SEO ranking, digital marketing, voice assistant optimization, voice search strategy, voice search ranking, SEO improvement",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/seo-services": // New Added no exist in amazio react app
                return {
                    title: "SEO Services for Higher Traffic & Better Rankings | Amazio",
                    description: "Optimize your website with Amazio’s SEO services. Increase search engine rankings, drive organic traffic, and enhance your online presence with proven SEO tactics.",
                    keywords: "SEO services, website optimization, search engine rankings, increase traffic, digital marketing, on-page SEO, off-page SEO, technical SEO, SEO strategy, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/paid-media-promotions":  // New Added no exist in amazio react app
                return {
                    title: "Expert Paid Media Promotions for Maximum ROI | Amazio",
                    description: "Maximize your ROI with Amazio’s Paid Media Promotions. Get targeted traffic and improved ad performance through expertly managed ad campaigns and strategies.",
                    keywords: "Paid media promotions, ROI optimization, ad management, PPC advertising, digital ads, targeted traffic, media buying, online ad campaigns, ad performance, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/web-and-landing-page-development": // New Added no exist in amazio react app
                return {
                    title: "Web & Landing Page Development Services | Amazio",
                    description: "Amazio offers expert Web and Landing Page Development services. Create impactful websites and high converting landing pages with our professional design solutions.",
                    keywords: "Web development, landing page design, website creation, landing page optimization, web design services, landing page development, digital marketing, conversion optimization, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/app-development": //New Added no exist in amazio react app
                return {
                    title: "Professional App Development | Mobile & Web Solutions | Amazio",
                    description: "Amazio offers professional App Development services for both mobile and web platforms. Create user-friendly, innovative apps that drive engagement and growth.",
                    keywords: "App development, mobile apps, web apps, app design, custom app solutions, app development services, software engineering, digital apps, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/content-development":// New Added no exist in amazio react app
                return {
                    title: "Content Development Services |Engaging & Creative Content |Amazio",
                    description: "Amazio offers top tier Content Development services. Create engaging, high-quality content tailored to your brand’s needs to boost your online presence.",
                    keywords: "Content development, content creation, engaging content, creative writing, digital content, brand messaging, content strategy, website content, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/video-marketing":// New Added no exist in amazio react app
                return {
                    title: "Professional Video Marketing Services | Amazio",
                    description: "Amazio provides professional Video Marketing services to help you reach your audience effectively. Engage viewers and drive results with high-quality video content.",
                    keywords: "Professional video marketing, video services, digital video, video content creation, engagement videos, video strategy, online marketing, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/personal-branding":// New Added no exist in amazio react app
                return {
                    title: "Personal Branding Services | Build Your Unique Brand | Amazio",
                    description: "Amazio offers expert Personal Branding services to help you build a unique and powerful brand identity. Stand out and advance your career with our strategic solutions.",
                    keywords: "Personal branding, brand identity, career development, branding services, personal brand strategy, unique branding, professional branding, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/free-subscription":// New Added no exist in amazio react app
                return {
                    title: "Start Free Subscription | Try Amazio’s Services for Free",
                    description: "Register for a free subscription with Amazio to experience our digital solutions. Discover how our services can enhance your business with no initial cost.",
                    keywords: "Amazio Free Subscription, risk-free trial, explore services, free access, digital solutions, subscription benefits, business solutions, Amazio offerings, free trial",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/log-in":
                return {
                    title: "Amazio Log In | Secure Access to Your Digital Marketing Account",
                    description: "Access your Amazio account securely with our log in page. Manage your digital marketing services and optimize your business growth with ease.",
                    keywords: "Amazio log in, account access, secure login, digital marketing, service management, business growth, Amazio account, user credentials, online services",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/sign-up":
                return {
                    title: "Amazio Log In | Secure Access to Your Digital Marketing Account",
                    description: "Access your Amazio account securely with our log in page. Manage your digital marketing services and optimize your business growth with ease.",
                    keywords: "Amazio log in, account access, secure login, digital marketing, service management, business growth, Amazio account, user credentials, online services",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/reset-password":
                return {
                    title: "Amazio Log In | Secure Access to Your Digital Marketing Account",
                    description: "Access your Amazio account securely with our log in page. Manage your digital marketing services and optimize your business growth with ease.",
                    keywords: "Amazio log in, account access, secure login, digital marketing, service management, business growth, Amazio account, user credentials, online services",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/cookie-policy":
                return {
                    title: "Cookie Policy | Amazio’s Cookie and Tracking Practices",
                    description: "Discover how Amazio uses cookies and tracking technologies to improve your experience on our website. Review our Cookie Policy for details on data handling.",
                    keywords: "Cookie Policy, Amazio tracking practices, cookies, data handling, website cookies, user experience, privacy, tracking technologies, cookie usage",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/privacy-policy":
                return {
                    title: "Privacy Policy | Amazio Data Protection and User Privacy",
                    description: "Read Amazio’s Privacy Policy to understand how we protect your data and privacy. Learn about our practices for handling personal information and data security.",
                    keywords: "Privacy Policy, Amazio data protection, user privacy, personal information, data security, privacy practices, Amazio privacy, data handling, security policy",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/terms-conditions":
                return {
                    title: "Terms & Conditions | Amazio Website and Service Terms",
                    description: "Read Amazio’s Terms & Conditions for using our website and services. Understand our policies, user responsibilities, and legal agreements for a smooth experience.",
                    keywords: "Terms & Conditions, Amazio guidelines, service policies, website usage, legal terms, user compliance, Amazio terms, service agreements, site terms",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/contact-us":
                return {
                    title: "Contact Amazio | Start Your Digital Marketing Journey Today",
                    description: "Ready to transform your business? Contact Amazio to get started with customized digital marketing solutions that drive online growth and success.",
                    keywords: "Contact Amazio, digital marketing journey, business growth, online transformation, customized solutions, digital marketing support, business success",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/about-us":
                return {
                    title: "About Amazio | Building Innovative Digital Solutions for Growth",
                    description: "Discover how Amazio empowers businesses through innovative digital solutions. Learn more about our mission, values, and the team committed to your success.",
                    keywords: "Business listing management, social media services, online presence enhancement, digital marketing solutions, business optimization, SEO management, social media strategy, listing optimization, business growth, marketing management",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            case "/onboarding-form":
                return {
                    title: "Get Started with Amazio | Onboarding Form for Digital Success",
                    description: "Kickstart your digital success with Amazio. Fill out our onboarding form to access personalized marketing solutions that drive business growth and online performance.",
                    keywords: "Onboarding form, digital success, personalized solutions, marketing services, business growth, online performance, client onboarding, Amazio",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };
            default:
                return {
                    title: "Reviews & Reputation Management Services | Amazio",
                    description: "Enhance your brand’s image with Amazio’s Reviews & Reputation Management services. Analyze customer sentiment and maintain a positive online presence.",
                    keywords: "Review management, reputation management, sentiment analysis, online reputation, brand monitoring, customer reviews, online feedback, brand sentiment, review analysis, reputation protection",
                    google_meta:"_xqMhtFFb9Wz6llbCWl_nvsZnX8Hh1w77u9V6ipXW9A",
                };

        }
    }, [location.pathname]);

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta name="keywords" content={meta.keywords} />
            <meta name="google-site-verification" content={meta.google_meta} />
            <meta property="og:title" content={meta.title} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content="https://amazio.com/images/about-us-video.jpg" alt="Amazio" />
            <meta property="og:url" content="https://amazio.com/" />
            <meta property="og:description" content={meta.description} />
        </Helmet>
    );
}

export default MetaComponent;






