import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { CardSection } from '../Elements';
Exporting(Highcharts);

function GoogleCustomerAction(props) {

    useEffect(() => {
        Highcharts.chart('container7', {
            chart: {
                type: 'area',
                spacingBottom: 0,
                spacingTop: 10,
                spacingLeft: 0,
                spacingRight: 0,
            },
         colors: [
           '#4662a0',
           '#aadb87',
           '#da495b',
           '#a87bc6',
           '#fde5a5',
           '#66ceed',
           '#d565ad',
           '#7ea45d',
           '#eace6b',
           '#66edc6',
           '#fdb7a5'
        ],
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: props.data[0],
                tickmarkPlacement: 'on',
                title: {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            tooltip: {
                shared: true,
            },
            plotOptions: {
                area: {
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        } 
                    }
                }
            },
            series: [{
                name: 'Get Directions',
                data:  props.data[1] ? props.data[1] : 0
            }, {
                name: 'Phone Calls',
                data: props.data[2] ? props.data[2] : 0
            }, {
                name: 'Website Clicks',
                data: props.data[3] ? props.data[3] : 0
            }]
        });
    }, [props]);
    return (
        <CardSection title="Google Customer Action">
            <div id="container7" style={{minWidth:' 100%',height: '270px; margin: 0 auto'}}></div>
        </CardSection>
    )
}

export default GoogleCustomerAction
