import _ from "lodash";

function BusinessDetails(props) {
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const paymentMenthods = ["DISCOVER", "VISA", "INVOICE", "MASTERCARD", "ANDROIDPAY", "TRAVELERSCHECK", "CHECK", "APPLEPAY", "CASH", "DINERSCLUB", "FINANCING", "AMERICANEXPRESS", "PAYPAL", "BITCOIN", "SAMSUNGPAY"]

    return (
        <>
            <div className="row g-0 mb-3 py-2 bg-light">
                <div className="col-sm-12 px-3">
                    <div className="fw-medium text-primary">Business Details</div>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="business_description" className="col-md-3 text-md-end col-form-label">Business Description:</label>
                <div className="col-md-8">
                    <textarea type="text" className="form-control" maxLength={1000} id="business_description" defaultValue={props.formdata && props.formdata.business_description} name="business_description" rows="5" placeholder="Business Description"></textarea>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Keyword:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="keyword" onClick={() => props.addField('keyword')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Keywords
                    </span>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Competitor:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="competitor" onClick={() => props.addField('competitor')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Competitor
                    </span>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Business Hours:</label>
                <div className="col-md-8 mt-2">
                    <div className="mb-2">
                        <input type="checkbox" name="business_hours_ckecked" value="ok" className="form-check-input me-2" id="business_hours" onClick={() => props.showFeilds('hours')} />
                        <label className="form-check-label" htmlFor="business_hours">
                            Specify business hours
                        </label>
                    </div>

                    <div className="d-none" id="hours">
                        <div className="row mt-3" id="editHour">
                            {(!props.edit || (props.formdata && props.formdata.business_hours.length === 0)) && weekDays.map((item, index) => {
                                return (<div className="input-group mb-3" key={index}>
                                    <input type="text" readOnly className="form-control" name="days[]" value={item} />
                                    <select className="form-select split_time" name="business_hourtype[]">
                                        <option value="">Select</option>
                                        <option value="open">Open</option>
                                        <option value="split">Split</option>
                                        <option value="24">24 Hours</option>
                                        <option value="close">Close</option>
                                    </select>
                                    <input type="text" className="form-control" name="business_hour_from[]" readOnly placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" defaultValue="00:00" />
                                    <span className="input-group-text">to</span>
                                    <input type="text" className="form-control col-4" name="business_hour_to[]" readOnly placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" defaultValue="00:00" />
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="additional_hours_info" className="col-md-3 text-md-end col-form-label">Additional Hours Info:</label>
                <div className="col-md-8">
                    <input type="text" className="form-control" id="additional_hours_info" name="additional_hours_info" defaultValue={props.formdata && props.formdata.additional_hours_info} placeholder="Additional Hours Info" />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Holidays Hours:</label>
                <div className="col-md-8" id="holiday_hours">
                    <span className="mt-2 btn btn-light text-primary" onClick={() => props.addField('holiday_hours')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Another
                    </span>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Payment Method:</label>
                <div className="col-md-8 mt-2" id="payment_method">
                    <div className="row row-cols-lg-2 row-cols-xl-3">
                        {
                            props.formdata && (props.formdata.payment_methods || props.formdata.payment_methods === '') && paymentMenthods.map((item, index) => {
                                return <div key={index} className={props.edit ? '' : 'd-none'}>
                                    <input type="checkbox" className="form-check-input me-2" id={item} value={item} name="payment_methods[]" defaultChecked={props.formdata.payment_methods.includes(item)} />
                                    <label className="form-check-label" htmlFor={item}>{_.startCase(item)}</label>
                                </div>
                            })}
                        {!props.edit && paymentMenthods.map((item, index) => {
                            return <div key={index}>
                                <input type="checkbox" className="form-check-input me-2" id={item} value={item} name="payment_methods[]" />
                                <label className="form-check-label" htmlFor={item}>{_.startCase(item)}</label>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessDetails