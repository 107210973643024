import { ShowImage } from "../../components/ImageCropper";

function EmailPhoto(props) {
    return (
        <>
            <div className="row g-0 mb-3 py-2 bg-light">
                <div className="col-sm-12 px-3">
                    <div className="fw-medium text-primary">Email and Website</div>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Contact Email:</label>
                <div className="col-md-8">
                    {(!props.formdata || (props.formdata && (!props.formdata.contact_email || (props.formdata.contact_email && props.formdata.contact_email.length === 0)))) && 
                    <input type="text" className="form-control mb-2" id="contact_email" name="contact_email[]" placeholder="Contact Email" />}
                    <span className="mt-2 btn btn-light text-primary" id="addemail" onClick={() => props.addField('addemail')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add another email address
                    </span>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Website</label>
                <div className="col-md-8">
                    <div className="mt-2">
                        <div className="row mb-3">
                            <label htmlFor="main_website" className="col-md-4 text-md-end col-form-label">Main Website:</label>
                            <div className="col-md-8">
                                <input type="url" className="form-control" id="main_website" defaultValue={props.formdata && props.formdata.main_website} name="main_website" placeholder="Enter your main Website URL" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="display" className="col-md-4 text-md-end col-form-label">Display:</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" id="display_website" defaultValue={props.formdata && props.formdata.display_website} name="display_website" placeholder="Enter your display Website URL" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-0 mb-3 py-2 bg-light">
                <div className="col-sm-12 px-3">
                    <div className="fw-medium text-primary">Photos and Video</div>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Business Logo:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('logo')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Photo
                    </span>
                    {props.allImage && props.allImage.logo && props.allImage.logo.url  ?
                        <ShowImage 
                            name='logo' 
                            src={props.allImage.logo.url} 
                            cropImage={props.allImage} 
                            setCropImage={props.setAllImage} 
                        /> 
                    : ''}
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">More Photos:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('more_photo')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Photos
                    </span>
                    <div className={props.allImage && props.allImage.more_photo && props.allImage.more_photo.length > 0 ? "d-flex flex-wrap rounded mt-2 border":"d-flex flex-wrap rounded mt-2"}>
                    {props.allImage && props.allImage.more_photo && props.allImage.more_photo.length > 0 ? 
                        props.allImage.more_photo.map((item, key) => {
                            return (
                                <ShowImage 
                                    name='more_photo' 
                                    key={key} 
                                    multiple={true} 
                                    index={key} 
                                    src={item.url} 
                                    cropImage={props.allImage} 
                                    setCropImage={props.setAllImage} 
                                />
                            )
                        })
                    : '' }
                    </div>
                </div>
            </div>
            
            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">YouTube video:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" onClick={() => props.showFeilds('youtube_video')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add YouTube video
                    </span>
                    <input type="url" className="form-control mt-2 d-none" id="youtube_video" defaultValue={props.formdata && props.formdata.youtube_video} name="youtube_video" placeholder="Youtube Video URL" />
                </div>
            </div>
        </>
    )
}

export default EmailPhoto