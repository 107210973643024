import React, { useContext, useState, useEffect } from "react";
import { fetchData, GET_LOCATION_UNIQUE_URL, DASHBOARD_OVERVIEW, SOCI_DASHBOARD } from "../../components/Service";
import { Link } from "react-router-dom";
import $ from 'jquery'
import ListingDeviceByWeekDay from "../../components/Dashboard/ListingDeviceByWeekDay";
import ListingProfileView from "../../components/Dashboard/ListingProfileView";
import GoogleViewMapSearch from "../../components/Dashboard/GoogleViewMapSearch";
import GoogleCustomerAction from "../../components/Dashboard/GoogleCustomerAction";
import GooglePhoneCallHour from "../../components/Dashboard/GooglePhoneCallHour";
import FanEngagementDemographic from "./FanEngagementDemographic";
import EngagementSentiment from "./EngagementSentiment";
import EngagementStatus from "./EngagementStatus";
import ReviewByNetwork from "./ReviewByNetwork";
import OpenReview from "./OpenReview";
import SocailMediaContent from "./SocailMediaContent";
import ContentStatus from "./ContentStatus";
import ReviewSentiment from "./ReviewSentiment";
import MapContainer from "./googleMap";
import { Context } from '../../components/Context';
import ChooseLocation from "../../components/ChooseLocation";
import LocationMap from "../../components/Dashboard/LocationMap";
import { useLoadScript } from "@react-google-maps/api";

function DashboardAdmin() {
    const [context] = useContext(Context)
    let dashboardTitle = context && context.auth && context.auth.user_group_id === 1 ? 'Admin Dashboard' : 'User Dashboard'
    window.document.title = dashboardTitle;

    const [reload, setReload] = useState(false);
    const [location, setLocation] = useState();
    const [incomingData, setIncomingData] = useState(0)
    const [formfield, setFormfield] = useState({
        type: 'thirtDay',
        startDate: '',
        endDate: '',
    })

    const [soci, setSoci] = useState(0);
    const [fanEngagedData, setFanEngagedData] = useState('');
    const [engagementSentiment, setEngagementSentiment] = useState(0);
    const [engagementStatus, setEngagementStatus] = useState(0);
    const [reviewByNetwork, setReviewByNetwork] = useState(0);
    const [openReview, setOpenReview] = useState(0);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCnsDaRuiQrNBJn9txd1VHFurxqdW-t1lk&callback=initMap&libraries=places" // Add your API key
    });

    var selected_location = localStorage.getItem('selected_location_dashboard') ? JSON.parse(localStorage.getItem('selected_location_dashboard')) : [] //From Dashboard Page
    var default_location_selected;
    if (selected_location.length > 0) {
        default_location_selected = selected_location
    } else {
        default_location_selected = location && location[0] && location[0].value && location[0].value !== '' ? location[0] : false
    }

    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records);
            }
        });
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (location) {
            let selected_location = localStorage.getItem('selected_location_dashboard') ? JSON.parse(localStorage.getItem('selected_location_dashboard')) : []
            var default_location = [];
            selected_location.forEach(function (item, index) {
                default_location.push(item.value)
            })
            var defaultLocation
            if (default_location.length > 0) {
                defaultLocation = default_location.join(',')
            } else {
                defaultLocation = location && location[0] && location[0].value && location[0].value !== '' ? location[0].value : false
            }
            if (defaultLocation) {
                document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
                fetchData(`${DASHBOARD_OVERVIEW}?location=${defaultLocation}&type=thirtDay`, 'GET', '', true, false, (res) => {
                    document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                    if (res.records) {
                        setIncomingData(res.records)
                    } else {
                        setIncomingData('0')
                    }
                }, false, signal, false);
            }
        }

        return () => {
            controller.abort()
        }
    }, [location])

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setTimeout(() => {
            document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
            fetchData(SOCI_DASHBOARD, 'GET', '', true, false, (res) => {
                document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                $('.card').removeClass('card-loader');
                if (res.records) {
                    setSoci(res.records);
                    setFanEngagedData(res.records && res.records.engagement_demographic && res.records.engagement_demographic.data);
                    setEngagementSentiment(res.records.engagement_sentiment);
                    setEngagementStatus(res.records.engagement_status.data);
                    setReviewByNetwork(res.records.reviews_by_network.data);
                    setOpenReview(res.records.open_reviews.data.open.buckets);
                    setReload(false);
                } else {
                    setReload(true);
                }
            }, false, signal, false);
        }, 300);

        return () => {
            controller.abort()
        }
    }, [reload]);

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">{dashboardTitle}</h4>
                    </div>
                </div>
                <div className="row" id="demo_dashobard">
                    <div className="col-auto dashboard-link">
                        {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "yes" &&
                            <>
                                <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex main-link active">Overview</Link>
                                <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex"> Listings </Link>
                                <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Social insights</Link>
                                <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Reputation</Link>
                            </>
                        }
                        {context && context.auth && (context.auth.user_group_id === 3 || (context.auth.user_group_id === 1 && context.auth.super_admin === "no")) && context.auth.permission ? context.auth.permission.map((ele, num) => {
                            return (<>
                                {ele.permission === 'Common Dashboard' && ele.view === 'yes' ? <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex main-link active">Overview</Link> : ''}
                                {ele.permission === 'Listing Dashboard' && ele.view === 'yes' ? <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex"> Listings </Link> : ''}
                                {ele.permission === 'Social Media Dashboard' && ele.view === 'yes' ? <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Social insights</Link> : ''}
                                {ele.permission === 'Reputation Management Reviews' && ele.view === 'yes' ? <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Reputation</Link> : ''}
                            </>)
                        }) : ''
                        }
                    </div>
                </div>

                {default_location_selected && <ChooseLocation
                    location={location}
                    setFormfield={setFormfield}
                    formfield={formfield}
                    setIncomingData={setIncomingData}
                    dashboardOverview="dashboardOverview"
                    default_location_selected={default_location_selected}
                />
                }

                <div id="generatePDF">
                    <SocailMediaContent data={soci && soci.tiles_summary && soci.tiles_summary.data} />

                    <div className="row g-4 mt-2">
                        <ContentStatus data={soci} />
                        <FanEngagementDemographic data={fanEngagedData} />
                        <EngagementSentiment data={engagementSentiment} />
                    </div>

                    <div className="row g-4 mt-2">
                        <EngagementStatus data={engagementStatus} />
                        {isLoaded ? <MapContainer data={soci && soci.engagement_ativity} /> : null}
                        <ReviewSentiment data={reviewByNetwork} />
                    </div>

                    <div className="row g-4 mt-2">
                        <ReviewByNetwork data={reviewByNetwork} />
                        <OpenReview data={openReview} />
                        {isLoaded ? <LocationMap data={incomingData && incomingData.location_map} /> : null}
                    </div>

                    <div className="row g-4 mt-2">
                        <ListingDeviceByWeekDay data={incomingData && incomingData.usageByWeekday ? incomingData.usageByWeekday : 0} />
                        <ListingProfileView data={incomingData && incomingData.listingValue ? incomingData.listingValue : 0} />
                        <GoogleViewMapSearch data={incomingData && incomingData.viewSearchValue ? incomingData.viewSearchValue : 0} />
                    </div>
                    <div className="row g-4 mt-2">
                        <GoogleCustomerAction data={incomingData && incomingData.googleCustomer ? incomingData.googleCustomer : 0} />
                        <GooglePhoneCallHour data={incomingData && incomingData.phoneCalls ? incomingData.phoneCalls : 0} />
                    </div>
                    <div className="row g-4 mt-2"></div>
                </div>
            </div>
            <div>
            </div>
        </section>
    )
}

export default DashboardAdmin
