import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastNotify, validateForm } from "../../components/Helper";
import { fetchData, ADD_NEW_WEBSITE_SUPPORT } from "../../components/Service";

function WebsiteSupport() {
    
    const [dueDate, setDueDate] = useState(new Date())
    useEffect(() => {

    }, [])

    const getFileName = (e, id) => {
        [...e.target.files].forEach(element => {
            document.getElementById(id).innerHTML += `<div text-start alert py-2 alert-info mt-2 mb-0 alert-dismissible fade show">${element.name}<button type="button" className="btn-close my-1 py-2" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
        });
    }

    const disableUrlInput = (value) => {
        if (value === 'no') {
            document.getElementById("website_url").disabled = true;
        } else {
            document.getElementById("website_url").disabled = false;
        }

    }

    const submitForm = (e, formType) => {
        if (formType === 'new') {
            let formData = new FormData(document.getElementById('new'))
            formData.append('type', formType)
            if (validateForm(e, "new")) {
                fetchData(ADD_NEW_WEBSITE_SUPPORT, 'POST', formData, true, true, (res) => {
                    if (res.success) {
                        toastNotify('success', res.success)
                        document.getElementById("website_url").disabled = false;
                        var formId = document.getElementById("new");
                        formId.classList.remove('was-validated');
                        formId.reset()

                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.error)
                        }
                    }
                })
            }
        } else {
            let formData = new FormData(document.getElementById('existing'))
            formData.append('type', formType)
            if (validateForm(e, "existing")) {
                fetchData(ADD_NEW_WEBSITE_SUPPORT, 'POST', formData, true, true, (res) => {
                    if (res.success) {
                        toastNotify('success', res.success)
                        var formId = document.getElementById("existing");
                        formId.classList.remove('was-validated');
                        formId.reset()

                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.error)
                        }
                    }
                })
            }
        }
    }

    return (
        <section className="admin-wrapper">
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">
                            Is this request for a New website or change to an existing website created by AMAZIO
                        </h4>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-11 col-lg-12 col-xl-12">
                        <ul className="nav nav-tabs tabs-common border-0" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="active btn" id="new-tab" data-bs-toggle="tab" data-bs-target="#new" type="button" role="tab" aria-controls="new" aria-selected="true">New</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="btn" id="existing-tab" data-bs-toggle="tab" data-bs-target="#existing" type="button" role="tab" aria-controls="existing" aria-selected="false">Existing</button>
                            </li>
                        </ul>
                        <div className="tab-content border p-4 rounded mt-2" id="myTabContent">
                            <form className="tab-pane fade show active" id="new" role="tabpanel" method="post" aria-labelledby="new-tab">
                                <div className="row mb-3">
                                    <label htmlFor="Name" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Do you have any existing website:
                                    </label>
                                    <div className="col-md-6">
                                        <select className="form-select" id="existing_site_text" name="existing_site_text" onChange={(e) => disableUrlInput(e.target.value)}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="email" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        URL (Optional):
                                    </label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" id="website_url" name="website_url" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="phone" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Which <a href="/websites" target="_blank" rel="noreferrer noopener">Template</a> would you like to use<small className="text-danger">*</small>:
                                    </label>
                                    <div className="col-md-6">
                                        <select className="form-select" id="template" name="template" required>
                                            <option value=""> Select - Template</option>
                                            <option value="template1">Template 1</option>
                                            <option value="template2">Template 2</option>
                                            <option value="template3">Template 3</option>
                                            <option value="template4">Template 4</option>
                                            <option value="template5">Template 5</option>
                                            <option value="template6">Template 6</option>
                                            <option value="template7">Template 7</option>
                                            <option value="template8">Template 8</option>
                                            <option value="template9">Template 9</option>
                                            <option value="template10">Template 10</option>
                                            <option value="template11">Template 11</option>
                                            <option value="template12">Template 12</option>
                                            <option value="template13">Template 13</option>
                                            <option value="custom">Custom Template</option>
                                        </select>
                                        <div className="invalid-feedback">This template field is required.</div>

                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="phone" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Preferred contact method<small className="text-danger">*</small>:
                                    </label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" id="contact_method" name="contact_method" required/>
                                        <div className="invalid-feedback">This contact field is required.</div>

                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="message" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Message<small className="text-danger">*</small>:
                                    </label>
                                    <div className="col-md-6">
                                        <textarea rows="5" className="form-control" id="developer_message" name="developer_message" required></textarea>
                                        <div className="invalid-feedback">This message field is required.</div>

                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="date" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Requested Due Date (Optional):
                                    </label>
                                    <div className="col-md-6">
                                        <DatePicker
                                            selected={dueDate}
                                            onChange={(date) => setDueDate(date)}
                                            className="form-control"
                                            minDate={new Date()}
                                            dateFormat="yyyy-MM-dd"
                                            name="due_date"
                                            required=""
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="date" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Upload HD Images for your Website:
                                    </label>
                                    <div className="col-md-6">
                                        <label className="upload-files">
                                            <input type="file" name="image" accept=".jpg,.jpeg,.png,.gif" data-accept="jpg,jpeg,png,gif" data-fileupload="#imagenewfiles" multiple id="file" onChange={(e) => getFileName(e, 'imagenewfiles')} />
                                            <div className="pb-1">
                                                <i className="bi bi-cloud-arrow-up-fill fs-5 me-2"></i>
                                                Drop image here / Select Image
                                                <div className="small">(.png, .jpg, .jpeg, .gif)</div>
                                            </div>
                                        </label>
                                        <div className="pb-1" id="imagenewfiles"></div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="date" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Upload website content here:
                                    </label>
                                    <div className="col-md-6">
                                        <label className="upload-files">
                                            <input type="file" name="document[]" accept=".docx,.doc,.pdf" data-accept="doc,docx,pdf" multiple id="file-upload" onChange={(e) => getFileName(e, 'pdfnewfiles')} />
                                            <div className="pb-1">
                                                <i className="bi bi-cloud-arrow-up-fill fs-5 me-2"></i>
                                                Drop files here / Select File
                                                <div className="small">(.png, .jpg, .jpeg, .gif)</div>
                                            </div>
                                        </label>
                                        <div className="pb-1" id="pdfnewfiles"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 offset-md-4 offset-xxl-3">
                                        <button type="button" className="btn btn-dark px-4" onClick={(e) => submitForm(e, 'new')}>Submit Ticket</button>
                                    </div>
                                </div>
                            </form>

                            <form className="tab-pane fade needs-validation" id="existing" role="tabpanel" aria-labelledby="existing-tab" noValidate>
                                <div className="row mb-3">
                                    <label htmlFor="link" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Website URL:
                                    </label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" id="website_url" name="website_url" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="message" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Message for the developer<small className="text-danger">*</small>:
                                    </label>
                                    <div className="col-md-6">
                                        <textarea rows="5" className="form-control" id="developer_message" name="developer_message" required></textarea>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="date" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Attachments:
                                    </label>
                                    <div className="col-md-6">
                                        <label className="upload-files">
                                            <input type="file" name="document" accept=".jpg,.jpeg,.png,.gif" data-accept="jpg,jpeg,png,gif" data-fileupload="#imagenewfiles" multiple id="file" onChange={(e) => getFileName(e, 'imagenewfiles')} />
                                            <div className="pb-1">
                                                <i className="bi bi-cloud-arrow-up-fill fs-5 me-2"></i>
                                                Drop image here / Select Image
                                                <div className="small">(.png, .jpg, .jpeg, .gif)</div>
                                            </div>
                                        </label>
                                        <div className="pb-1" id="imagenewfiles"></div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="date" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Preferred Contact method<small className="text-danger">*</small>:
                                    </label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" id="contact_method" name="contact_method" required/>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="date" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">
                                        Requested Due Date (Optional):
                                    </label>
                                    <div className="col-md-6">
                                        <DatePicker
                                            selected={dueDate}
                                            onChange={(date) => setDueDate(date)}
                                            className="form-control"
                                            minDate={new Date()}
                                            dateFormat="yyyy-MM-dd"
                                            name="due_date"
                                            required=""
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 offset-md-4 offset-xxl-3">
                                        <button type="button" className="btn btn-dark px-4" onClick={(e) => submitForm(e, 'exiting')} >Submit Ticket</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WebsiteSupport
