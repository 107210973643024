import { ShowImage } from "../../components/ImageCropper";

function SocialMedia(props) {
    return (
        <>
            <div className="row g-0 mb-3 py-2 bg-light">
                <div className="col-sm-12 px-3">
                    <div className="fw-medium text-primary">Social Medias</div>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="twitter_handle" className="col-md-3 text-md-end col-form-label">Twitter Handle:</label>
                <div className="col-md-8">
                    <input type="text" className="form-control" id="twitter_handle" defaultValue={props.formdata && props.formdata.twitter_handle} name="twitter_handle" placeholder="Username" />
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="facebook_page_url" className="col-md-3 text-md-end col-form-label">Facebook Page URL:</label>
                <div className="col-md-8">
                    <input type="url" className="form-control" id="facebook_page_url" defaultValue={props.formdata && props.formdata.facebook_page_url} name="facebook_page_url" placeholder="Facebook Page URL" />
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="instagram_handle" className="col-md-3 text-md-end col-form-label">Instagram Handle:</label>
                <div className="col-md-8">
                    <input type="text" className="form-control" id="instagram_handle" defaultValue={props.formdata && props.formdata.instagram_handle} name="instagram_handle" placeholder="Instagram Handle" />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Google Cover Photo:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('google_cover')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Google Cover Photo
                    </span>
                    {props.allImage && props.allImage.google_cover ? 
                        <ShowImage 
                            name='google_cover' 
                            src={props.allImage.google_cover.url} 
                            cropImage={props.allImage} 
                            setCropImage={props.setAllImage} 
                        /> 
                    : ''}
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Google Profile Photo:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('google_profile')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Google Profile Photo
                    </span>
                    {props.allImage && props.allImage.google_profile ? 
                        <ShowImage 
                            name='google_profile' 
                            src={props.allImage.google_profile.url} 
                            cropImage={props.allImage} 
                            setCropImage={props.setAllImage} 
                        /> 
                    : ''}
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Facebook Cover Photo:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('facebook_cover')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Facebook Cover Photo
                    </span>
                    {props.allImage && props.allImage.facebook_cover ? 
                        <ShowImage 
                            name='facebook_cover' 
                            src={props.allImage.facebook_cover.url} 
                            cropImage={props.allImage} 
                            setCropImage={props.setAllImage} 
                        /> 
                    : ''}
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-md-3 text-md-end col-form-label">Facebook Profile Photo:</label>
                <div className="col-md-8">
                    <span className="mt-2 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('facebook_profile')}>
                        <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                        Add Facebook Profile Photo
                    </span>
                    {props.allImage && props.allImage.facebook_profile ? 
                        <ShowImage 
                            name='facebook_profile' 
                            src={props.allImage.facebook_profile.url} 
                            cropImage={props.allImage} 
                            setCropImage={props.setAllImage} 
                        /> 
                    : ''}
                </div>
            </div>
        </>
    )
}

export default SocialMedia