import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import $ from 'jquery'
import { CardSection } from '../../components/Elements';
Exporting(Highcharts);

function ReviewByNetwork(props) {
     
    useEffect(() => {
        var baar_chart_data = props.data && props.data.per_network;
        var total = props.data && props.data.total;
        var count = 0;
        var chart_data_new = [];

        if((Object.keys(baar_chart_data ?? []).length > 1)){
            $.each(baar_chart_data , function(key , value){
                var chart_data = {};
                chart_data['name'] = key+" <strong>"+value.total_count+"</strong>";
                chart_data['y'] = Math.round(value.total_count*100/total);
                chart_data['drilldown'] = key;
                chart_data_new[count] = chart_data;
                count ++;
            });
        }
       
        // Create the chart
       if(props.data){
        Highcharts.chart('baar-chart', {
            chart: {
                type: 'bar'
            },
           colors: [
            '#4662a0',
            '#aadb87',
            '#da495b',
            '#a87bc6',
            '#fde5a5',
            '#66ceed',
            '#d565ad',
            '#7ea45d',
            '#eace6b',
            '#66edc6',
            '#fdb7a5'
         ],
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: ''
                }

            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}%'
                    }
                }
            },

            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b> of total<br/>'
            },

            "series": [
            {
                "name": "",
                "colorByPoint": true,
                "data": chart_data_new
            }
            ],
        });
       }else{
           document.getElementById('baar-chart').innerHTML = "No Review Found"
       }
        
    }, [props.data]);
    
    return (
        <CardSection title="Review By Network">
            <div id="baar-chart" style={{ height:'250px !important; width:100%', maxWidth:'500px'}}></div>
        </CardSection>
    )
}

export default ReviewByNetwork
