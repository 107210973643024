import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { reactSelectValidation, state as states } from "../../components/Helper";

function RequireInfo(props) {
    const [state, setState] = useState('');
    
    useEffect(() => {
        setState('');
    },[props]);

    if (document.getElementById('remain_char')) {
        if (props.formdata && props.formdata.featured_message) {
            document.getElementById('remain_char').innerText = "characters remaining: " + (50 - props.formdata.featured_message.length);
        } else {
            document.getElementById('remain_char').innerText = "characters remaining: 50"
        }
    }
    const remainChar = (e) => {
        let count = e.target.value.length
        document.getElementById('remain_char').innerText = "characters remaining: " + (50 - count);
    }

    const stateHandleChange = (options) => {
        setState(options);
    }

    return (
        <>
            <div className="row g-0 mb-3 py-2 bg-light">
                <div className="col-sm-12 px-3">
                    <div className="fw-medium text-primary">Required Listing Information</div>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="business_name" className="col-md-3 text-md-end col-form-label">Business Name<strong className="text-danger">*</strong>:</label>
                <div className="col-md-8">
                    <input type="text" className="form-control" name="business_name" id="business_name" defaultValue={props.formdata && props.formdata.business_name} placeholder="Business Name" autoComplete="off" required />
                    <div className="invalid-feedback">The business name field is required</div>

                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="business_alias" className="col-md-3 text-md-end col-form-label">Business Name Alias:</label>
                <div className="col-md-8">
                    <input type="text" className="form-control" id="business_alias" defaultValue={props.formdata && props.formdata.business_alias} name="business_alias" placeholder="Enter alias for your Business" />
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="category_ids" className="col-md-3 text-md-end col-form-label">Categories<strong className="text-danger">*</strong>:</label>
                <div className="col-md-8">
                    <Select
                        placeholder={'-- Select Categories --'}
                        value={props.value}
                        options={props.category}
                        isMulti={true}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        onChange={(e) => { reactSelectValidation(e, 'category_ids'); props.setSelectedCategory(e ?? []) }}
                        className="basic-multi-select react-select required"
                        name="category_ids[]"
                        id="category_ids"
                        required
                    />
                    <div className="invalid-feedback">The categories field is required</div>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="address" className="col-md-3 text-md-end col-form-label">Address<strong className="text-danger">*</strong>:</label>
                <div className="col-md-8">
                    <input type="text" className="form-control" name="address" id="address" defaultValue={props.formdata && props.formdata.address} placeholder="Address" required />
                    <div className="invalid-feedback">The address field is required</div>
                    <div className="row mt-2">
                        <div className="col-12 col-md-4">
                            <label htmlFor="city" className="col-form-label">City<strong className="text-danger">*</strong>:</label>
                            <div>
                                <input type="text" className="form-control" name="city" id="city" defaultValue={props.formdata && props.formdata.city} placeholder="City" required />
                                <div className="invalid-feedback">The city field is required</div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <label htmlFor="state" className="col-form-label">State<strong className="text-danger">*</strong>:</label>
                            <div>
                                <select className="form-select scan-select px-2" name="state" id="state" value={state ? state : (props.formdata && props.formdata.state ? props.formdata.state : '')} onChange={(e) => stateHandleChange(e.target.value)} placeholder="State" required>
                                    <option value="">--Select State--</option>
                                    { 
                                        Object.keys(states).map((ele, num) => <option value={ele} key={num}>{states[ele]}</option>)
                                    }
                                </select>
                                <div className="invalid-feedback">The state field is required</div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <label htmlFor="zip_code" className="col-form-label">ZIP<strong className="text-danger">*</strong>:</label>
                            <div>
                                <input type="text" className="form-control" id="zip_code" name="zip_code" minLength={4} maxLength={9} defaultValue={props.formdata && props.formdata.zip_code} placeholder="ZIP" required />
                                <div className="invalid-feedback">The zip field is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="main_phone" className="col-md-3 text-md-end col-form-label">Business Phone<strong className="text-danger">*</strong>:</label>
                <div className="col-md-8">
                    <input type="text" className="form-control" minLength={8} maxLength={15} id="main_phone" name="main_phone" defaultValue={props.formdata && props.formdata.main_phone} placeholder="Business Phone" required />
                    <div className="invalid-feedback">The Business Phone field is required</div>

                    <div>
                        <span role="button" className="mt-2 btn btn-light text-primary" onClick={() => props.showFeilds('add_phone')}>
                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>
                            Add More Phone
                        </span>
                    </div>
                    <div className="mt-2 d-none" id="add_phone">
                        <div className="row mb-3">
                            <label htmlFor="alternate_phone" className="col-md-4 text-md-end col-form-label">Alternate Phone:</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" id="alternate_phone" defaultValue={props.formdata && props.formdata.alternate_phone} name="alternate_phone" placeholder="Alternate Phone" />

                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="fax_phone" className="col-md-4 text-md-end col-form-label">Fax phone:</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" id="fax_phone" defaultValue={props.formdata && props.formdata.fax_phone} name="fax_phone" placeholder="Fax phone" />

                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="mobile_phone" className="col-md-4 text-md-end col-form-label">Mobile Phone:</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" defaultValue={props.formdata && props.formdata.mobile_phone} id="mobile_phone" name="mobile_phone" placeholder="Mobile Phone" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="tty_phone" className="col-md-4 text-md-end col-form-label">TTY Phone:</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" id="tty_phone" defaultValue={props.formdata && props.formdata
                                    .tty_phone} name="tty_phone" placeholder="TTY Phone" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="featured_message" className="col-md-3 text-md-end col-form-labell">Featured Message<strong className="text-danger">*</strong>:</label>
                <div className="col-md-8">
                    <p className="text-end text-danger m-0" id="remain_char">characters remaining: 50</p>
                    <input type="text" className="form-control" defaultValue={props.formdata && props.formdata.featured_message} id="featured_message" maxLength={50} onKeyUp={(e) => remainChar(e)} name="featured_message" placeholder="Featured Messagee" required />
                    <div className="invalid-feedback">The Featured Message field is required</div>
                    <input type="url" className="form-control mt-2" id="featured_message_url" defaultValue={props.formdata && props.formdata.featured_message_url} name="featured_message_url" placeholder="Enter a URL to link your Featured Messagee" />
                </div>
            </div>
        </>
    )
}

export default RequireInfo