import React from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api';
import { CardSection } from '../../components/Elements';

function  MapContainer(props) {

    const mapStyles = { height: "370px", width: "100%"};
      
    const defaultCenter = props && props?.data?.length > 0 ? props.data[0] : {lat: 32.8850634, lng: -96.9688435}
        
    return (
        <CardSection title="Engagement Activity">
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={3}
                center={defaultCenter}
            >   
            {
                props && props.data && props.data.map((item, i) => {
                    return (
                        <Marker key={i} position={item} />
                    )
                })
            }
            </GoogleMap>
        </CardSection>
    )
}

export default  MapContainer
