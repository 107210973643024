import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import $ from 'jquery'
import { CardSection } from '../../components/Elements';
Exporting(Highcharts);

function EngagementSentiment(props) {
    useEffect(() => {
        $('#engage-sentiment').highcharts({
            chart: {
                renderTo: 'engage-sentiment',
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                margin: [0, 0, 0, 0],
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0,
                plotShadow: false,
            },
            colors: [
                '#4662a0',
                '#aadb87',
                '#da495b',
                '#a87bc6',
                '#fde5a5',
                '#66ceed',
                '#d565ad',
                '#7ea45d',
                '#eace6b',
                '#66edc6',
                '#fdb7a5'
            ],
            title: {
                text: '',
                align: 'center',
                verticalAlign: 'top',
                y: 70
            },
            tooltip: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            pane: {
                center: ['50%', '75%'],
                size: '50%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    borderWidth: 0,
                    backgroundColor: 'none',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            yAxis: [{
                lineWidth: 0,
                min: 0,
                max: 100,
                minorTickLength: 0,
                tickLength: 0,
                tickWidth: 0,
                labels: {
                    enabled: false
                },
                pane: 0,
            }],
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        distance: -50,
                        style: {
                            fontWeight: 'bold',
                            color: 'red',
                            borderWidth: 0,
                            textShadow: '0px 1px 2px black',
                            shadow: false,
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    size: '160%',
                    center: ['50%', '85%']
                },
                gauge: {
                    dataLabels: {
                        enabled: false
                    },

                    dial: {
                        radius: '100%'
                    }
                }
            },
            series: [{
                type: 'pie',
                name: '',
                innerSize: '50%',
                showInLegend: false,
                data: [
                    ['Nagative', 1],
                    ['Positive', 1],
                    ['Neutral', 1],
                ],
                enableMouseTracking: false,
            },
            ],
        });
    }, [props.data]);

    return (
        <CardSection title="Engagement Sentiment">
            <div id="engage-sentiment" className='mt-5' style={{ height: '180px', margin: '0 auto' }}></div>
            <div className="line-engage-part clearfix">
                <div className="first-part"></div>
                <div className="second-part"></div>
                <div className="third-part"></div>
            </div>
            <div className="engage-values">
                <div className="native">
                    <span data-engage_sentiment="negative">{props.data && props.data.sentiments && props.data.sentiments.negative}</span>
                    <span> Negative</span>
                </div>
                <div className="netrual">
                    <span data-engage_sentiment="neutral">{props.data && props.data.sentiments && props.data.sentiments.neutral}</span>
                    <span> Neutral</span>
                </div>
                <div className="positive">
                    <span data-engage_sentiment="positive">{props.data && props.data.sentiments && props.data.sentiments.positive}</span>
                    <span> Positive</span>
                </div>
            </div>
        </CardSection>
    )
}

export default EngagementSentiment
